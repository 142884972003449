import React, { useState } from 'react';
import './Faq.css';

// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Faq() {

  const [activeFaq, setActiveFaq] = useState(1);

  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto text-center mb-5">
            <h2>Veelgestelde vragen</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto">
            <div className={`faq-item ${activeFaq == 1 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(1)}>
                <h3>Wat houdt het 8-weeks traject in om aan te komen in gewicht?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Het 8-weeks traject om aan te komen in gewicht is een gepersonaliseerd begeleidingsprogramma dat gericht is op het verhogen van je calorie-inname en het bevorderen van gezonde gewichtstoename. Het omvat voedingsadvies, aanbevelingen voor voedzame voeding en ondersteuning gedurende de gehele periode.
                </p>
              </div>
            </div>
            <div className={`faq-item ${activeFaq == 2 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(2)}>
                <h3>Voor wie is dit traject bedoeld?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p> 
                Dit traject is bedoeld voor mensen die moeite hebben om aan te komen in gewicht en graag willen aankomen op een gezonde en gebalanceerde manier. Het is geschikt voor mensen met een snelle stofwisseling, een dun postuur of een laag gewicht, die hun calorie-inname willen verhogen en een gezonder lichaam willen ontwikkelen.
                </p>
              </div>
            </div>
            <div className={`faq-item ${activeFaq == 3 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(3)}>
                <h3>Moet ik tijdens dit traject sporten of trainen?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Hoewel regelmatige lichaamsbeweging altijd wordt aangemoedigd vanwege de vele voordelen voor de gezondheid, is het volgen van een trainingsprogramma geen vereiste voor dit traject. Het hoofddoel van het traject is het verhogen van je calorie-inname door middel van voedzame maaltijden en snacks om gewichtstoename te bevorderen. Het is echter belangrijk om op te merken dat het toevoegen van krachttraining aan je routine positieve fysieke veranderingen kan bevorderen en mogelijk snellere resultaten kan opleveren. Hoewel krachttraining optioneel is en niet verplicht, kan het een waardevolle aanvulling zijn op het traject.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 4 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(4)}>
                <h3>Hoe werkt het traject om aan te komen in gewicht zonder sporten?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Het traject om aan te komen zonder sporten is gebaseerd op het verhogen van je dagelijkse calorie-inname door middel van voedzame maaltijden en snacks. Daarnaast kijken we ook naar je fysieke activiteit in het dagelijkse leven en passen indien nodig aan. We zullen samenwerken om een aangepast voedingsplan op te stellen dat je helpt om meer calorieën binnen te krijgen en geleidelijk aan te komen in gewicht.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 5 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(5)}>
                <h3>Wat zijn de belangrijkste voordelen van het volgen van dit traject?
</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Door dit traject te volgen, kun je verwachten dat je geleidelijk in gewicht aankomt op een gezonde manier. Naast het bereiken van gewichtstoename, leer je ook belangrijke basisprincipes over hoe het lichaam werkt met betrekking tot calorie-inname en -verbruik. Je zult leren hoeveel calorieën je ongeveer consumeert en hoeveel er in verschillende voedingsmiddelen zitten, waardoor je bewuster wordt van wat je eet. Dit helpt je om beter geïnformeerde keuzes te maken en je voedingspatroon aan te passen op een manier die je gewichtstoename ondersteunt. Daarnaast zul je merken dat je meer energie en vitaliteit hebt, verbeterde lichaamskracht ervaart en een algehele verbetering van je fysieke verschijning en welzijn bereikt.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 6 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(6)}>
                <h3>Moet ik speciale voedingssupplementen gebruiken tijdens het traject?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Speciale voedingssupplementen zijn niet noodzakelijk tijdens het traject, maar ze kunnen een aanvulling vormen als je moeite hebt om voldoende voedingsstoffen binnen te krijgen via je reguliere voeding. Tijdens het traject is er veel kennis over suppletie aanwezig, en er wordt gekeken naar mogelijke voedingstekorten en verteringsproblemen die kunnen bijdragen aan een verminderde opname van voedingsstoffen. In sommige gevallen kunnen specifieke supplementen worden aanbevolen om deze tekorten aan te vullen en de spijsvertering te ondersteunen, zodat je voeding beter wordt opgenomen. Het gebruik van voedingssupplementen wordt individueel besproken en aangepast aan jouw specifieke behoeften en doelen.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 7 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(7)}>
                <h3>Zal ik alleen vet aankomen of ook spiermassa tijdens dit traject?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Het is mogelijk dat je zowel vet als spiermassa aankomt tijdens het traject, vooral als je geen specifieke training doet. Het is echter belangrijk om te benadrukken dat het doel van het traject is om een gezonde gewichtstoename te bevorderen, waarbij de nadruk ligt op het verhogen van de calorie-inname met voedzame voeding.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 8 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(8)}>
                <h3>Hoeveel tijd moet ik per week aan het traject besteden?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Het traject vereist geen specifieke tijdsinvestering buiten je reguliere dagelijkse activiteiten. Je zult echter wel tijd moeten besteden aan het plannen en bereiden van voedzame maaltijden en snacks om je calorie-inname te verhogen.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 9 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(9)}>
                <h3>Zijn er mogelijke bijwerkingen of risico's waar ik me bewust van moet zijn?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Over het algemeen zijn er geen ernstige bijwerkingen of risico's verbonden aan het verhogen van je calorie-inname met behulp van voedzame voeding. Het is echter belangrijk om rekening te houden met eventuele individuele voedselallergieën, intoleranties of medische aandoeningen die van invloed kunnen zijn op je dieet. Ik kan je helpen met elke allergie of intolerantie.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 10 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(10)}>
                <h3>Wat gebeurt er na het voltooien van het traject? Zal ik het gewicht behouden dat ik heb gewonnen?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Na het voltooien van het traject is het belangrijk om je nieuwe eetgewoonten en calorie-inname te handhaven om het behaalde gewicht te behouden. Door bewuste keuzes te blijven maken en een gebalanceerd dieet te volgen, kun je de resultaten behouden en een gezonde levensstijl handhaven.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 11 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(11)}>
                <h3>Wat zijn de kosten van het 8-weeks traject en wat krijg ik daarvoor?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                De kosten van het traject zijn 100 euro voor 2 maanden waarin je specifieke begeleiding op maat en ondersteuning ontvangt. Het omvat doorgaans een gepersonaliseerd voedingsplan, regelmatige follow-ups, tips en advies voor gewichtstoename, en eventuele extra middelen of materialen die worden verstrekt.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 12 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(12)}>
                <h3>Heb ik enige voedings- of dieetervaring nodig om aan dit traject deel te nemen?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Je hebt geen specifieke voedings- of dieetervaring nodig om aan dit traject deel te nemen. Het traject is ontworpen om je stap voor stap te begeleiden en je te voorzien van de nodige informatie en ondersteuning om succesvol aan te komen in gewicht.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq;
