import { Modal, Button, Group, TextInput, NumberInput, Checkbox, Box, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import axios from 'axios';

function Questionnaire() {

  const form = useForm({
    initialValues: {
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const sendForm = (values) => {
    console.log(values);

    axios.post(`hlp-app-lmvfr.ondigitalocean.app/api/sendgrid/aanvraag`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
    }); 
  }

  return (
    <>

      {/* Header */}
      <section style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')`, height: '100vh' }}>
        <Box maw={450} mx="auto">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading">
                  <h2 style={{ lineHeight: '115%' }}>The second step to your physical and mental transformation</h2>
                  <p>
                    Fill in this questionnaire below so I can help you the best I can. 
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={form.onSubmit((values) => sendForm(values))}>

                  <Flex
                    gap="md"
                  >
                    <TextInput
                      withAsterisk
                      label="Name"
                      placeholder="Name"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('name')}
                    />
                    <TextInput
                      withAsterisk
                      label="Age"
                      placeholder="Age"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('age')}
                    />
                  </Flex>

                  <Flex
                    gap="md"
                  >
                    <TextInput
                      withAsterisk
                      label="Gender"
                      placeholder="Gender"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('gender')}
                    />
                    <TextInput
                      withAsterisk
                      label="Weight"
                      placeholder="Weight"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('weight')}
                    />
                  </Flex>

                  <Flex
                    gap="md"
                  >
                    <TextInput
                      withAsterisk
                      label="Job title"
                      placeholder="Job title"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('job_title')}
                    />
                    <NumberInput
                      withAsterisk
                      label="Activity level [0 - 10]"
                      placeholder="Activity level [0 - 10]"
                      mb="sm"
                      className="w-100"
                      {...form.getInputProps('activity_level')}
                    />
                  </Flex>

                  <Textarea
                    placeholder="Medical conditions or injuries"
                    label="Medical conditions or injuries"
                    withAsterisk
                    minRows={3}
                    mb="sm"
                    className="w-100"
                    {...form.getInputProps('conditions')}
                  />

                  <Group position="right" mt="md">
                    <button type="submit" className="button">Send</button>
                  </Group>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </section>
    </>
  )
}

export default Questionnaire;