import './Recensies.css';

// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Recensies() {

  return (
    <section className="recensies-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-heading centered dark-heading">
            <h6>Recensies</h6>
            <h2 className="mt-2 mb-4">Ontdek wat anderen zeggen over mijn coaching</h2>
            <p>
              Lees de succesverhalen van mijn klanten en laat je inspireren door hun reis naar een gezonder leven
            </p>
          </div>
        </div>
      </div>

      <Swiper
        className="mt-5 pt-5"
        modules={[Navigation]}
        style={{ overflow: 'visible' }}
        spaceBetween={50}
        slidesPerView={3.15}
        navigation
        autoHeight
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          // when window width is >= 320px
          320: {
            width: 320,
            slidesPerView: 1
          },
          // when window width is >= 640px
          640: {
            width: 320,
            slidesPerView: 1
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2
          },
          // when window width is >= 768px
          991: {
            width: 991,
            slidesPerView: 3
          },
          // when window width is >= 768px
          1025: {
            width: 1025,
            slidesPerView: 3
          }
        }}
      >
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Kevin</h4>
            <p>
              23 jaar oud
            </p>
            <div className="pricing">
              <span>Er is veel kennis aanwezig</span>
            </div>
            <p className="quote">
              “Dankzij het traject bij Healthy Living Project heb ik eindelijk succesvol gewicht kunnen aankomen, iets wat me voorheen nooit lukte.<br/>
              Stef heeft me nieuwe inzichten gegeven en alles is nu ineens logisch waarom ik moeite had met aankomen.<br/> 
              Zijn persoonlijke begeleiding, geduld en begrip hebben echt het verschil gemaakt.<br/> 
              Ik voel me sterker, energieker en begrijp nu hoe ik mijn lichaam op de juiste manier kan voeden en verzorgen.<br/> 
              Als je worstelt met gewichtstoename, raad ik Healthy Living Project ten zeerste aan.<br/> 
              Bedankt, Stef, voor je geweldige steun en kennis!”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Benjamin</h4>
            <p>
              22 jaar oud
            </p>
            <div className="pricing">
              <span>Er is veel kennis aanwezig</span>
            </div>
            <p className="quote">
              “Na bijna 5 jaar lang trainen, waarbij ik alleen in het eerste jaar resultaat zag, begon ik me wanhopig te voelen.<br/> 
              Gelukkig kwam ik in contact met Healthy Living Project en besloot ik het traject te volgen. <br/>
              Het was een keerpunt in mijn leven! Stef opende mijn ogen voor nieuwe inzichten over voeding en krachttraining.<br/>
              Hij leerde me de juiste voedingstrategieën en trainingsmethoden die mijn progressie een boost gaven. <br/>
              Dankzij zijn persoonlijke begeleiding ben ik eindelijk sterker en gespierder geworden. <br/>
              Ik kan het zelf bijna niet geloven! Maar wat me nog het meest heeft geholpen, is dat Stef altijd voor me klaarstaat en ik met al mijn vragen bij hem terecht kan. <br/>
              Zelfs nu ik tevreden ben met mijn resultaten, weet ik dat ik op hem kan rekenen. <br/>
              Stef, bedankt dat je altijd voor me klaar staat en me blijft ondersteunen als mijn coach.”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Alexander Visser</h4>
            <p>
              33 jaar oud
            </p>
            <div className="pricing">
              <span>Het werkt!</span>
            </div>
            <p className="quote">
              “Sinds 1 jaar eindelijk mezelf in de sportschool gekregen om serieus aan mezelf te werken. Eerst op eigen kracht, alleen merkte ik in de eerste 6 tot 9 maanden dat het aankomen in spieren met ups en downs ging. Sinds ik hulp van Stef krijg en goede duidelijke tips over oefeningen, trainingschema's en voeding, merk ik dat ik mijn spieren stabiliseren en groeien. In de laatste paar maanden kom ik wekelijks tussen de 0,2 gr en 0,8 gr in spieren aan. 🙏🙏”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Lars</h4>
            <p>
              25 jaar oud
            </p>
            <div className="pricing">
              <span>Er is veel kennis aanwezig</span>
            </div>
            <p className="quote">
              “Voordat ik begon met het traject bij Healthy Living Project, worstelde ik met een laag energieniveau en moeite om gewicht aan te komen.<br/> 
              Ik wist niet dat bepaalde voedingsmiddelen mijn opname beïnvloedden en resulteerden in een tekort aan calorieën<br/> 
              Stef heeft me geholpen om mijn dieet te verbeteren en voedingsmiddelen te vinden die mijn spijsvertering verstoorden.<br/> 
              Door zijn begeleiding heb ik geleerd welke voedingsmiddelen ik het beste kan vermijden en hoe ik mijn calorie-inname kan verhogen zonder dat het me moeite kost. <br/> 
              Nu voel ik me energieker en heb ik eindelijk succes in het bereiken van mijn gewichtsdoelen. <br/> 
              Ik ben Stef ontzettend dankbaar voor zijn kennis en ondersteuning tijdens mijn reis naar een gezonder en sterker lichaam!”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Frenk Le Feber</h4>
            <p>
              27 jaar oud
            </p>
            <div className="pricing">
              <span>Er is veel kennis aanwezig</span>
            </div>
            <p className="quote">
              “De afgelopen periode heb ik hier een traject gevolgd en ben zeer te spreken over Healthy Living Project.<br/>
              Er is veel kennis aanwezig over voeding en sport.<br/>
              in twee fases is er een voedingschema op maat gemaakt die bij jouw wensen aansluit.<br/>
              Ook wordt er toegelicht wat er is verwerkt in je schema, zo wordt je zelf ook bewust van wat je eet en deze info neem je altijd mee.<br/>
              Door contact via whatsapp geeft dit soms net die extra motivatie.<br/>
              Betaalbaar en zeker aan te raden!”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Shireen de Boer</h4>
            <p>
              35 jaar oud
            </p> 
            <div className="pricing">
              <span>Echte aanrader!</span>
            </div>
            <p className="quote">
              “Erg tevreden over de begeleiding van Stef! Ik ben altijd al bezig met mijn gezondheid maar ik liep toch tegen een aantal dingen aan. Stef heeft voor mij een mooi schema gemaakt en mij wat mooie vitamines aangeraden. Ik heb vooral geleerd dat de momenten wanneer je iets eet en de combinatie zeer belangrijk zijn. Ook heeft hij erg geholpen met vitamines voor onze peuter die veel ziek was. Echte aanrader!”
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Rob van den Berg</h4>
            <p>
              30 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Stef weet waar hij over praat en dat is precies wat ik zocht. Zelf al best bezig met mijn voeding, maar juist de details zijn dan belangrijk. Top!”
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Dionne de Boer</h4>
            <p>
              28 jaar oud
            </p>
            <div className="pricing">
              <span>Super fijn</span>
            </div>
            <p className="quote">
              “Super fijn gesprek gehad en goede uitleg. Kan altijd appen als ik nog vragen heb!”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul> */}

            {/* <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h4>Thom Ridder</h4>
            <p>
              30 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Heel veel nuttige tips, voorop op het gebied van micronutriënten die echt een positief effect hebben op de algemene gezondheid. HLP weet ook vooral uit te leggen waarom iets werkt of juist niet, dat niet alleen je training belangrijk is maar ook alles erom heen zoals rust en voeding. Ook heb ik een goede ervaring op het gebied van PT en kickboxen. De methode kan keihard zijn, maar ook hier wordt er erg goed op de details gelet en waarom er wordt gekozen voor een bepaalde aanpak.”
            </p>

            {/* <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul>

            <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a> */}
          </div>
        </SwiperSlide>
      </Swiper>

    </div>
    </section>
  )
}

export default Recensies;
