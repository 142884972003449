import './CallToAction.css';

function CallToAction() {

  return (
    <section className="call-to-action" id="diensten" style={{ backgroundImage: `url('/img/stef-schrijven.jpg')` }}>
      <div className="container">
        <div className="row align-items-center cta-inner">
          <div className="col-md-3 mx-auto text-center d-flex justify-content-center">
            <img className="w-100" src="/img/page-header/stef-ridder-img.png" alt="" />
          </div>
          <div className="col-md-7 ml-auto text-center d-flex justify-content-center">
            <div className="section-heading mb-0" style={{ maxWidth: '100%' }}>
              <h1 className="m-0">Weten wat ik voor je kan doen?</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction;
