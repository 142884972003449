import { useState, useRef, useEffect, useContext } from 'react'
import AppContext from '../../AppContext';
import { useNavigate } from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo'
import ReactWhatsapp from 'react-whatsapp';
import './Video.css';
import '../Disclaimer.css';
import Lottie from 'react-lottie';
import * as animationData from './loading.json'

function Video() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

	const navigate = useNavigate();

  const videoElement = useRef(null);

	const animationRef = useRef(null);

	const [loading, setLoading] = useState(false)
	const [videoInit, setVideoInit] = useState(false)
	const [subscribed, setSubscribed] = useState(false)
	const [isOpen, setOpen] = useState(false)
	const [disclaimer, setDisclaimer] = useState(false)
	const [step, setStep] = useState(1);
	const [ctaOverlay, setCtaOverlay] = useState(false);
	const [hideVideo, setHideVideo] = useState(true);
	const [minimize, setMinimize] = useState(false);
	const [email, setEmail] = useState('');

	// useEffect(function() {
	// 	if (videoInit) return false;
		
  //   window.addEventListener('scroll', function() {
	// 		setHideVideo(false);
	// 		setVideoInit(true);
	// 	});
	// }, [])

	const sendEmail = () => {
		console.log('send email');
 
		const options = {
			method: 'POST',
			headers: {accept: 'application/json', 'content-type': 'application/json'},
			body: JSON.stringify({
				email: email
			})
		}; 

		setLoading(true);
		
		fetch('https://healthyliving-project.com/api/klaviyo/add_member_to_list', options)
			.then(response => response.json()) 
			.then(response => {
				console.log(response)
				setSubscribed(true);
				setLoading(false);
			})
			.catch(err => console.error(err));


		// console.log(animationRef.current);
		// animationRef.current.pause(); 
	}

  useEffect(() => {
		console.log('Subscribed');
		if (subscribed) {
			setStep(2)
		} 
  }, [subscribed]);

  useEffect(() => {
		console.log('Step changed');
		if (step === 2) {
			console.log('Step changed 2');
			navigate('/je-bent-begonnen');
		}
  }, [step]);

  useEffect(() => {
		console.log('Global state changed', globalState);
		// if (globalState.video_opened) {
		// 	restartVideo();
		// }
		if (globalState.cta_opened) {
			setCtaOverlay(true);
		}
		// if (globalState.video_hidden) {
		// 	setHideVideo(true);
		// }
  }, [globalState]);

	// const restartVideo = () => {
	// 	console.log('Restart video', videoElement);
	// 	setOpen(!isOpen);
	// 	setHideVideo(false);
  //   videoElement.current.pause(); 
  //   videoElement.current.muted = false; 
  //   videoElement.current.currentTime = 0; 
  //   videoElement.current.play(); 
	// }

	// const closeVideo = () => {
	// 	setOpen(!isOpen);
  //   videoElement.current.muted = true; 
  //   updateGlobalState('video_opened', false);
	// }

	// const minimizeVideo = () => {
	// 	setOpen(false);
	// 	setMinimize(true);
  //   videoElement.current.pause(); 
  //   videoElement.current.currentTime = 0; 
  //   videoElement.current.muted = true; 
	// }

	// const maximizeVideo = () => {
	// 	setOpen(false);
	// 	setMinimize(false);
  //   videoElement.current.currentTime = 0; 
  //   videoElement.current.muted = true; 
  //   videoElement.current.play(); 
	// }

	const closeOverlay = () => {
		console.log('Close overlay');
		setCtaOverlay(false);
    updateGlobalState('cta_opened', false);
    updateGlobalState('video_hidden', false);
		// setHideVideo(false);
    // videoElement.current.pause(); 
    // videoElement.current.muted = true; 
    // videoElement.current.currentTime = 0; 
	}

	// const checkTime = () => {
	// 	if (videoElement.current.currentTime > 109) {
	// 		setOpen(false); 
	// 		setCtaOverlay(true);
	// 		setMinimize(false);
	// 	}

	// 	if (videoElement.current.currentTime > 115) {
	// 		setHideVideo(true);
	// 		videoElement.current.webkitExitFullScreen();
	// 	}
	// }

	const showCta = () => {
		setOpen(false); 
		setCtaOverlay(true);
		// setMinimize(false);
	}
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
		console.log('Handle change');
    // 👇 Get input value from "event"
    setEmail(event.target.value);
  };
 
	return (
		<>
			<div className={`cta-overlay ${ctaOverlay ? 'active' : ''}`}>
				<div className="cta-content">
					<svg style={{ right: '45px', top: '35px' }} onClick={() => closeOverlay()} className={ctaOverlay ? 'close-icon active' : 'close-icon'} width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M67.0356 0C30.099 0 0 30.099 0 67.0356C0 103.972 30.099 134.071 67.0356 134.071C103.972 134.071 134.071 103.972 134.071 67.0356C134.071 30.099 103.972 0 67.0356 0ZM89.5595 82.4537C91.5035 84.3978 91.5035 87.6155 89.5595 89.5595C88.554 90.565 87.2803 91.0343 86.0066 91.0343C84.7329 91.0343 83.4593 90.565 82.4537 89.5595L67.0356 74.1413L51.6174 89.5595C50.6118 90.565 49.3382 91.0343 48.0645 91.0343C46.7908 91.0343 45.5171 90.565 44.5116 89.5595C42.5676 87.6155 42.5676 84.3978 44.5116 82.4537L59.9298 67.0356L44.5116 51.6174C42.5676 49.6734 42.5676 46.4556 44.5116 44.5116C46.4556 42.5676 49.6734 42.5676 51.6174 44.5116L67.0356 59.9298L82.4537 44.5116C84.3978 42.5676 87.6155 42.5676 89.5595 44.5116C91.5035 46.4556 91.5035 49.6734 89.5595 51.6174L74.1413 67.0356L89.5595 82.4537Z" fill="white"/>
					</svg>
					{
						loading ?
        		<div className="confetti-pop">
      				<Lottie options={defaultOptions}
								height={150}
								width={150}
							/>
        		</div>
						:
						step == 1 ?
						<>
							<h2>Begin vandaag nog</h2>
							<p style={{ textAlign: 'center', padding: '0 15px' }}>
								Vul hieronder je e-mailadres in voor meer informatie
							</p>
							<input type="email" id="email" value={email} placeholder="E-mailadres"
								onChange={handleChange}
							/>
							<small style={{ color: "#fff", marginBottom: "10px"}}>(je zit nergens aan vast)</small>
							<button className="button button-white" onClick={() => sendEmail()}>Versturen</button>
						</>
						: step == 2 ?
						<>
							{/* <Lottie lottieRef={animationRef} width={400} height={400} /> */}
							<h2>Je hebt de eerste stap gezet!</h2>
							<p style={{ textAlign: 'center', paddingBottom: '20px' }}>
								Stuur mij vervolgens een appje met de tekst <b style={{ fontSize: '28px' }}>HLP</b> naar <i><a style={{ color: 'white' }} href="tel:0031627003089">+31 06 27 00 30 89</a></i><br/> of klik hieronder om direct WhatsApp te openen.<br/><br/>
								Op deze manier kan ik je persoonlijk meer informatie geven, via WhatsApp.
							</p> 
							<ReactWhatsapp number="+31627003089" message="HLP" style={{ background: 'transparent', border: 'none' }}>
								<button className="button button-white">Stuur mij een appje</button>
							</ReactWhatsapp>
						</>
						: null
					}
				</div>

				{/* Disclaimer */}
				{/* <button onClick={() => setDisclaimer(!disclaimer)} type="button">Open disclaimer</button>
				<Disclaimer open={disclaimer} /> */}
			</div>
			{/* <div 
				id="video-container"
				className={
					isOpen ? 'modal' : isOpen == false && minimize ? 'minimize' : ''
				}
				style={{ display: hideVideo ? 'none' : 'flex'}}
			>

				{ 
					minimize ? null :
					<svg onClick={() => minimizeVideo()} className={isOpen ? 'minimize-icon active' : 'minimize-icon'} width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path opacity="0.4" d="M95.4391 0H39.0769C14.595 0 0 14.595 0 39.0769V95.3719C0 119.921 14.595 134.516 39.0769 134.516H95.3718C119.854 134.516 134.449 119.921 134.449 95.4391V39.0769C134.516 14.595 119.921 0 95.4391 0Z" fill="#E00A18"/>
					<path d="M94.1613 72.3023H40.3549C37.5973 72.3023 35.3105 70.0156 35.3105 67.258C35.3105 64.5004 37.5973 62.2136 40.3549 62.2136H94.1613C96.9189 62.2136 99.2057 64.5004 99.2057 67.258C99.2057 70.0156 96.9189 72.3023 94.1613 72.3023Z" fill="#E00A18"/>
					</svg>
				}

				{
					minimize && isOpen == false ? null :
					<svg onClick={() => closeVideo()} className={isOpen ? 'close-icon active' : 'close-icon'} width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.4" d="M103.376 0H42.3264C15.8087 0 0 15.8087 0 42.3264V103.303C0 129.893 15.8087 145.702 42.3264 145.702H103.303C129.82 145.702 145.629 129.893 145.629 103.376V42.3264C145.702 15.8087 129.893 0 103.376 0Z" fill="#CC0C19"/>
						<path d="M72.8525 94.0492C71.4683 94.0492 70.0841 93.5392 68.9914 92.4465L43.2747 66.7301C41.162 64.6174 41.162 61.1205 43.2747 59.0079C45.3874 56.8953 48.8842 56.8953 50.9969 59.0079L72.8525 80.8631L94.7078 59.0079C96.8204 56.8953 100.317 56.8953 102.43 59.0079C104.543 61.1205 104.543 64.6174 102.43 66.7301L76.7136 92.4465C75.6208 93.5392 74.2366 94.0492 72.8525 94.0492Z" fill="white"/>
					</svg>
				}


				<div className="overlay" onClick={() => restartVideo()}></div>
				{
					minimize && isOpen == false ? null : isOpen ? null :
					<span className="play-video-button" onClick={() => restartVideo()}>
						Bekijk mij eerst
						<svg width="121" height="97" viewBox="0 0 121 97" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.4" d="M90.4257 96.454H30.1419C12.0568 96.454 0 84.3973 0 66.3122V30.1419C0 12.0568 12.0568 0 30.1419 0H90.4257C108.511 0 120.568 12.0568 120.568 30.1419V66.3122C120.568 84.3973 108.511 96.454 90.4257 96.454Z" fill="white"/>
						<path d="M56.7851 33.0963L71.6752 42.0184C77.3418 45.4546 77.3418 51.0007 71.6752 54.4369L56.7851 63.3589C50.7567 66.9759 45.8135 64.2028 45.8135 57.1496V39.2453C45.8135 32.2524 50.7567 29.4793 56.7851 33.0963Z" fill="white"/>
						</svg>
					</span>
				}
				<video 
					src={'https://mxbucket.ams3.cdn.digitaloceanspaces.com/HLP-video-good-subtitles-no-icon.mp4'} 
					width="400" 
					height="600" 
					autoPlay={true}
					muted={true}
					controls={isOpen ? true : false}
					allowFullScreen={false}
					// onPlay={(event) => console.log('playing', event)}
					// onPlaying={(event) => console.log('playinging', event)}
					// onPause={() => console.log('paused')}
					onTimeUpdate={() => checkTime()}
          ref={videoElement}
				>
				</video>
				<button onClick={() => showCta()} className="button"
				style={{ display: isOpen ? 'block' : 'none'}}>
					Ik wil meer informatie
				</button>
			</div> */}
		</>
	)
}

export default Video;
	