import AppContext from '../../AppContext';
import './Page.css';
import { useContext, useState } from 'react';
import { Checkbox, Modal, Button, Select, createStyles, Flex } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  modal: {
    '.mantine-Paper-root': {
      background: 'transparent !important',
      boxShadow: 'none !important'
    },
    '.mantine-Modal-header': {
      background: 'transparent'
    },
    '.mantine-Modal-inner': {
      padding: '0'
    }
  }
}));

function Traject() {
  
  const { classes } = useStyles();

  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <>
      <div className="section traject pakket-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading centered dark-heading">
                <h6>Een unieke aanpak</h6>
                <h1 className="mt-2 mb-4">Zo Ziet Jouw Challenge Er Uit</h1>
                <p>
                  Voor de beste resultaten stellen we de 42-daagse Challenge af op jouw niveau.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="step-lines">
                <div className="step-line">
                  <span className="line-number">1</span>
                  <h5>Voor de challenge</h5>
                  <ul>
                    <li>
                      Kennismaken met je coach
                    </li>
                    <li>
                      Ontvangen op maat gemaakt trainingsschema
                    </li>
                    <li>
                      Ontvangen gewichtslog
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">2</span>
                  <h5>Dag 1 - 7 (Introductiefase)</h5>
                  <ul>
                    <li>
                      Spiermassa Gids doornemen - alle informatie leren voordat je begint
                    </li>
                    <li>
                      Workout-app gereed maken
                    </li>
                    <li>
                      MyFitnessPal gereed maken
                    </li>
                    <li>
                      Trainingsschema doornemen
                    </li>
                    <li>
                      Gewichtslog uitprinten en neerleggen op plek (badkamer?)
                    </li>
                    <li>
                      Uitleg vragen waar nodig
                    </li>
                    <li>
                      Met je coach realistische doelstellingen opstellen
                    </li>
                    <li>
                      Ontvangen op maat gemaakt trainingsschema
                    </li>
                    <li>
                      Ontvangen gewichtslog
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">3</span>
                  <h5>Dag 1 - 14 (Begin van de challenge)</h5>
                  <ul>
                    <li>
                      Zelf aan de slag en de informatie toepassen                    
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">4</span>
                  <h5>Dag 15</h5>
                  <ul>
                    <li>
                      Voortgangsbeoordeling met je coach                    
                    </li>
                    <li>
                      Aanpassen waar nodig                 
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">5</span>
                  <h5>Dag 15 - 28</h5>
                  <ul>
                    <li>
                      Zelf aan de slag en de informatie toepassen                    
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">4</span>
                  <h5>Dag 29</h5>
                  <ul>
                    <li>
                      Voortgangsbeoordeling met je coach                    
                    </li>
                    <li>
                      Aanpassen waar nodig                 
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">5</span>
                  <h5>Dag 29 - 42</h5>
                  <ul>
                    <li>
                      Zelf aan de slag en de informatie toepassen                    
                    </li>
                  </ul>
                </div>
                <div className="step-line">
                  <span className="line-number">5</span>
                  <h5>Dag 43</h5>
                  <ul>
                    <li>
                      Eindbeoordeling                
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Traject;