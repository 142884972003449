import styled from 'styled-components'
import Lottie from 'react-lottie';
import * as animationData from './confetti.json'

import './JeBentBegonnen.css';
import ReactWhatsapp from 'react-whatsapp';

function JeBentBegonnen() {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
 
  return (
    <div className="header-outer">
      <div className="page__header thanks-header" style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')` }}><div>
        <div className="confetti-pop">
      <Lottie options={defaultOptions}
              height={'100vh'}
              width={'100vw'}
              isStopped={false}
              isPaused={false}/>
        </div>
    </div>
        <div className="container">
          <div className="row">
            <div className="header__img">
              <img className="w-100" src="/img/page-header/stef-ridder-img.png" alt="" />
            </div>

            <div className="header-content">
							<h2>Bedankt voor Je Inschrijving!</h2>
							<p style={{ textAlign: 'center', paddingBottom: '20px' }}>
                Dank je wel voor het delen van jouw gegevens. Je ontvangt binnen enkele minuten een e-mail met links naar jouw gratis trainingsschema, gids en e-book.
                <br/><br/>
								Stuur mij vervolgens een appje. Op deze manier kan ik je persoonlijk meer informatie geven. 
                <br/><br/>
                Whatsapp naar <i><a style={{ color: 'white' }} href="tel:0031627003089">+31 06 27 00 30 89</a></i><br/> of klik hieronder om direct WhatsApp te openen.<br/><br/>
								
							</p> 
							<ReactWhatsapp number="+31627003089" message="Hi Stef, ik heb interesse in je Black Friday deal!" style={{ background: 'transparent', border: 'none' }}>
								<button className="button button-white">Stuur mij een appje</button>
							</ReactWhatsapp>
            </div>
          </div>
          
          {/* <div className="quote">
            <p>
              “Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default JeBentBegonnen;