import { useContext } from 'react'
import AppContext from '../../AppContext';

import styled from 'styled-components'
import { Link } from 'react-router-dom';

import { useExitIntent } from 'use-exit-intent'

import Countdown, { zeroPad } from 'react-countdown';

import './PageHeader.css';

function PageHeader() {
  const { globalState, updateGlobalState } = useContext(AppContext);
  
  const { registerHandler } = useExitIntent()

  registerHandler({
    id: 'openModal',
    handler: () => console.log('Hello from handler!')
  })

  // Update the global state
  const openVideo = () => {
    updateGlobalState('video_opened', true);
    console.log(globalState);
  };

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div>
          <div className="timer-box">
            <span>5</span>
          </div>
          <div className="timer-desc">
            <span>klanten</span>
          </div>
          {/* <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>dagen</span>
          </div> */}
        </div>
      );
    }
  };

  const scrollToPrices = () => {
    const section = document.querySelector('#prijzen');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  }
 
  return (
    <div className="header-outer">
      <div className="timer">
        <Countdown 
          date={tomorrow} 
          renderer={renderer} 
        />
      </div>
      <div className="page__header lp__header" style={{ backgroundImage: `url('/img/page-header/hlp-bg-grey.jpg')` }}>
        <div className="container">
          <div className="row align-items-center align-items-md-start justify-content-end">
            <div className="logo pt-4" style={{ width: '90%', margin: '0 auto' }}>
              <a href="#">
                {/* <img src="/img/hlp-logo-wit.svg" alt="Logo Healthy Living Project" className="w-100" /> */}
              </a>
            </div>
          </div>
          <div className="row">

            <div className="header-content">
              
              {/* <h2>
                Speciaal voor:<br/>
                <span>MANNEN (18-45 jaar) die streven naar gewichtstoename en spieropbouw!</span>
              </h2>
              <h2><span>Maak kennis met mijn unieke 6-weken traject!</span></h2> */}

              {/* <h1>Ben je moe van het eeuwige gevecht om aan te komen?</h1>
              <h2>We creëren samen een aanpak voor gezonde gewichtstoename<br />die perfect bij jouw levensstijl past.</h2> */}

              <Label>
                BLACK FRIDAY ACTIE!
              </Label>
 
              {/* <h1>Persoonlijke Begeleiding voor<br/>Gezonde Gewichtstoename</h1> */}
              <h1>Wil jij weten waarom 
aankomen bij jou niet
lijkt te werken?</h1>
              {/* <h1>Ik heb 109 mannen online geholpen om in totaal 453 kilo aan te komen</h1> */}
              {/* <h1>Bereik Jouw Ideale Gewicht</h1> */}
              {/* <h2>Een Aanpak die Perfect Past bij <b>Jouw Levensstijl</b></h2> */}
              <h2>Voor Mannen Die Resultaten Willen: Ontvang Mijn Tools Om Aan te Komen Vandaag Gratis!</h2>
              {/* <h2>Je zult nooit meer te dun zijn met mijn <i>online</i> coaching</h2> */}

              <button onClick={() => handleClick()} className="button button-red">
                Ontvang mijn deal!
              </button>
              
              {/* <h4><small style={{ textShadow: 'none', fontSize: '16px' }}>(Gegarandeerd resultaat)</small></h4> */}
            </div>
          </div>

          {/* <img src="/img/deal-image.png" alt="Logo Healthy Living Project" style={{ position: 'absolute' }} /> */}
          
          {/* <div className="quote">
            <p>
              “Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>
          </div> */}
        </div>
      </div>

      


      {/* <div className="deal-label">
      <Label>
        ONTVANG VANDAAG:
      </Label>
        <span><span className="label">Gratis</span> <span>Trainingsschema op maat <i>(t.w.v. €59,99)</i></span></span>
        <span><span className="label">Gratis</span> <span>"Hoe Calorieën Tracken?"-Gids</span></span>
        <span><span className="label">Gratis</span> <span>20 Meest Gemaakte Fouten bij Aankomen E-Book</span></span>
        <span><span className="label">50% korting</span> <span>op een coachingstraject</span></span>
      </div> */}
    </div>
  )
}

const Label = styled.div`
  background: rgba(255, 118, 34, 1);
  color: #fff;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  

  @media (min-width: 992px) { 
    margin-right: auto;
  }
`

export default PageHeader;