import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import './Text.css';

function Text() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  return (
    <section className="call-to-action" id="diensten" style={{ backgroundColor: `#353535` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center d-flex justify-content-center">
            <div className="text-section">
              <h3>Ben je moe van het eeuwige gevecht om aan te komen?</h3>
              <p>
                Het is tijd om afscheid te nemen van de dagen van dunne armen, holle wangen en onzekerheid over je lichaam.
                <br/><br/>
                Met onze uitgebreide gids kun je je lichaam in slechts enkele weken transformeren en het zelfvertrouwen krijgen waar je naar verlangt!
              </p>
              <button onClick={() => handleClick()} className="button mt-4">
                Bekijk mijn aanbieding
              </button>
            </div>
          </div>
        </div> 
      </div>
    </section>
  )
}

export default Text;
