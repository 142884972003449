import React, { createContext, useState } from 'react';

// Create a new context
const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({}); // Initial state

  // Define any functions to update the global state
  const updateGlobalState = (key, value) => {
    setGlobalState(prevState => ({ ...prevState, [key]: value }));
  };

  // Provide the global state and functions to child components
  return (
    <AppContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;