import React, { useContext } from 'react';
import AppContext from '../../AppContext';

import './Prijzen.css';

import Countdown, { zeroPad } from 'react-countdown';

function Prijzen() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  // Access the global state
  console.log(globalState);

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div>
          <div className="timer-box">
            <span>2</span>
          </div>
          <div className="timer-desc">
            <span>klanten</span>
          </div>
          {/* <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>dagen</span>
          </div> */}
        </div>
      );
    }
  };

  return (
    <section id="prijzen" className="prijzen" style={{ backgroundImage: `url('/img/services/prijzen-bg.png')` }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              <h6>Jouw investering</h6>
              <h2 className="mt-2 mb-4">Profiteer van mijn beginners korting en begin vandaag met je transformatie!</h2>
              <p>
                Investeer in jouw gezondheid en bespaar geld met mijn speciale aanbieding!
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-5 justify-content-center" style={{ gap: '30px' }}>
          {/* <div className="col-md-4">
            <div className="prijs-card">
              <div className="sticker">
                <span>20%</span>
                <span>korting</span>
              </div>
              <h6 style={{ color: 'red' }}>Bulk-Up Project</h6>
              <h4>12-weken<br/>traject</h4>
              <div className="pricing">
                <span>€159</span>
                <span className="old-price">€199</span>
              </div>
              <p>
                The perfect way to get started and get used to our tools.
              </p>

              <ul>
                <li>
                  1 personal training sessions per week
                </li>
                <li>
                  Nutritional advice and support
                </li>
                <li>
                  Monthly fitness evaluation
                </li>
              </ul>

              <a href="#im-ready" className="button">
                Ik wil beginnen
              </a>
            </div>
          </div> */}
          <div className="col-md-4 mx-auto">
            <div className="prijs-card best-deal">
              {/* <div className="label pulse-gray">Most chosen</div> */}
              <div className="sticker">
                <span>50%</span>
                <span>korting</span>
              </div>
              {/* <div className="sticker extra">
                <span>én krijg je</span>
                <span>eerste week</span>
                <span>gratis</span>
              </div> */}
              <h6 style={{ color: '#000' }}>Thrive-X</h6>
              <h4>8-weeks<br/>traject</h4>
              <div className="pricing">
                <span>€99</span>
                <span className="old-price">€198</span>
              </div>
              <p>
                Bespaar €99 als één van mijn eerste eigen klanten!
              </p>

              <ul>
                <li>
                  Persoonlijke aandacht
                </li>
                <li>
                  24/7 mogelijkheid om vragen te stellen
                </li>
                <li>
                  Altijd antwoord binnen 24 uur
                </li>
                <li>
                  Advies op maat voor jou
                </li>
                <li>
                  Motivatie en ondersteuning
                </li>
                <li>
                  Samen tussentijdse doelen stellen
                </li>
                <li>
                  Progressie- en prestatieanalyse
                </li>
                <li>
                  Doelen bijstellen waar nodig
                </li>
                <li>
                  Voedingsadvies en maaltijdplanning op maat
                </li>
                <li>
                  Trainingsadvies op maat
                </li>
                <li>
                  Suppletieadvies op maat
                </li>
                <li>
                  Caloriebehoefte-check
                </li>
                {/* <li>
                  Blessure-check
                </li>
                <li>
                  Slaap-check
                </li> */}
                <li>
                  Trainingsvideo's naar keuze
                </li>
                <li>
                  Kortingen voor suppletie
                </li>
              </ul>
              
              <small style={{ color: "#fff", textAlign: "center", marginBottom: "12.5px" }}>Investeer in jezelf en zie hoe je fysieke en mentale welzijn positief transformeren!</small>

              <button onClick={() => handleClick()} className="button">
                Start mijn transformatie
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="prijs-timer">
              <div className="timer">
                <Countdown 
                  date={tomorrow} 
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Prijzen;
