import { useContext } from 'react'
import AppContext from '../../AppContext';
import './Voordelen.css';

function Voordelen() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const scrollToPrices = () => {
    const section = document.querySelector('#prijzen');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  }

  return (
    <section className="services" id="services" style={{ paddingTop: '0' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h6>Voordelen</h6>
              {/* <h2>Waarom ik een goede keus ben</h2> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="content-list">
              <div className="voordeel-list-heading">
                <h3>Fysieke transformatie</h3>
              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verbeterde gezondheid</h3>
                  <p>
                  Het bereiken van een gezond gewicht kan de algehele gezondheid van een persoon verbeteren. Het kan het immuunsysteem versterken, de weerstand tegen ziekten verhogen en het risico op bepaalde gezondheidsproblemen verminderen.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verhoogde energieniveaus</h3>
                  <p>
                  Ervaar meer vitaliteit en uithoudingsvermogen, zodat je de dagelijkse taken en activiteiten gemakkelijker aankunt.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Betere voedingsgewoonten</h3>
                  <p>
                  Door mijn deskundige begeleiding en ondersteuning, kan ik je helpen bij het maken van gezonde keuzes, het leren van evenwichtige maaltijden en het ontwikkelen van een positieve relatie met voedsel. Dit proces zal resulteren in een verbeterde algemene voeding en een gezondere levensstijl op lange termijn.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verbeterde houding en lichaamsbewustzijn</h3>
                  <p>
                  Ontwikkel een betere houding en lichaamsbewustzijn, wat kan bijdragen aan een zelfverzekerde uitstraling.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Kracht en spieropbouw</h3>
                  <p>
                  Ontwikkel een sterker en gespierder lichaam dat indruk maakt en je in staat stelt om fysieke uitdagingen aan te gaan.
                  </p>
                </div>

              </div>

              <button onClick={() => scrollToPrices()} className="button">
                Ik wil meer informatie
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-list">
              <div className="voordeel-list-heading">
                <h3>Mentale transformatie</h3>
              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verhoogd zelfvertrouwen</h3>
                  <p>
                  Bouw zelfvertrouwen op door je fysieke verschijning te verbeteren en te weten dat je controle hebt over je lichaam en gezondheid.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verbeterd zelfbeeld</h3>
                  <p>
                  Ontwikkel een positiever zelfbeeld en leer van jezelf te houden, ongeacht je uiterlijk.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Versterkt gevoel van eigenwaarde</h3>
                  <p>
                  Versterk je gevoel van eigenwaarde door fysieke doelen te bereiken en jezelf te overtreffen.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verminderde onzekerheid en schaamte</h3>
                  <p>
                  Overwin gevoelens van onzekerheid en schaamte die verband houden met een dun postuur, en voel je zelfverzekerder in sociale situaties.
                  </p>
                </div>

              </div>
              <div className="voordeel-item-new">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#353535"/>
                  <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="voordeel-content">
                  <h3 className="mt-0 mb-2">Verbeterde mentale veerkracht</h3>
                  <p>
                  Ontwikkel een sterkere mindset en leer om te gaan met uitdagingen, wat kan leiden tot een betere algehele mentale gezondheid.
                  </p>
                </div>

              </div>

              <button onClick={() => scrollToPrices()} className="button">
                Ik wil meer informatie
              </button>
            </div>
          </div>
        </div>
        <div className="row button-row">
          <div className="col-md-12">
            <div className="d-flex justify-content-center mt-5">
              <button onClick={() => scrollToPrices()} className="button">
                Ik wil meer informatie
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Voordelen;
