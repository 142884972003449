

function ThankYou() {
  return (
    <>
      {/* Navigatie */}
      <nav className="d-none">
        <div className="container">
          <div className="row align-items-center justify-content-end">
            <div className="logo">
              <a href="#">
                <img src="/img/hlp-logo-wit.svg" alt="Logo Healthy Living Project" />
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Header */}
      <div className="page__header" style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')`, height: '100vh' }}>
        <div className="container">
          <div className="row">
            <div className="header__img">
              <img className="w-100" src="/img/page-header/stef-ridder-img.png" alt="" />
            </div>
            <div className="col-md-7 ml-auto mt-5 mt-md-0">
              <div className="header__content">
                <h5>Thank you for your trust</h5>
                <h1>Your fitness journey has begun!</h1>
                <p className="mb-2">
                  Keep an eye on your e-mail. I will send you a fun questionnaire to help me better understand your goals and how I can best help you achieve them!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThankYou;