import './Diensten.css';

function Diensten() {

  return (
    <section className="diensten" id="diensten" style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mx-auto text-center d-flex justify-content-center">
            <div className="section-heading">
              <h6>Diensten</h6>
              <h2>Hoe ik jou ga helpen</h2>
              <p>
                Een traject dat gericht is op jouw succes - Dit is wat je kunt verwachten:
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="dienst-list">
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Persoonlijk voedingsplan</h5>
                  <p>
                  Een op maat gemaakt voedingsplan dat is afgestemd op de behoeften en doelen van elke individuele klant.
                  </p>
                </div>
              </div>
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Caloriebehoefteberekening</h5>
                  <p>
                  Een analyse om de juiste calorie-inname vast te stellen die nodig is om gewichtstoename te bevorderen op een gezonde manier.
                  </p>
                </div>
              </div>
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Voedingsadvies en -begeleiding</h5>
                  <p>
                  Ondersteuning en begeleiding bij het maken van gezonde voedselkeuzes en het samenstellen van evenwichtige maaltijden en snacks.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Wekelijkse check-ins</h5>
                  <p>
                  Reguliere follow-ups om de voortgang te bespreken, eventuele obstakels aan te pakken en aanpassingen aan het plan te maken indien nodig.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Ondersteuning bij boodschappen doen</h5>
                  <p>
                  Hulp bij het identificeren van gezonde voedingsmiddelen en het maken van verstandige keuzes tijdens het boodschappen doen.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Recepten en maaltijdideeën</h5>
                  <p>
                  Inspiratie en suggesties voor voedzame maaltijden en snacks die bijdragen aan gewichtstoename.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Gezonde gewichtstoename monitoring</h5>
                  <p>
                  Het bijhouden van gewichtstoename en het aanpassen van het plan indien nodig om een gezonde en gestage progressie te bevorderen.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Tips voor eetlustvergroting</h5>
                  <p>
                  Advies en strategieën om de eetlust te vergroten en de inname van voedsel te verhogen.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Lifestyle-aanpassingen</h5>
                  <p>
                  Aanbevelingen voor eventuele noodzakelijke aanpassingen in levensstijl die bijdragen aan gewichtstoename, zoals veranderingen in slaappatroon of stressmanagement.
                  </p>
                </div>
              </div>
              
              <div className="dienst-item">
                <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 14.9943V16.3101C31.9981 19.3942 30.9507 22.3951 29.014 24.8652C27.0772 27.3354 24.3549 29.1424 21.253 30.0169C18.1511 30.8913 14.8359 30.7863 11.8017 29.7175C8.76752 28.6487 6.17698 26.6734 4.41644 24.0861C2.6559 21.4989 1.81969 18.4383 2.03252 15.3609C2.24534 12.2835 3.49581 9.35411 5.59742 7.00966C7.69903 4.66522 10.5392 3.03132 13.6943 2.35164C16.8494 1.67196 20.1504 1.98292 23.105 3.23815" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32 4.8606L16.6154 19.1625L12 14.8762" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <div className="dienst-content">
                  <h5 className="m-0">Continue motivatie en ondersteuning</h5>
                  <p>
                  Ondersteuning gedurende het hele traject om motivatie hoog te houden, vragen te beantwoorden en klanten te helpen obstakels te overwinnen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Diensten;
