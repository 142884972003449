import { Modal, Button, Group, TextInput, NumberInput, Checkbox, Box, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import styled from 'styled-components'

import axios from 'axios';


import PageHeader from '../components/Gids/PageHeader'
// import BeforeAfter from '../components/Gids/BeforeAfter'
import Text from '../components/Gids/Text'
import Voordelen from '../components/Gids/Voordelen' 
import Recensies from '../components/Gids/Recensies'
// import Diensten from '../components/Gids/Diensten'
import CallToAction from '../components/Gids/CallToAction'
import Prijzen from '../components/Gids/Prijzen'
// import Video from '../components/Gids/Video'
// import BigVideo from '../components/Gids/BigVideo'

function Gids() {

  return (
    <>
      {/* Header */}
      <PageHeader />
      {/* Text */}
      <Text />
      {/* Voordelen */}
      <Voordelen />
      {/* CallToAction */}
      <CallToAction />
      {/* Recensies */}
      <Recensies />
      {/* Prijzen */}
      <Prijzen />
    </>
  )
}

export default Gids;