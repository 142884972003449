import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';
import ReactPixel from 'react-facebook-pixel';

import './OfferPakket.css';
import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import PaymentIcons from './PaymentIcons';
import { Checkbox, Select, Box } from '@mantine/core';
import { useInViewport } from '@mantine/hooks';

// Import Swiper React components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('336340599280167', options);

function Offer({ defaultPrice }) {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const [openedModal, setOpenedModal] = useState(null);

  const openModal = (id) => {
    console.log('Open modal');

    setOpenedModal(id);
  }

  const closeModal = (id) => {
    console.log('Close modal');

    setOpenedModal(null);
  }

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const [activeFaq, setActiveFaq] = useState(1);

  // const today = new Date()
  // const tomorrow = new Date(today)
  // tomorrow.setDate(tomorrow.getDate() + 1)
  // tomorrow.setHours(0,0,0,0) 

  var today = new Date();
  today.setHours(today.getHours() + 4);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const schrijfInOpPakket = (id) => {
    
 
		const options = {
			method: 'POST',
			headers: {accept: 'application/json', 'content-type': 'application/json'},
			body: JSON.stringify({
				email: globalState.email, 
				firstName: globalState.naam
				// tel: tel.replace('+3106', '+316').replace('+31', '').replace('06', '6')
			})
		};  

		setLoading(true);
		
		fetch(`https://healthyliving-project.com/api/klaviyo/pakket-${id}`, options)
		// fetch(`http://localhost:5000/klaviyo/pakket-${id}`, options)
			.then(response => response.json()) 
			.then(response => {
				console.log(response)
				setLoading(false);
			}) 
			.catch(err => console.error(err));
  }
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null)

	const navigate = useNavigate();

  useEffect(function() {
    if (searchParams.get("paymentResponse")) {
      setPaymentStatus(searchParams.get("paymentResponse"));
    }
  }, [searchParams]);

  const [title, setTitle] = useState('60 Dagen Spiermassa Challenge Ticket');

  const createPayment = () => {
    setLoading(true);

    const values = {
      "message": "Payment",
      "title": title,
      "email": globalState.email, 
      "naam": globalState.naam,
      "amount": defaultPrice
    }

    // axios.post(`https://localhost:5000/api/stripe/`, values, {
    axios.post(`https://healthyliving-project.com/api/stripe/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response); 
      console.log(response.data.session.url);

      ReactPixel.trackCustom('Add To Cart', {currency: "EUR", value: defaultPrice});

      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done'); 
      setLoading(false);
      
      // navigate(`/thank-you`);
    });
  }

  const [price, setPrice] = useState(defaultPrice);
  const [selectValue, setSelectValue] = useState('3 dagen');

  const setAmount = (amount, checked) => {

    let newAmount;
    console.log(amount);
    console.log(price);

    if (checked) {
      newAmount = Number(Number(price) + Number(amount)).toFixed(2);
    } else {
      newAmount = Number(Number(price) - Number(amount)).toFixed(2);
    }

    setPrice(newAmount);
  }
  
  const { ref, inViewport } = useInViewport();

  const [stickySet, setStickySet] = useState(false);

  useEffect(() => {
    if (stickySet) return;

    if (inViewport) {
      setStickySet(true);
    }
  }, [inViewport])


  const images = [
    {
      img: "/img/offer/offer-gids.jpg",
      description: "Spiermassa Gids"
    },
    {
      img: "/img/offer/offer-schema.jpg",
      description: "Op maat gemaakt schema (voorbeeld)"
    },
    {
      img: "/img/offer/offer-workout.jpg",
      description: "Workouts bijhouden"
    },
    {
      img: "/img/offer/offer-logboek.jpg",
      description: "Logboek"
    },
    {
      img: "/img/offer/offer-app.jpg",
      description: "Eiwitten bijhouden"
    },
    // {
    //   img: "/img/offer/offer-recepten.jpg",
    //   description: "Receptenboek"
    // }
  ]
 
  return (
    <>
      <div id="offer" className="section pakket-section">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <h3>Unieke aanbieding 👇</h3>
            </div>
          </div> */}

          <div className="offer-sticker">
            <span>Profiteer van</span>
            <span>60%</span>
            <span>korting</span>
          </div>

          <div className="row mt-md-5 justify-content-center">
        
            {/* <div className="col-md-10 mx-auto">
              <h2>Ben je klaar voor de beste resultaten van je leven?</h2>
            </div> */}

            <div className="col-md-8 mx-auto">
              <div className="prijs-card">
                <h2 className="mb-4">Investeer vandaag nog in jezelf</h2>

                <p>
                  Ben je <b>helemaal klaar</b> met de minimale resultaten die je behaalt, terwijl je al je tijd en energie in het sporten stopt? Dan is de 60 Dagen Spiermassa Challenge precies wat je nodig hebt! Alles is voor je uitgestippeld, je hoeft het alleen nog te volgen. Dit ontvang je van mij:
                </p>

                        
                {/* <Swiper
                  className="mt-5 pt-5 w-100"
                  modules={[Navigation, Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation
                  autoHeight={false}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {
                    images.map((image, index) => (
                      <SwiperSlide key={index} >
                        <div className="img offer-img d-flex justify-content-center">
                        <img style={{ maxHeight: '350px' }} src={ image.img } alt="" />
                        </div>

                        <small style={{ color: '#000', textAlign: 'center', marginTop: '20px', display: 'flex', justifyContent: 'center' }}>{ image.description }</small>
                      </SwiperSlide>
                    ))
                  }
                </Swiper> */}

                <ul className="offer-items">
                  
                  <li>
                    <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                    </svg>
                    <div className="offer-content">
                      <h4>Persoonlijk trainingsschema</h4>
                      <p>
                        Een op maat gemaakt en doeltreffend trainingsschema. Gemaakt aan de hand van een intake-formulier, voor jouw niveau en je gewenste aantal trainingsdagen per week!
                      </p>
                      <p style={{ fontSize: '16px', marginTop: '10px' }}>
                        Met alternatieve oefeningen zodat je altijd iets kunt doen - ook in een drukke gym!
                      </p>
                    </div>
                  </li>

                  <li>
                    <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                    </svg>
                    <div className="offer-content">
                      <h4>Ultieme Spiermassa Gids</h4>
                      <p>
                        Geen uitgebreide e-book van 150 pagina's die je toch niet gaat lezen, maar een <b>beknopte gids</b> van een aantal pagina's met de allerbelangrijkste informatie die je nodig hebt over voeding, training, rust en herstel. 
                      </p>
                    </div>
                  </li>

                  <li>
                    <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                    </svg>
                    <div className="offer-content">
                      <h4>Online contact met een expert</h4>
                      <p>
                        Kom je er gedurende de challenge niet uit? Loop je ergens vast of wil je meer informatie? Je krijgt een rechtstreekse verbinding met een online personal coach, waarbij <b>dagelijks</b> al je vragen beantwoordt worden via de mail.
                      </p>
                    </div>
                  </li>

                  <li>
                    <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                    </svg>
                    <div className="offer-content">
                      <h4>Challenge Logboek</h4>
                      <p>
                        Je ontvangt een logboek waarin je je progressie kunt bijhouden voor optimale resultaten.
                      </p>
                    </div>
                  </li>

                </ul>

                <p>
                  Nu <b>60% KORTING</b>. Dus dit alles krijg je voor slechts...
                </p>

                <div className="pricing">
                  <span style={{ color: 'var(--red)' }}>€{ defaultPrice.toString().replace('.', ',') }</span>
                  <span className="old-price">{ defaultPrice == 29.99 ? `€64,99` : `€89,99` }</span>
                </div>

                <div className="klarna-price">
                  <span>Je kunt zelfs in 3 termijnen van €{ defaultPrice == 29.99 ? `10,00` : `13,33` } betalen. Renteloos. Met <img style={{ width: '58px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Klarna_Payment_Badge.svg/640px-Klarna_Payment_Badge.svg.png" alt="" /></span>
                </div>

                <Box
                  ref={ref}
                >
                  <p className="mt-5">
                    {/* Mis deze kans niet. Ben je niet tevreden? Dan krijg je je geld meteen terug, zonder vragen. */}
                    {/* <br/><br/> */}
                    Maar wacht... Omdat het bijna zomer is geef ik het volgende er ook nog <i>tijdelijk</i> gratis bij 🤯🤯:
                  </p>
                </Box>

                <ul className="offer-items">

                  <li>
                    <div className="list-icon">
                      🎁
                    </div>
                    <div className="offer-content">
                      <h4>Toegang tot mobiele apps</h4>
                      <p>
                        Volg je vooruitgang in de sportschool en in de keuken met handige apps waarmee je je prestaties kunt bijhouden en doelen kunt stellen voor continu succes. Geen zorgen, je krijgt stap voor stap instructies om deze in te stellen.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="list-icon">
                      🎁
                    </div>
                    <div className="offer-content">
                      <h4>De Complete Eiwitlijst</h4>
                      <p>
                        Een verzameling van de beste eiwitbronnen die je kunt toevoegen aan jouw eetpatroon. Gesorteerd op prijs zodat je zelfs op een budget simpel eiwitten kunt toevoegen!
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="list-icon">
                      🎁
                    </div>
                    <div className="offer-content">
                      <h4>Supplementengids</h4>
                      <p>
                        Een gids samengesteld om je te informeren over alles wat je moet weten over supplementen. Van de wetenschap achter populaire supplementen tot tips voor het veilig en effectief gebruik ervan, deze gids biedt een diepgaand inzicht in het spectrum van supplementen, zodat je weloverwogen keuzes kunt maken die passen bij jouw fitnessdoelen.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="list-icon">
                      🎁
                    </div>
                    <div className="offer-content">
                      <h4>Gids: 20 Meest Gemaakte Fouten bij Spieren Opbouwen</h4>
                      <p>
                        Een 19 bladzijden tellende gids (geen paniek, groot lettertype gebruikt! 😆) met waardevolle inzichten over veelvoorkomende valkuilen bij het opbouwen van spiermassa en leer hoe je ze kunt vermijden.
                      </p>
                    </div>
                  </li>

                </ul>

                <div className="row mb-5">
                  <div className="col-md-8 mx-auto">
                    <img src="/img/complete-bundel.png" alt="" style={{ width: '100%' }} />
                  </div>
                </div>

                {/* <h4 className="mb-3">Hoeveel dagen train je per week?</h4>      
                <Select
                  value={selectValue}
                  placeholder={selectValue}
                  data={['1 dagen', '2 dagen', '3 dagen', '4 dagen', '5 dagen', '6 dagen', '7 dagen']}
                  onChange={(_value, option) => {
                    console.log(_value);
                    setTitle(`Het Ultieme Starters Schema - ${_value}`);
                    setSelectValue(_value);
                  }}
                /> */}

                {/* <h4 className="mb-3 mt-4">Extra's</h4> */}
                {/* <div className="extra-option">
                  <Checkbox
                    label="Heb je speciale wensen of behoeften of weinig apparatuur tot je beschikking? Laat het schema op maat maken voor jouw unieke wensen en behoeften. Ontvang het schema binnen 24 uur na het invullen van een intake-formulier. (+ €19.99)"
                    onChange={(event) => setAmount(19.99, event.currentTarget.checked)}
                  />
                </div> */}
                {/* <div className="extra-option mt-2">
                  <Checkbox
                    label="4 weken online 1-op-1 coaching via WhatsApp - Past in een druk (sociaal) leven (+ €29.99)"
                    onChange={(event) => setAmount(29.99, event.currentTarget.checked)}
                  /> */}
                {/* </div> */}
                
                
                <div className={`button-container text-center mt-4 ${stickySet ? 'sticky-button' : null}`} style={{ flexDirection: 'column' }}>
                  <button className="button button-red" type="button" onClick={() => createPayment()}>
                    Ik Wil Beginnen! - €{ defaultPrice.toString().replace('.', ',') } <span style={{ textDecoration: 'line-through'}}>{ defaultPrice == 29.99 ? `€64,99` : `€89,99` }</span>
                  </button>

                  <div className="pay-info mt-2">
                    <span className="betalen-desc d-flex mb-2 justify-content-center">
                      Veilig betalen met:
                    </span>
                    <PaymentIcons />
                  </div>

                  <small style={{ fontSize: '14px', color: "#000" }}>Ben je niet tevreden? Je ontvangt je geld terug zonder vragen!</small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Offer;