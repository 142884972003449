import { useContext, useEffect, useState } from 'react'
import AppContext from '../../AppContext';

import styled from 'styled-components'
import { Link } from 'react-router-dom';

import { useExitIntent } from 'use-exit-intent'
import * as animationData from './gift-popper.json'
import Lottie from 'react-lottie';

import Countdown, { zeroPad } from 'react-countdown';

import './QuizSteps.css';
import QuizQuestion from './QuizQuestion';

function QuizSteps() {
  const { globalState, updateGlobalState } = useContext(AppContext);
  
  const { registerHandler } = useExitIntent()

  registerHandler({
    id: 'openModal',
    handler: () => console.log('Hello from handler!')
  })

  // Update the global state
  const openVideo = () => {
    updateGlobalState('video_opened', true);
    console.log(globalState);
  };

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const [activeStep, goToStep] = useState(1);
  const [answerChosen, chooseAnswer] = useState(null);
  
  const quiz = [
    {
        "question": "Ben je een man of een vrouw?",
        "type": "button",
        "answers": [
            "Vrouw",
            "Man"
        ],
        "selected_answer": null
    },  
    {
        "question": "Wat is je leeftijd?",
        "type": "number",
        "answers": [
            ""
        ],
        "selected_answer": null
    },  
    {
        "question": "Wat is je lengte?",
        "type": "number",
        "appendix": "cm",
        "answers": [
            ""
        ],
        "selected_answer": null
    },  
    {
        "question": "Hoeveel weeg je?",
        "type": "number",
        "appendix": "kg",
        "answers": [
            ""
        ],
        "selected_answer": null
    },
    {
        "question": "Hoe actief ben je?",
        "type": "button",
        "answers": [
            "Een beetje actief",
            "Gemiddeld actief",
            "Erg actief"
        ],
        "selected_answer": null
    },
    {
        "question": "Welke van de volgende beschrijft jou het best?",
        "type": "button",
        "answers": [
            "Ik kan niet groter worden of spieren opbouwen, hoeveel ik ook eet of sport.",
            "Ik ben 'skinny fat'. Ik lijk slank in kleding, maar ik heb wel lichaamsvet.",
            "Ik ben tevreden met mijn lichaam, maar moet nog één laatste laag vet verliezen.",
            "Ik ben niet tevreden met mijn lichaam en wil een serieus aantal kilo's afvallen."
        ],
        "selected_answer": null
    }
  ]
//   const quiz = [
//     {
//         "question": "Denk je dat je moeilijk aan kunt komen doordat je er de genen niet voor hebt?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je nooit aankomt, ook al eet je veel?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je moeite hebt met aankomen door problemen met je hormonen?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je moeilijk aan kunt komen door veel stress in je leven?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je moeilijk aan kunt komen omdat je metabolisme te snel is?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je lichaam geen vet opslaat, zelfs niet als je veel eet?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Heb je ooit geprobeerd om spieren op te bouwen om aan te komen?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Denk je dat je niet aankomt omdat je erg actief bent gedurende de dag?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Heb je het gevoel dat je altijd dun zult blijven, wat je ook doet?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Geloof je dat een snelle schildklier een reden is waarom je moeilijk aan lijkt te komen?",
//         "answers": [
//             "Ja",
//             "Nee",
//             "Weet ik niet"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Heb je het gevoel dat je lichaam altijd terugkeert naar een bepaald gewicht, hoe hard je ook probeert?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Sport je regelmatig maar kom je niet aan in gewicht?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Heb je ooit calorieën geteld om te proberen aan te komen?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     },  
//     {
//         "question": "Hoor je van mensen om je heen dat je gewoon een snelle stofwisseling hebt en daarom niet aankomt?",
//         "answers": [
//             "Ja",
//             "Nee"
//         ],
//         "selected_answer": null
//     }
//   ]

//   const quiz = [
//     {
//         "question": "Hoeveel kilo weeg je momenteel?",
//         "answers": [
//             "Onder de 50 kg",
//             "Tussen 50 en 60 kg",
//             "Tussen 60 en 70 kg",
//             "Tussen 70 en 80 kg",
//             "Boven de 80 kg"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel calorieën denk je dagelijks binnen te krijgen?",
//         "answers": [
//             "Minder dan 1500",
//             "Tussen 1500 en 2500",
//             "Meer dan 2500",
//             "Geen idee, ik tel ze niet",
//             "Wat zijn calorieën?"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel gram eiwitten consumeer je dagelijks?",
//         "answers": [
//             "Minder dan 50 g",
//             "Tussen 50 g en 100 g",
//             "Meer dan 100 g",
//             "Ik hou mijn eiwitinname niet bij"
//         ],
//         "selected_answer": null
//     }
//   ]
  // const quiz = [
  //   {
  //       "question": "Hoe lang probeer je al om je gewicht aan te passen?",
  //       "answers": [
  //           "Minder dan 6 maanden",
  //           "Tussen 6 maanden en 1 jaar",
  //           "1 tot 2 jaar",
  //           "Meer dan 2 jaar"
  //       ],
  //       "selected_answer": null
  //   },  
  //   {
  //       "question": "Heb je ooit met een fitnesscoach gewerkt?",
  //       "answers": [
  //           "Ja, ik heb momenteel een coach",
  //           "Ja, maar in het verleden",
  //           "Nee, nog nooit"
  //       ],
  //       "selected_answer": null
  //   },
  //   {
  //       "question": "Heb je ooit calorieën geteld om je voedselinname bij te houden?",
  //       "answers": [
  //         "Ja, regelmatig",
  //         "Af en toe",
  //         "Nee, nooit" 
  //       ],
  //       "selected_answer": null
  //   },  
  //   {
  //       "question": "Hoe tevreden ben je met je huidige gewicht?",
  //       "answers": [
  //           "Zeer tevreden",
  //           "Gematigd tevreden",
  //           "Neutraal",
  //           "Ongemakkelijk",
  //           "Zeer ontevreden"
  //       ],
  //       "selected_answer": null
  //   },
  //   {
  //       "question": "Welk resultaat zou je graag willen zien op het gebied van gewicht?",
  //       "answers": [
  //           "Aankomen",
  //           "Aankomen in spiermassa",
  //           "Gewichtsverlies",
  //           "Geen specifiek doel",
  //           "Onderhouden van huidig gewicht"
  //       ],
  //       "selected_answer": null
  //   },
  //   {
  //       "question": "Wat beschrijft het beste jouw huidige aanpak?",
  //       "answers": [
  //         "Ik heb geen duidelijke strategie",
  //         "Ik volg online advies op",
  //         "Ik heb mijn eigen plan, maar het werkt niet"
  //       ],
  //       "selected_answer": null
  //   },
  //   {
  //       "question": "Hoe vaak train je momenteel?",
  //       "answers": [
  //         "0-1 keer per week",
  //         "2-3 keer per week",
  //         "4-5 keer per week",
  //         "Dagelijks"
  //       ],
  //       "selected_answer": null
  //   },
    // {
    //     "question": "Wat is jouw grootste uitdaging bij het aankomen?",
    //     "answers": [
    //       "Gebrek aan eetlust",
    //       "Onregelmatig eten",
    //       "Te veel cardio",
    //       "Andere",
    //       "Ik heb geen idee"
    //     ],
    //     "selected_answer": null
    // },
    // {
    //     "question": "Hoe belangrijk vind je het om professionele begeleiding te hebben bij jouw doelen?",
    //     "answers": [
    //       "Zeer belangrijk",
    //       "Belangrijk",
    //       "Neutraal",
    //       "Niet belangrijk"
    //     ],
    //     "selected_answer": null
    // },
    // {
    //     "question": "Hoe benader je jouw fitnessdoelen?",
    //     "answers": [
    //       "Ik heb graag begeleiding zodat ik weet wat ik moet doen",
    //       "Ik doe zelf onderzoek en stel mijn eigen plan op"
    //     ],
    //     "selected_answer": null
    // },
    // {
    //     "question": "Heb je ooit supplementen gebruikt om aan te komen?",
    //     "answers": [
    //       "Ja, regelmatig",
    //       "Af en toe",
    //       "Nee, nooit"  
    //     ],
    //     "selected_answer": null
    // },
//     {
//         "question": "Wat motiveert jou het meest om je gewicht te veranderen?",
//         "answers": [
//           "Gezondheidsredenen",
//           "Zelfvertrouwen",
//           "Sportprestaties",
//           "Ik heb andere redenen"
//         ],
//         "selected_answer": null
//     }
// ];

//   const quiz = [
//     {
//         "question": "Hoe tevreden ben je met je huidige gewicht?",
//         "answers": [
//             "Zeer tevreden",
//             "Gematigd tevreden",
//             "Neutraal",
//             "Ongemakkelijk",
//             "Zeer ontevreden"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Welk resultaat zou je graag willen zien op het gebied van gewicht?",
//         "answers": [
//             "Aankomen",
//             "Aankomen in spiermassa",
//             "Gewichtsverlies",
//             "Geen specifiek doel",
//             "Onderhouden van huidig gewicht"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Heb je eerder moeite gehad met aankomen in gewicht?",
//         "answers": [
//             "Nee, vroeger was ik voller.",
//             "Ik heb nooit moeite gehad met aankomen",
//             "Ja, het is een doorlopend proces",
//             "Ik probeer niet specifiek in gewicht aan te komen",
//             "Eerder moeite met afvallen"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Heb je ooit geprobeerd calorieën te tellen?",
//         "answers": [
//             "Dat doe ik altijd",
//             "Ja, regelmatig",
//             "Af en toe",
//             "Nee, nog nooit"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe zou je jouw huidige dieet omschrijven?",
//         "answers": [
//             "Evenwichtig en voedzaam",
//             "Redelijk gebalanceerd",
//             "Onregelmatig maar oké",
//             "Veel ruimte voor verbetering",
//             "Ik weet het niet"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel calorieën denk je dagelijks binnen te krijgen?",
//         "answers": [
//             "Minder dan 1500",
//             "Tussen 1500 en 2500",
//             "Meer dan 2500",
//             "Ik heb geen idee"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Waarom denk je dat eiwitten belangrijk zijn voor je lichaam?",
//         "answers": [
//             "Ze herstellen en bouwen spieren op",
//             "Ze maken gerechten lekkerder",
//             "Ze dienen als brandstof",
//             "Ik weet het niet"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel gram eiwitten consumeer je dagelijks?",
//         "answers": [
//             "Minder dan 50 g",
//             "Tussen 50 g en 100 g",
//             "Meer dan 100 g",
//             "Ik hou mijn eiwitinname niet bij"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Wat is jouw geslacht?",
//         "answers": [
//             "Man",
//             "Vrouw",
//             "Ik identificeer me anders"
//         ],
//         "selected_answer": null
//     },    
//     {
//         "question": "Hoeveel kilo weeg je momenteel?",
//         "answers": [
//             "Onder de 50 kg",
//             "Tussen 50 en 60 kg",
//             "Tussen 60 en 70 kg",
//             "Tussen 70 en 80 kg",
//             "Boven de 80 kg"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Wat is jouw lengte?",
//         "answers": [
//             "Minder dan 160 cm",
//             "160 cm - 170 cm",
//             "170 cm - 180 cm",
//             "180 cm - 190 cm",
//             "Meer dan 190 cm"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe consistent ben je in het volgen van een gezonde levensstijl?",
//         "answers": [
//             "Zeer consistent",
//             "Matig consistent",
//             "Soms",
//             "Zelden"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Wat is jouw belangrijkste doel?",
//         "answers": [
//             "Aankomen",
//             "Algemene fitheid",
//             "Spieropbouw",
//             "Verbetering van de gezondheid",
//             "Afvallen",
//             "Ik weet het niet / geen specifiek doel"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel maaltijden eet je gemiddeld per dag?",
//         "answers": [
//             "Minder dan 3 maaltijden per dag",
//             "3 maaltijden per dag",
//             "Meer dan 3 maaltijden per dag",
//             "Mijn maaltijden variëren"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel uur slaap probeer je te krijgen per nacht?",
//         "answers": [
//             "Ongeveer 6 uur",
//             "7 tot 9 uur",
//             "Meer dan 9 uur",
//             "Slaap is niet mijn prioriteit",
//             "Ik weet het eigenlijk niet"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Gebruik je momenteel supplementen zoals eiwitpoeder, creatine of magnesium?",
//         "answers": [
//             "Nee, nooit",
//             "Af en toe",
//             "Ja, regelmatig",
//             "Wat zijn voedingssupplementen?"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe gemotiveerd ben je om je doelen te bereiken?",
//         "answers": [
//             "Niet echt gemotiveerd",
//             "Gematigd gemotiveerd",
//             "Zeer gemotiveerd",
//             "Ik ben niet bezig met doelen"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Wat motiveert je het meest om je fitnessdoelen te bereiken?",
//         "answers": [
//             "Zichtbare resultaten",
//             "Gezondheidsverbetering",
//             "Interne voldoening",
//             "Externe goedkeuring"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Ervaar je momenteel blessures, pijnklachten of andere hindernissen bij het werken aan je fitnessdoelen?",
//         "answers": [
//             "Ja, ik ervaar blessures of pijnklachten.",
//             "Nee, ik heb geen belemmeringen op dit moment.",
//             "Ik heb andere remmende factoren"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel weet je over het belang van voeding en training bij het bereiken van je doelen?",
//         "answers": [
//             "Niet veel",
//             "Gemiddeld",
//             "Veel, ik ben goed geïnformeerd",
//             "Ik weet het niet",
//             "Ik zou veel willen leren over voeding en fitness"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe vaak sport je per week?",
//         "answers": [
//             "Minstens 4 keer per week",
//             "2-3 keer per week",
//             "Af en toe",
//             "Ik ben van plan te beginnen met sporten",
//             "Sporten? Dat is niet mijn ding"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe zou je jouw dagelijkse activiteitsniveau op het werk of tijdens je opleiding omschrijven?",
//         "answers": [
//             "Actief (veel lopen en bewegen)",
//             "Hele dag zitten",
//             "Voornamelijk zitten",
//             "Een combinatie van zitten en bewegen",
//             "Het varieert per dag"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Waar zoek je meestal naar informatie over voeding en training?",
//         "answers": [
//             "Boeken en wetenschappelijke artikelen",
//             "Social media en blogs",
//             "Familie of vrienden",
//             "Podcasts of audioboeken",
//             "Wetenschappelijke artikelen",
//             "Ik zoek geen informatie"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Vind je voeding soms wat verwarrend? Kies hieronder het onderwerp dat je het meest verwarrend vindt:",
//         "answers": [
//             "Eiwitten, koolhydraten en vetten",
//             "Calorieën",
//             "Voedingslabels en ingrediëntenlijsten",
//             "Alles is verwarrend",
//             "Ik vind niet veel verwarrend"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Wat is jouw favoriete type training?",
//         "answers": [
//             "Krachttraining",
//             "Cardio",
//             "Yoga of stretch-oefeningen",
//             "Vechtsport",
//             "Ik ben nog zoekende"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoe vaak drink je alcohol?",
//         "answers": [
//             "Dagelijks",
//             "3-4 keer per week",
//             "1-2 keer per week",
//             "Af en toe in het weekend",
//             "Ik drink geen alcohol"
//         ],
//         "selected_answer": null
//     },
//     {
//         "question": "Hoeveel water drink je dagelijks?",
//         "answers": [
//             "Minder dan 2 liter",
//             "Ongeveer 2 liter",
//             "Minstens 3 liter",
//             "Water? Ik vergeet het soms",
//             "Drinken tijdens de training is genoeg",
//             "Ik drink alleen frisdrank"
//         ],
//         "selected_answer": null
//     }
// ];


  useEffect(() => {
    updateGlobalState('quiz', quiz);
  }, [])
 
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [showGift, setShowGift] = useState(null);

  useEffect(() => {
    // setShowGift(globalState.show_gift);
    // updateGlobalState('no_scroll', true);

    setTimeout(() => {
        claimGift();
    }, 3500)
  }, [globalState.show_gift])

  const claimGift = () => {
    console.log('Claim gift');
    updateGlobalState('show_gift', null);
    // updateGlobalState('no_scroll', false);
  }
 
  return (
    <div className={`quiz-steps ${globalState.quiz_started ? 'shown' : ''}`} data-step={ globalState.active_step }
    style={globalState.no_scroll ? { overflowY: 'hidden' } : {}}
    >
        <div className={`gift-overlay ${showGift ? 'shown' : ''}`}></div>
        {/* <div className={`gift-popper ${showGift ? 'shown' : ''}`}>
            <Lottie options={defaultOptions}
            height={'100vh'}
            width={'100vw'}
            isStopped={false} 
            isPaused={false}/>
        </div> */}

        {/* 20 meest gemaakte fouten */}
        {/* { showGift == 1 ? */}
        <div className={`unlocked-gift ${showGift == 1 ? 'shown' : ''}`}>
            <img src="/img/gifts/gift-ebook-20-fouten.png" alt="" />
            <div className="unlocked-title">
                <h4>Je hebt zojuist een van mijn E-Books ontgrendeld!</h4>
                <p>
                    Om je op weg te helpen krijg je deze tool uit mijn Better Body Bundle gratis.
                </p>
            </div>
            {/* <button onClick={() => claimGift()} className="button button-red mt-auto">
                Claim gratis tool
            </button> */}
        </div>
        {/* // : null} */}

        {/* Gids voor calorieën tellen */}
        {/* { showGift == 2 ? */}
        <div className={`unlocked-gift ${showGift == 2 ? 'shown' : ''}`}>
            <img src="/img/gifts/gift-gids-calorie-iname.png" alt="" />
            <div className="unlocked-title">
                <h4>Je hebt zojuist een van mijn gidsen ontgrendeld!</h4>
                <p>
                    Om je op weg te helpen krijg je deze tool uit mijn Better Body Bundle gratis.
                </p>
            </div>
            {/* <button onClick={() => claimGift()} className="button button-red mt-auto">
                Claim gratis tool
            </button> */}
        </div>
        {/* // : null} */}

        {/* trainingsschema */}
        {/* { showGift == 3 ? */}
        <div className={`unlocked-gift ${showGift == 3 ? 'shown' : ''}`}>
            <img src="/img/gifts/gift-trainingsschema.png" alt="" />
            <div className="unlocked-title">
                <h4>Je hebt zojuist een trainingsschema op maat ontgrendeld!</h4>
                <p>
                    Om je op weg te helpen krijg je deze tool uit mijn Better Body Bundle gratis.
                </p>
            </div>
            {/* <button onClick={() => claimGift()} className="button button-red mt-auto">
                Claim gratis tool
            </button> */}
        </div>
        {/* : null } */}

      {
        quiz.map((q, index) => (
          <QuizQuestion 
            key={index}
            total={quiz.length}
            index={index} 
            activeIndex={globalState.active_step}
            data={q}
            lastStep={index == quiz.length - 1 ? true : false}
          />
        ))
      }

    </div>
  )
}

const Label = styled.div`
  background: rgba(255, 118, 34, 1);
  color: #fff;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  

  @media (min-width: 992px) { 
    margin-right: auto;
  }
`

export default QuizSteps;