const Intro = () => {
  return (
    <div className="container pb-md-5 mb-md-5 pt-5 mt-5">
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="section-heading centered dark-heading text-left">
            <h6>Hier is wat je nodig hebt...</h6> 
            {/* <h2 className="mt-2 mb-4">Welkom bij de 60 Dagen Spiermassa Challenge!</h2> */}
            <p>
              {/* Je bent een beginner in de gym of je wilt nog gaan beginnen met sporten... */}
              {/* <br/><br/>
              Op social media zie je overal tegenstrijdige informatie over wat je nou wel en niet moet doen.
              <br/><br/>
              Door alle informatie weet je niet meer waar je moet beginnen of waar je nou goed aan doet, dus je stelt het sporten maar uit. */}
              {/* <br/><br/> */}
              {/* Veel beginners voelen zich overweldigd door de hoeveelheid informatie en weten niet waar ze moeten beginnen met spieropbouw. Vaak ontbreekt het aan een gestructureerd plan, de juiste kennis en persoonlijke begeleiding. */}
              {/* <br/><br/> */}
              {/* Onze <i>Zichtbare Spiermassa in 60 Dagen Challenge</i> biedt een duidelijk en gestructureerd programma dat speciaal is ontworpen voor beginners. Het combineert op maat gemaakte trainingsschema's, een uitgebreide spiermassa gids en online begeleiding van een expert op het gebied van spiermassa (wanneer het jou uitkomt) om jou snel en effectief resultaten te laten zien. */}



              Doe mee aan onze 60 Dagen Training Challenge en krijg alles wat je nodig hebt om spieren op te bouwen, vet te verliezen en je fitnessdoelen te bereiken.
              <br/><br/>
              Ontvang wekelijks een gepersonaliseerd trainingsplan.
              <br/><br/>
              <b>Je hebt geen reden meer om geen spiermassa op te bouwen.</b>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
          
          <a href="#offer" className="button button-white d-flex mt-4 mb-4 justify-content-center">
            Begin Vandaag
          </a>
        </div>
      </div>
    </div>
  );
}
 
export default Intro;