import './Prijzen.css';

import Countdown, { zeroPad } from 'react-countdown';

function Prijzen() {

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-box">
            <span>00</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>dagen</span>
          </div>
        </div>
      );
    }
  };

  return (
    <section className="prijzen" style={{ backgroundImage: `url('/img/services/prijzen-bg.png')` }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              <h6>Investment</h6>
              <h2 className="mt-2 mb-4">Profiteer van mijn tijdelijke korting en begin vandaag met afvallen!</h2>
              <p>
                Investeer in jouw gezondheid en bespaar geld met onze speciale aanbieding voor een 6-, 12- of 18-weeks traject!
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-5">
          <div className="col-md-3 ml-auto">
            <div className="prijs-card">
              <div className="sticker">
                <span>30%</span>
                <span>korting</span>
              </div>
              <h4>6-weken<br/>traject</h4>
              <div className="pricing">
                <span>€140</span>
                <span className="old-price">€200</span>
              </div>
              <p>
                The perfect way to get started and get used to our tools.
              </p>

              <ul>
                <li>
                  1 personal training sessions per week
                </li>
                <li>
                  Nutritional advice and support
                </li>
                <li>
                  Monthly fitness evaluation
                </li>
              </ul>

              <a href="#im-ready" className="button">
                I want to start
              </a>
            </div>
          </div>
          <div className="col-md-3 mx-auto">
            <div className="prijs-card best-deal">
              {/* <div className="label pulse-gray">Most chosen</div> */}
              <div className="sticker">
                <span>35%</span>
                <span>korting</span>
              </div>
              <h4>12-weken<br/>traject</h4>
              <div className="pricing">
                <span>€260</span>
                <span className="old-price">€400</span>
              </div>
              <p>
                Bespaar €75 op jouw gepersonaliseerde voedingsadvies!
              </p>

              <ul>
                <li>
                  1 personal training sessions per week
                </li>
                <li>
                  Nutritional advice and support
                </li>
                <li>
                  Monthly fitness evaluation
                </li>
              </ul>

              <a href="#im-ready" className="button">
                I want to start
              </a>
            </div>
          </div>
          <div className="col-md-3 mr-auto">
            <div className="prijs-card">
              <div className="sticker">
                <span>40%</span>
                <span>korting</span>
              </div>
              <h4>18-weken<br/>traject</h4>
              <div className="pricing">
                <span>€360</span>
                <span className="old-price">€600</span>
              </div>
              <p>
                Bespaar €75 op jouw gepersonaliseerde voedingsadvies!
              </p>

              <ul>
                <li>
                  1 personal training sessions per week
                </li>
                <li>
                  Nutritional advice and support
                </li>
                <li>
                  Monthly fitness evaluation
                </li>
              </ul>

              <a href="#im-ready" className="button">
                I want to start
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="prijs-timer">
              <div className="timer">
                <Countdown 
                  date={tomorrow} 
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Prijzen;
