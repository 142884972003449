import { Modal, Button, Group, TextInput, NumberInput, Checkbox, Box, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams } from "react-router-dom";

import styled from 'styled-components'

import axios from 'axios';

function Payment() {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null)

  useEffect(function() {
    if (searchParams.get("paymentResponse")) {
      setPaymentStatus(searchParams.get("paymentResponse"));
    }
  }, [searchParams]);

  const createPayment = () => {
    const values = {
      "message": "Payment"
    }

    axios.post(`hlp-app-lmvfr.ondigitalocean.app/api/payment/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
      
      // navigate(`/thank-you`);
    });
  }

  return (
    <>
      <h1>Payment</h1>
      <button onClick={() => createPayment()}>Create payment</button>

      {
        paymentStatus == 'success' ?
          <h2>Payment succesful</h2>
        : paymentStatus == 'error' ?
          <h2>Payment error</h2> 
        : null
      }
    </>
  )
}

export default Payment;