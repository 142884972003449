import './BeforeAfter.css';

function BeforeAfter() {

  return (
    <>
      <section id="services" style={{ background: '#2C2C2C' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
              </div>
            </div>
          </div>
          <div className="row" style={{ position: 'relative' }}>
            <div className="col-md-4 mb-5 mb-md-0">
              <h6>Je data inzichtelijk</h6>
              <h2>Nu met gratis app</h2>
              <p style={{ color: "white", marginTop: '25px' }}>
                Ontdek de waarde van gestructureerde data-analyse met mijn handige app, speciaal ontwikkeld om jouw 8-weekse aankom traject te begeleiden. Mijn gebruiksvriendelijke app biedt realtime inzichten in jouw persoonlijke vooruitgang en prestaties, en stelt mij in staat om je traject aan te passen op basis van meetbare resultaten. Met overzichtelijke grafieken en statistieken geeft mijn app je een dieper begrip van jouw fitnessreis, wat je motiveert en helpt bij weloverwogen beslissingen. Ervaar de eenvoudige en effectieve manier om jouw inspanningen om te zetten in tastbare successen, allemaal binnen handbereik via mijn intuïtieve app-interface.
              </p>
            </div>
            <div className="col-md-7 ml-auto">
                <img className="w-100" src="/img/deal-image-big2.png" alt="Logo Healthy Living Project" />
            </div>
          </div>
        </div>
      </section>
      <section className="services" id="services">
        <div className="container">
          <div className="row align-items-center" style={{ position: 'relative' }}>
            <div className="col-md-7 mr-auto">
              <div className="foto-imgs">
                <div className="foto-img">
                  <img src="/img/transformation/transformatie-voor.jpeg" alt="" />
                </div>
                <div className="foto-img">
                  <img style={{ objectPosition: '0 -60px' }} src="/img/transformation/transformatie-na.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5 mt-md-0">
              <h2>Ik was moe van het eeuwige gevecht om aan te komen.</h2>
              <p style={{ color: "white", marginTop: '25px' }}>
                Jarenlang worstelde ik om gewicht aan te komen en probeerde van alles. Ik las boeken, leerde, studeerde en experimenteerde onophoudelijk.Uiteindelijk viel alles op zijn plek. Ik begreep eindelijk hoe het echt werkte.
                <br/><br/>
                Deze persoonlijke reis heeft me zoveel geleerd en nu is het tijd om anderen te helpen.Mijn kennis en ervaringen gebruiken om anderen te begeleiden en te ondersteunen bij hun eigen gewichtstoename.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BeforeAfter;
