import './Page.css';

const Garantie = () => {
  return (
    <div className="featured-review voorwie-section" style={{ background: "#fff" }}>
      <div className="container">
        <div className="row align-items-center mt-5">
          <div className="col-md-8 mx-auto">
            <h3 className="mb-5" style={{ color: "#000" }}>100% Tevredenheidsgarantie</h3>
            <p style={{ fontStyle: 'initial' }} className="mb-4">
            Ik geloof zo sterk in de effectiviteit van dit programma dat ik een volledige geld-terug-garantie bied. Geen gedoe, <b>alleen resultaat of je geld terug.</b>
              {/* Je bent misschien sceptisch over alle beloften en voordelen die deze challenge biedt. Maar laat me je verzekeren, het is niet te mooi om waar te zijn. We zijn zo overtuigd van de effectiviteit van dit programma dat we je een volledige geld-terug-garantie bieden. */}
            </p>

            <div className="voorwie-item">
              <p style={{ fontStyle: 'initial' }}>
                <img style={{ boxShadow: 'none' }} src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Volledige terugbetaling:</b> Als je om welke reden dan ook niet tevreden bent met de challenge, laat het me dan binnen 60 dagen weten en je krijgt je volledige aankoopbedrag terug. Geen vragen gesteld.
                </div>
              </p>
            </div>

            {/* <div className="voorwie-item">
              <p style={{ fontStyle: 'initial' }}>
                <img style={{ boxShadow: 'none' }} src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Risicovrij proberen:</b> We willen dat je met vertrouwen meedoet aan deze challenge, daarom nemen we alle risico's voor je weg. Als het programma niet aan je verwachtingen voldoet, ben je volledig beschermd door onze garantie.
                </div>
              </p>
            </div> */}

            <div className="voorwie-item">
              <p style={{ fontStyle: 'initial' }}>
                <img style={{ boxShadow: 'none' }} src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Geen verborgen voorwaarden:</b> Er zijn geen verborgen voorwaarden. Ik sta volledig achter de kracht van dit programma en ben ervan overtuigd dat het voor jou zal werken. Maar als dat niet het geval is, verwerk ik zonder probleem je terugbetaling.
                </div>
              </p>
            </div>
            

            <p style={{ fontStyle: 'initial' }}>
            <b>Dus waar wacht je nog op?</b> Doe vandaag nog mee aan de challenge en ontdek zelf de transformatieve kracht ervan, zonder enig risico.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-white d-flex mt-5 mb-5 justify-content-center">
              Probeer nu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Garantie;