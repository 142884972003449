const USPs = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="usps">
            {/* <div className="usp-item">
              <h5>Unieke begeleiding</h5>
              <p>
                1-op-1 <i>ONLINE</i> begeleiding van een expert op het gebied van spieropbouw bij mannen
              </p>
            </div> */}
            <div className="usp-item">
              <h5>Uniek voor jouw lichaam</h5>
              <p>
              Een op maat gemaakt trainingsplan, speciaal ontworpen voor jouw unieke wensen en behoeften. 
              </p>
            </div>
            {/* <div className="usp-item">
              <h5>Geen strikt voedingsschema</h5>
              <p>
              Geen gedoe met calorieën tellen of strikte diëten. Je leert hoe je met minimale aanpassingen grote resultaten kunt behalen.              </p>
            </div> */}
            <div className="usp-item">
              <h5>Wekelijkse aanpassingen</h5>
              <p>
              Elke week ontvang je aanpassingen voor je trainingsplan gebaseerd op jouw resultaten en vooruitgang.</p>
            </div>
            <div className="usp-item">
              <h5>Voor elk niveau</h5>
              <p>
              Deze challenge is aan te passen aan elk niveau, voor beginners tot gevorderden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default USPs;