import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import './Page.css';

import { Crisp } from "crisp-sdk-web";

import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import USPs from './USPs';
import Schema from './Schema';
import Wetenschap from './Wetenschap';
import ReviewsList from './ReviewsList';
import OfferPakket from './OfferPakket';
import OfferCard from './OfferPakketCard';
import Gifts from './Gifts';
import Insta from './Instagram';
import Header from './Header';
import Intro from './Intro';
import Resultaten from './Resultaten';
import StickyAtc from './StickyAtc';
import FeaturedReview from './FeaturedReview';
import JouwCoach from './JouwCoach';
import Traject from './Traject';
import Verwachten from './Verwachten';
import VoorWie from './VoorWie';
import NietVoorJou from './NietVoorJou';
import WatMoetIkLaten from './WatMoetIkLaten';
import Garantie from './Garantie';
import Twijfels from './Twijfels';
import WatNodig from './WatNodig';
import DeChallenge from './DeChallenge';
import Waarom from './Waarom';
import TextImage from './TextImage';
import HeaderReview from './HeaderReview';
import OfferItems from './OfferItems';
import Facebook from './Facebook';
import WatJeKrijgt from './WatJeKrijgt';
import Cta from '../Namaak/Cta';

function Trainingsplan({ defaultPrice }) {

  Crisp.configure('3a766b7e-34c5-4cea-8d7c-8b42d4bb1ed6', {
    autoload: false
  });

  useEffect(() => {
    Crisp.load();
  }, [])
  
  const { globalState, updateGlobalState } = useContext(AppContext);

  var today = new Date();
  today.setHours(today.getHours() + 4);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };
  
  const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

  const [activeFaq, setActiveFaq] = useState(0);
  const faqs = [
    {
      "vraag": "Bieden jullie een geld-terug garantie?",
      "antwoord": "We zijn zo overtuigd van onze methode dat als je binnen de 60 dagen niet volledig tevreden bent, we je aankoopbedrag terugbetalen. Ja, we zijn zó serieus over jouw resultaten!"
    },
    {
      "vraag": "Wat als ik weinig fitnessapparatuur tot mijn beschikking heb?",
      "antwoord": "Jij kunt zelf aangeven welke materialen of apparatuur je tot je beschikking hebt. Op basis daarvan kan ik overal een oplossing of alternatief voor bedenken, zodat de training altijd op jouw specifieke situatie is afgestemd!"
    },
    {
      "vraag": "Wanneer gaat de challenge van start?",
      "antwoord": "De challenge is niet aan een bepaalde tijd of datum gebonden. Je begint de challenge wanneer het jou het beste uitkomt en vervolgens krijg je 60 dagen ondersteuning bij je trainingsplan."
    },
    {
        "vraag": "Wat houdt het 60-dagen trainingsplan precies in?",
        "antwoord": "Ons 60-dagen trainingsplan biedt een gepersonaliseerd trainingsplan dat wekelijks wordt aangepast op basis van jouw prestaties en feedback. Elke week ontvang je een nieuw schema dat specifiek is afgestemd op jouw voortgang en doelen."
    },
    {
        "vraag": "Moet ik een app downloaden?",
        "antwoord": "Nee, het is niet nodig om een app te downloaden. Je hebt ten alle tijden toegang tot je trainingsplan via internet. Je kunt dus op zowel je mobiel, tablet als op je computer bij je trainingsplan."
    },
    {
        "vraag": "Hoe wordt mijn trainingsplan gepersonaliseerd?",
        "antwoord": "Allereerst vul je een intake-formulier in waarin je mij informatie over jezelf kunt geven. Hier geef je ook aan hoeveel ervaring je hebt, wat je al in het verleden geprobeerd hebt, hoeveel tijd per week je vrij kunt maken etc. Je vult elke week in hoe je de oefeningen hebt ervaren, inclusief of je de sets hebt gehaald en hoe zwaar de oefeningen voelden op een schaal van 1-10. Op basis van deze informatie stel ik een nieuw trainingsplan voor je op, met aanbevelingen voor het gewicht dat je moet gebruiken."
    },
    {
        "vraag": "Krijg ik ondersteuning van een coach tijdens de challenge?",
        "antwoord": "Ja, gedurende de hele challenge heb je toegang tot mij als je online personal coach. Je kunt al je vragen stellen en ik bied begeleiding en advies om je te helpen je doelen te bereiken."
    },
    {
        "vraag": "Welke informatie ontvang ik bij elke oefening?",
        "antwoord": "Voor elke oefening in je schema ontvang je gedetailleerde instructies over hoe je de oefening moet uitvoeren, waarom deze oefening belangrijk is, welke spiergroepen je ermee traint, hoeveel herhalingen je moet doen, hoeveel rust je moet nemen, en hoeveel rondes je moet doen."
    },
    {
        "vraag": "Hoe weet ik wanneer ik het gewicht moet verhogen?",
        "antwoord": "In je wekelijkse evaluatie geef je aan hoe zwaar de oefeningen aanvoelden. Op basis hiervan geef ik aan wanneer het tijd is om het gewicht te verhogen, zodat je consistent progressie maakt."
    },
    {
        "vraag": "Is er een leeftijdsgrens?",
        "antwoord": "Deze challenge is voor IEDEREEN, van ALLE LEEFTIJDEN. Of je nu een tiener bent of oma Josephine op leeftijd, mijn plannen zijn geschikt voor IEDEREEN!"
    },
    {
        "vraag": "Is er ruimte voor vragen en feedback tijdens de challenge?",
        "antwoord": "Absoluut! Je kunt te allen tijde vragen stellen en feedback geven. Ik sta klaar om je te helpen en ervoor te zorgen dat je het meeste uit je trainingsprogramma haalt."
    },
    {
        "vraag": "Heb ik speciale apparatuur nodig voor het programma?",
        "antwoord": "Het programma is flexibel en kan zowel in de sportschool als thuis worden uitgevoerd. Idealiter heb je toegang tot basisapparatuur, maar we kunnen ook oefeningen aanpassen voor een thuissituatie."
    },
    {
        "vraag": "Wat als ik een oefening niet begrijp of correct kan uitvoeren?",
        "antwoord": "Bij elke oefening worden duidelijke instructies en, indien nodig, alternatieve oefeningen gegeven. Daarnaast kun je altijd contact opnemen voor extra uitleg of alternatieven."
    },
    {
        "vraag": "Wat als ik een oefening niet leuk vindt?",
        "antwoord": "Het is voor mij belangrijk dat jij je workouts leuk vindt en daarom overleggen we samen voor de leukste en beste oefeningen voor jou. Als je tussentijds van oefeningen wilt wisselen dan is dat zeker mogelijk. Je ontvangt de volgende week een vervangende oefening."
    },
    {
        "vraag": "Hoe kan ik mijn voortgang bijhouden?",
        "antwoord": "Je houdt je voortgang bij door het logboek voor je workout in te vullen via onze handige website. Je geeft feedback over elke oefening en geeft aan hoe zwaar deze aanvoelde. Deze gegevens worden gebruikt om je volgende week verder te personaliseren. Aan het einde van de challenge ontvang je een analyze over je prestaties gedurende de challenge."
    },
    {
        "vraag": "Wat voor resultaten kan ik verwachten na 60 dagen?",
        "antwoord": "Met consistentie en toewijding zul je na 60 dagen merkbare verbeteringen zien in je kracht, spierdefinitie, en algehele fitheid. Het programma is ontworpen om je te helpen maximaal resultaat te behalen."
    }
];


  

  const [activeObjection, setActiveObjection] = useState(0); 
  const objectionHandlers = [
    {
      "vraag": "\"Ik heb al eerder geprobeerd om spiermassa op te bouwen, maar het lukte me niet. Waarom zou het deze keer anders zijn?\"",
      "antwoord": "Dat begrijp ik. Deze challenge biedt echter persoonlijke begeleiding en op maat gemaakte trainingsschema's die specifiek zijn ontworpen om jou te helpen jouw doelen te bereiken. Met de juiste begeleiding en ondersteuning is succes binnen handbereik."
    },
    {
      "vraag": "\"Ik ben niet zeker of ik genoeg tijd heb om me volledig aan de challenge te wijden. Hoe kan ik er zeker van zijn dat ik mijn schema kan volgen?\"",
      "antwoord": "We begrijpen dat het leven druk kan zijn, maar de trainingsschema's zijn flexibel en kunnen worden aangepast aan jouw schema. Onze coaches zullen je helpen om realistische doelen te stellen en je ondersteunen bij het vinden van manieren om de challenge in je drukke leven te passen."
    },
    {
      "vraag": "\"Ik heb geen ervaring met krachttraining. Is deze challenge geschikt voor beginners?\"",
      "antwoord": "Absoluut! Deze challenge is ontworpen voor mensen van alle fitnessniveaus, inclusief beginners. Onze coaches zullen je begeleiden bij elke stap van je fitnessreis en ervoor zorgen dat je de juiste technieken leert en veilig vooruitgang boekt."
    },
    {
      "vraag": "\"Ik ben bang dat ik mijn voortgang niet zal kunnen bijhouden of dat ik niet gemotiveerd genoeg zal zijn om door te zetten. Hoe kan ik hiermee omgaan?\"",
      "antwoord": "Het is normaal om af en toe twijfels te hebben, maar we zijn hier om je te ondersteunen. Onze challenge omvat regelmatige voortgangsbeoordelingen en persoonlijke begeleiding om je gemotiveerd te houden en eventuele obstakels te overwinnen. Samen zullen we jouw succes verzekeren."
    }
  ]
  
  
  
  
  
  
  return (
    <>
      {/* <StickyAtc defaultPrice={defaultPrice} /> */}

      <HeaderReview />


<VoorWie />
<Intro /> 

<USPs /> 

{/* <Verwachten /> */}

      {/* <Header / > */}

      {/* <Schema /> */}

<Facebook />

{/* <Waarom /> */}

{/* <Resultaten /> */}

{/* <DeChallenge /> */}

{/* <OfferItems /> */}

<WatJeKrijgt />

{/* <TextImage
  image={'/img/fullbody-schema.png'}
  imageBlur={false}
  subtitle="Training"
  title="Trainingsschema"
  content={`
    Mijn allerbeste trainingsprogramma met de beste resultaten.
    <br/><br/>
    Terug naar de basis. Oefeningen die veel spieren tegelijk aanpakken.
    <br/><br/>
    Geen nutteloze oefeningen die er alleen leuk uitzien voor op Instagram.
    <br/><br/>
    Je kunt kiezen voor verschillende varianten: voor beginners, gevorderden en experts.
    <br/><br/>
    Ook het aantal trainingsdagen per week is aan te passen. Kun je een keer 2 in plaats van 3 keer trainen? Pak die week het 2-daags schema.
    <br/><br/>
    <b>Gegarandeerde resultaten</b>.
  `}
/>

<TextImage
  bg="#fff"
  image={'/img/offer/offer-gids.jpg'}
  subtitle="Educatie"
  title="Ultieme Spiermassa Gids"
  content={`
    Geen uitgebreide e-book van 150 pagina's die je toch niet gaat lezen...
    <br/><br/>
    Maar een korte gids van een aantal pagina's met de <b>allerbelangrijkste informatie</b> die je nodig hebt over voeding, training, rust en herstel voor optimale spiergroei.
    <br/><br/>
    Ik heb alle informatie hierin geschreven die ik heb geleerd in de afgelopen jaren bij het trainen van honderden mannen.
  `}
/>

<TextImage
  image={'/img/page-header/stef-ridder-img.png'}
  subtitle="Coaching"
  title="Contact met een expert"
  content={`
    Kom je er gedurende de challenge niet uit? Loop je ergens vast of wil je meer informatie?
    <br/><br/>
    Je krijgt een rechtstreekse verbinding met je online personal coach, waarbij één tot twee keer per dag dagelijks al je vragen beantwoordt worden via de mail.
    <br/><br/>
    Geen reden meer om niet te groeien!
  `}
/> */}

{/* <TextImage
  subtitle="Voeding"
  title="Simpele methode om te eten voor spiergroei"
  content={`
    Ik snap dat je helemaal geen zin hebt om alles bij te houden wat je in je mond stopt...
    <br/><br/>
    Geen gedoe met calorieën tellen in deze challenge.
    <br/><br/>
    Je krijgt geleerd hoe je met <b>hele simpele</b> aanpassingen en <b>zonder hoge kosten</b> ervoor zorgt dat je je spieren kunt voorzien van voedsel voor optimale spiergroei.
    <br/><br/>
    Je kunt zelfs met het avondeten mee blijven eten.
  `}
/> */}

{/* <WatNodig /> */}

{/* <JouwCoach /> */}

{/* <NietVoorJou /> */}

<ReviewsList />

{/* <WatMoetIkLaten /> */}

{/* <Twijfels /> */}

      {/* <Traject /> */}

      {/* <Gifts /> */}

      {/* <OfferPakket defaultPrice={defaultPrice} /> */}

      {/* <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Herken jij jezelf in het volgende?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                objectionHandlers.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeObjection == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveObjection(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Bekijk aanbieding
              </a>
            </div>
          </div>
        </div>
      </section>  */}

      {/* <FeaturedReview /> */}

      {/* <JouwCoach /> */}

<OfferCard defaultPrice={defaultPrice} />

<Garantie />

      <section className="faq-section voorwie-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Wat ons eerder is gevraagd 👇</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                faqs.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeFaq == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveFaq(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="#000"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Bekijk aanbieding
              </a>
            </div>
          </div>
        </div>
      </section> 

<Insta />
  </>
  )
}

export default Trainingsplan;