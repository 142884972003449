import styled from 'styled-components'
import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import ReviewsList from './ReviewsList';
import OfferPakket from './OfferPakket';

import './JeBentBegonnen.css';
import './QuizFinished.css';

import './Prijzen.css';
import './Faq.css';
import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import Resultaten from './Resultaten';
import Stappen from './Stappen';
import Header from './Header';
import Footer from './Footer';
import Waarom from './Waarom';
import Schema from './Schema';
import Vergelijking from './Vergelijking'; 
import Wetenschap from './Wetenschap';
import Instagram from './Instagram';
import Intro from './Intro';
import ObjectionHandlers from './ObjectionHandlers';

function WetenschappelijkSchema() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const [openedModal, setOpenedModal] = useState(null);

  const openModal = (id) => {
    console.log('Open modal');

    setOpenedModal(id);
  }

  const closeModal = (id) => {
    console.log('Close modal');

    setOpenedModal(null);
  }

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const [activeFaq, setActiveFaq] = useState(1);

  // const today = new Date()
  // const tomorrow = new Date(today)
  // tomorrow.setDate(tomorrow.getDate() + 1)
  // tomorrow.setHours(0,0,0,0) 

  var today = new Date();
  today.setHours(today.getHours() + 4);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const schrijfInOpPakket = (id) => {
    
 
		const options = {
			method: 'POST',
			headers: {accept: 'application/json', 'content-type': 'application/json'},
			body: JSON.stringify({
				email: globalState.email, 
				firstName: globalState.naam
				// tel: tel.replace('+3106', '+316').replace('+31', '').replace('06', '6')
			})
		};  

		setLoading(true);
		
		fetch(`https://healthyliving-project.com/api/klaviyo/pakket-${id}`, options)
		// fetch(`http://localhost:5000/klaviyo/pakket-${id}`, options)
			.then(response => response.json()) 
			.then(response => {
				console.log(response)
				setLoading(false);
			}) 
			.catch(err => console.error(err));
  }
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null)

	const navigate = useNavigate();

  useEffect(function() {
    if (searchParams.get("paymentResponse")) {
      setPaymentStatus(searchParams.get("paymentResponse"));
    }
  }, [searchParams]);

  const createPayment = () => {
    setLoading(true);

    const values = {
      "message": "Payment",
      "email": globalState.email, 
      "naam": globalState.naam
    }

    axios.post(`https://healthyliving-project.com/api/stripe/`, values, {
    // axios.post(`https://hlp-app-lmvfr.ondigitalocean.app/api/payment/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response); 
      console.log(response.data.session.url);
      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
      setLoading(false);
      
      // navigate(`/thank-you`);
    });
  }

  const faqs = [
    {
      "vraag": "Hoe onderscheiden jullie wetenschappelijk onderbouwde schema's zich van andere programma's?",
      "antwoord": "Onze schema's zijn gebaseerd op de laatste wetenschappelijke inzichten in sportwetenschap en fysiologie. Ze zijn ontworpen om optimale spiergroei en krachtverbetering te bevorderen, rekening houdend met individuele behoeften en doelen, ongeacht je ervaringsniveau."
    },
    {
      "vraag": "Kan ik beginnen met jullie schema's als ik nieuw ben in fitness?",
      "antwoord": "Zeker! Onze schema's zijn inclusief varianten voor beginners die je helpen veilig en effectief te starten, met duidelijke progressiepaden om je ontwikkeling te ondersteunen."
    },
    {
      "vraag": "Wanneer kan ik resultaten verwachten van het volgen van jullie schema?",
      "antwoord": "Resultaten kunnen variëren, maar consistentie in training gecombineerd met de juiste voeding leidt vaak tot zichtbare verbeteringen binnen enkele weken. Onze schema's zijn ontworpen om je maximale resultaten te geven op basis van je inzet en toewijding."
    },
    {
      "vraag": "Is personalisatie mogelijk met jullie schema's?",
      "antwoord": "Absoluut. Wij geloven in een gepersonaliseerde aanpak. Na een initiële beoordeling kunnen schema's worden aangepast om aan jouw specifieke doelen, voorkeuren en beschikbare apparatuur te voldoen."
    },
    {
      "vraag": "Wat is het verschil tussen een algemeen schema en een volledig gepersonaliseerd schema?",
      "antwoord": "Een algemeen schema biedt een solide basis voor de meeste doelgroepen. Een gepersonaliseerd schema daarentegen is volledig op maat gemaakt voor jouw unieke lichaamstype, doelen, ervaringsniveau, en levensstijl, voor maximale effectiviteit."
    },
    {
      "vraag": "Hoe werkt de online coaching?",
      "antwoord": "Onze online coaching biedt directe, persoonlijke begeleiding en aanpassingen aan je schema. Dit omvat regelmatige feedback, techniekanalyse en motivatie om je te helpen je doelen te bereiken."
    },
    {
      "vraag": "Welke communicatiemethoden gebruiken jullie voor online coaching?",
      "antwoord": "We gebruiken een mix van e-mail, instant messaging en videoconferenties, afhankelijk van je voorkeur en de noodzaak voor directe feedback of demonstraties."
    },
    {
      "vraag": "Heb ik speciale apparatuur nodig voor jullie schema's?",
      "antwoord": "Veel van onze schema's kunnen worden aangepast voor minimale apparatuur. We bieden alternatieven voor oefeningen die thuis of met basisgymapparatuur kunnen worden uitgevoerd, om te zorgen dat iedereen kan deelnemen."
    },
    {
      "vraag": "Bieden jullie voedingsadvies om de resultaten te optimaliseren?",
      "antwoord": "Hoewel onze focus ligt op trainingsschema's, begrijpen we het belang van voeding in het bereiken van fitnessdoelen. We bieden basisrichtlijnen en kunnen adviseren over aanpassingen voor optimale resultaten."
    },
    {
      "vraag": "Wat als ik vragen heb of vastloop met mijn schema?",
      "antwoord": "Wij zijn hier om te helpen! Als onderdeel van onze dienstverlening kun je altijd contact met ons opnemen voor ondersteuning, advies of aanpassingen aan je schema."
    }
  ]
  
  
  
 
  return (
    <>
      <Header />

      <Intro />

      {/* <Vergelijking /> */}

      {/* <Waarom /> */}

      <Wetenschap />

      <Schema />

      <Resultaten />

      <OfferPakket />

      <ObjectionHandlers />

      <ReviewsList />

      {/* <Stappen /> */}

      <OfferPakket />

      <Instagram /> 

      {/* <Stap pen /> */}

      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Om je twijfels weg te nemen 😁</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                faqs.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeFaq == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveFaq(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
                Klaar om te groeien? Start hier!
              </a>
            </div>
          </div>
        </div>
      </section> 
  </>
  )
}

export default WetenschappelijkSchema;