import React, { useContext } from 'react';
import AppContext from '../../AppContext';

import './Prijzen.css';

import Countdown, { zeroPad } from 'react-countdown';

function Prijzen() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  // Access the global state
  console.log(globalState);

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-box">
            <span>00</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };

  return (
    <section id="prijzen" className="prijzen" style={{ backgroundImage: `url('/img/services/prijzen-bg.png')` }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              <h6>TIJD VOOR ACTIE</h6>
              <h2 className="mt-2 mb-4">Krijg nu toegang tot de complete gids en begin je reis naar een gezonder en voller lichaam!</h2>
              <p>
              Slechts <span style={{ fontSize: "36px", fontWeight: "700" }}>€14,99</span> voor directe toegang tot mijn gids om gezond aan te komen.
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-5 justify-content-center" style={{ gap: '30px' }}>
          {/* <div className="col-md-4">
            <div className="prijs-card">
              <div className="sticker">
                <span>20%</span>
                <span>korting</span>
              </div>
              <h6 style={{ color: 'red' }}>Bulk-Up Project</h6>
              <h4>12-weken<br/>traject</h4>
              <div className="pricing">
                <span>€159</span>
                <span className="old-price">€199</span>
              </div>
              <p>
                The perfect way to get started and get used to our tools.
              </p>

              <ul>
                <li>
                  1 personal training sessions per week
                </li>
                <li>
                  Nutritional advice and support
                </li>
                <li>
                  Monthly fitness evaluation
                </li>
              </ul>

              <a href="#im-ready" className="button">
                Ik wil beginnen
              </a>
            </div>
          </div> */}
          <div className="col-md-4 mx-auto">
            <div className="prijs-card best-deal">
              {/* <div className="label pulse-gray">Most chosen</div> */}
              <div className="sticker">
                <span>40%</span>
                <span>korting</span>
              </div>
              {/* <div className="sticker extra">
                <span>én krijg je</span>
                <span>eerste week</span>
                <span>gratis</span>
              </div> */}
              {/* <h6 style={{ color: '#000' }}>Thrive-X</h6> */}
              <h4>Koop mijn gids</h4>
              <div className="pricing">
                <span>€14,99</span>
                <span className="old-price">€24,99</span>
              </div>
              <p>
                Wacht niet langer! Transformeer je lichaam, win zelfvertrouwen en bereik het gewicht waar je altijd van hebt gedroomd.
              </p>

              <ul>
                <li>
                Gezonde gewichtstoename zonder fastfood of shakes.
                </li>
                <li>
                Lijst met voedingsmiddelen die hoog zijn in calorieën.
                </li>
                <li>
                Kant-en-klare boodschappenlijsten voor directe start.
                </li>
                <li>
                Persoonlijke aanpassingen op basis van individuele behoeften.
                </li>
                <li>
                Duurzame benadering voor langdurige resultaten.
                </li>
                <li>
                Praktische tips voor het maximaliseren van calorie-inname.
                </li>
                <li>
                Geen compromis op gebied van smaak en genieten van voeding.
                </li>
              </ul>
              
              {/* <small style={{ color: "#fff", textAlign: "center", marginBottom: "12.5px" }}>Investeer in jezelf en zie hoe je fysieke en mentale welzijn positief transformeren!</small> */}

              <button onClick={() => handleClick()} className="button">
                Koop de gids
              </button>
            </div>
 
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <small style={{ color: '#fff' }}>"We zijn zo overtuigd van de effectiviteit van onze gids dat we een 100% tevredenheidsgarantie bieden. Als je niet tevreden bent, krijg je je geld terug."</small>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="prijs-timer">
              <div className="timer">
                <Countdown 
                  date={tomorrow} 
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Prijzen;
