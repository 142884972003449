import './Recensies.css';

// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Recensies() {

  return (
    <section className="recensies-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-heading centered dark-heading">
            <h6>Recensies</h6>
            <h2 className="mt-2 mb-4">Ontdek wat anderen zeggen over onze voedingsadviezen</h2>
            <p>
              Lees de succesverhalen van onze klanten en laat je inspireren door hun reis naar een gezonder leven
            </p>
          </div>
        </div>
      </div>

      <Swiper
        className="mt-5 pt-5"
        modules={[Navigation]}
        style={{ overflow: 'visible' }}
        spaceBetween={50}
        slidesPerView={3.15}
        navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 320,
            slidesPerView: 1
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2
          },
          // when window width is >= 768px
          991: {
            width: 991,
            slidesPerView: 3
          },
          // when window width is >= 768px
          1025: {
            width: 1025,
            slidesPerView: 3
          }
        }}
      >
        <SwiperSlide>
          <div className="recensie-card">
            <img className="avatar" src="https://via.placeholder.com/300x300" alt="" />
            <h4>Sarah</h4>
            <p>
              45 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Dankzij de deskundigheid van Stef heb ik eindelijk een op maat gemaakt voedingsplan dat perfect past bij mijn levensstijl. Na slechts een paar maanden ben ik al zoveel afgevallen en voel ik me zoveel beter. Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>

            <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul>

            <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`recensie-card ${({ isActive }) => ('best-deal')}`}>
            <img className="avatar" src="https://via.placeholder.com/300x300" alt="" />
            <h4>Sarah</h4>
            <p>
              45 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Dankzij de deskundigheid van Stef heb ik eindelijk een op maat gemaakt voedingsplan dat perfect past bij mijn levensstijl. Na slechts een paar maanden ben ik al zoveel afgevallen en voel ik me zoveel beter. Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>

            <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul>

            <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            <img className="avatar" src="https://via.placeholder.com/300x300" alt="" />
            <h4>Sarah</h4>
            <p>
              45 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Dankzij de deskundigheid van Stef heb ik eindelijk een op maat gemaakt voedingsplan dat perfect past bij mijn levensstijl. Na slechts een paar maanden ben ik al zoveel afgevallen en voel ik me zoveel beter. Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>

            <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul>

            <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            <img className="avatar" src="https://via.placeholder.com/300x300" alt="" />
            <h4>Sarah</h4>
            <p>
              45 jaar oud
            </p>
            <div className="pricing">
              <span>Meer dan tevreden!</span>
            </div>
            <p className="quote">
              “Dankzij de deskundigheid van Stef heb ik eindelijk een op maat gemaakt voedingsplan dat perfect past bij mijn levensstijl. Na slechts een paar maanden ben ik al zoveel afgevallen en voel ik me zoveel beter. Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>

            <ul>
              <li>
                6-weken traject
              </li>
              <li>
                -10 kilo
              </li>
            </ul>

            <a href="#im-ready" className="button button-black">
              Ik wil dit ook
            </a>
          </div>
        </SwiperSlide>
      </Swiper>

    </div>
    </section>
  )
}

export default Recensies;
