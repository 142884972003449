import styled from 'styled-components'

import Countdown, { zeroPad } from 'react-countdown';

import './PageHeader.css'; 
 
function PageHeader() {

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-box">
            <span>00</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };
 
  return (
    <div className="header-outer">
      <div className="timer">
        <Countdown 
          date={tomorrow} 
          renderer={renderer}
        />
      </div>
      <div className="page__header" style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')` }}>
        <div className="container">
          <div className="row">
            <div className="header__img">
              <img className="w-100" src="/img/page-header/stef-ridder-img.png" alt="" />
            </div>

            <div className="header-content">
              <h2>Gemakkelijk, snel en gezond aankomen</h2>
              <h1>ZONDER fastfood/ZONDER shakes</h1>
              {/* <h2>Transformeer je lichaam in slechts enkele weken!</h2> */}

              <button className="button button-gray">
              Bekijk mijn aanbieding
                <span>nu 40% korting</span>
              </button>
              
              <small>Niet Tevreden = Geld Terug</small>
            </div>
          </div>
          
          <div className="quote">
            <p>
              “Ik was op zoek naar een gezonde manier om aan te komen zonder mijn voedingsgewoonten volledig overhoop te gooien. Deze gids bood precies dat!”
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHeader;