import { useState } from 'react';
import './Diensten.css';

function Diensten() {

  const [activeFaq, setActiveFaq] = useState(1);

  return (
    <section id="diensten">
    <div className="section usp-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-5 mb-md-0">
            <h5>Persoonlijk plan</h5>
            <p>
              Geen kant en klare schema's die je ontvangt. Alles is 100% maatwerk, gebaseerd op jouw doelen, leefstijl, budget en voorkeuren.
            </p>
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <h5>Geen restricties</h5>
            <p>
            Je bent nog steeds vrij om lekkere dingen te eten! Ik zorg voor de juiste balans waardoor je in shape komt, zonder grote opoffering.
            </p>
          </div>
          <div className="col-md-4">
            <h5>Professionele coaching</h5>
            <p>
            Ik als digitale gewichtsbegeleider zit 10+ jaar in het vak en heb al tal van mensen geholpen.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="section text-image-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 mr-auto">
            <h5>Persoonlijke trainingsschema's</h5>
            <p>
            Schema's op maat gemaakt door jouw digitale gewichtsbegeleider, aangepast op jouw doelen,
            situatie, levensstijl en ervaring.
            <br/><br/>
            - 100% maatwerk<br/>
            - Trainingsschema altijd bij de hand<br/>
            - Uitleg over de oefeningen door middel van afbeeldingen<br/>
            - Voor elke oefening een alternatieve oefening
            </p>
            <div className="d-flex mt-3">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
          <div className="col-md-6 mt-5 md-md-0">
            <img className="w-100" src="/img/stef-schrijven.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div className="section text-image-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-2 order-md-1 mt-5 mt-md-0">
            <img className="w-100" src="/img/stef-schrijven.jpg" alt="" />
          </div>
          <div className="col-md-5 ml-auto order-1 order-md-2">
            <h5>Persoonlijke voedingsschema's</h5>
            <p>
              Je digitale gewichtsbegeleider maakt een persoonlijk voedingsplan en hierbij zal rekening gehouden worden met jouw levensstijl, intoleranties of een allergie. 
              <br/><br/>
              - 100% maatwerk<br/>
              - Maaltijd plannen voor meerdere dagen<br/>
              - Begeleiding bij voeding zodat je het zelf leert
            </p>
            <div className="d-flex mt-3">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default Diensten;
