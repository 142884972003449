import { Modal, Button, Group, TextInput, NumberInput, Checkbox, Box, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import styled from 'styled-components'

import axios from 'axios';


import PageHeader from '../components/Aankomen/PageHeader'
import BeforeAfter from '../components/Aankomen/BeforeAfter'
import Voordelen from '../components/Aankomen/Voordelen'
import Recensies from '../components/Aankomen/Recensies'
import Diensten from '../components/Aankomen/Diensten'
import Prijzen from '../components/Aankomen/Prijzen'
import Video from '../components/Aankomen/Video'
import BigVideo from '../components/Aankomen/BigVideo'
import Faq from '../components/Aankomen/Faq'

function Aankomen() {

  return (
    <>
      {/* Header */}
      <PageHeader />
      
      {/* BeforeAfter */}
      <BeforeAfter />

      {/* Voordelen */}
      <Voordelen />

      {/* Voordelen */}
      {/* <BigVideo /> */}

      {/* Diensten */}
      <Diensten />

      {/* Recensies */}
      <Recensies />

      {/* Prijzen */}
      <Prijzen />
 
      {/* Faq */}
      <Faq />

      {/* Prijzen */}
      <Video />
    </>
  )
}

export default Aankomen;