
import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import './Page.css';

import axios from 'axios';

// Import Swiper React components
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function ReviewsList() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const recensies = [
    {
      "titel": "Inzicht en Resultaten",
      "recensie": "Dit programma heeft me echt geholpen de barrières te doorbreken die me al jaren tegenhielden. Ik voelde me begrepen en zag eindelijk de resultaten waar ik naar zocht.",
      "naam": "Michael",
      "hoe_lang_geleden": "5 dagen geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Volledige begeleiding",
      "recensie": "Ik heb nog nooit zoveel geleerd over voeding en training in zo'n korte tijd. De begeleiding was top en ik voelde me volledig ondersteund.",
      "naam": "Michel",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Verbazingwekkende resultaten",
      "recensie": "De resultaten die ik in slechts 60 dagen heb behaald zijn ongelooflijk. Dit programma heeft echt mijn verwachtingen overtroffen.",
      "naam": "Mike",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Levensveranderend",
      "recensie": "Dit programma heeft mijn kijk op fitness en gezondheid volledig veranderd. Ik voel me sterker en energieker dan ooit tevoren.",
      "naam": "Sander a",
      "hoe_lang_geleden": "2 dagen geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Onverwachte vooruitgang",
      "recensie": "Ik had nooit gedacht dat ik in zo'n korte tijd zoveel vooruitgang zou zien. Dit programma is echt een aanrader voor iedereen die serieus wil trainen.",
      "naam": "David R.",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Geweldige structuur",
      "recensie": "De structuur van dit programma is geweldig. Ik voelde me vanaf dag één volledig ondersteund en de resultaten spreken voor zich.",
      "naam": "Govert R.",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Ongelooflijke kennis",
      "recensie": "De spiermassa gids bevat informatie die ik nergens anders heb kunnen vinden. Het heeft mijn training en voeding naar een hoger niveau getild.",
      "naam": "Alex",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Positief verrast",
      "recensie": "Ik was in het begin sceptisch, maar de resultaten hebben me echt verrast. Mijn lichaam heeft zich zichtbaar verbeterd en mijn vrienden merken het ook.",
      "naam": "Lorenzo K.",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Eenvoudig te volgen",
      "recensie": "De eenvoud en effectiviteit van dit programma zijn geweldig. Ik kon mijn doelen bereiken zonder constante begeleiding nodig te hebben.",
      "naam": "Mick",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Blijvend resultaat",
      "recensie": "De begeleiding tijdens de challenge heeft me veel geleerd over voeding en training. Dit zal me nog lang helpen bij mijn fitnessdoelen.",
      "naam": "Tim",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "3 kilo spiermassa",
      "recensie": "Dankzij dit programma ben ik 3 kilo aangekomen in spiermassa. Het verschil is duidelijk zichtbaar en ik ben zeer tevreden.",
      "naam": "Yvo A",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Uitstekende resultaten",
      "recensie": "Ik ben ontzettend blij dat ik deze challenge heb gevolgd. De resultaten waren boven verwachting en ik heb veel bijgeleerd.",
      "naam": "Erik",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Absolute aanrader",
      "recensie": "Dit programma is een absolute aanrader. De resultaten die ik heb behaald zijn fantastisch en ik heb veel geleerd.",
      "naam": "Laurens",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Constante vooruitgang",
      "recensie": "Dankzij de duidelijke instructies en begeleiding heb ik constante vooruitgang geboekt. Dit programma is zijn geld meer dan waard.",
      "naam": "Tim",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Essentiële kennis",
      "recensie": "De kennis die ik heb opgedaan over training en voeding is essentieel gebleken. Dit programma vult alle gaten die ik had in mijn kennis.",
      "naam": "Chris",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Maximale resultaten",
      "recensie": "De 60 Dagen Spiermassa Challenge heeft me geleerd hoe ik mijn lichaam op de juiste manier kan trainen en voeden voor maximale resultaten.",
      "naam": "Frank",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Levensveranderende ervaring",
      "recensie": "Dit programma heeft mijn leven veranderd. Ik heb meer zelfvertrouwen, meer energie en een sterker lichaam dan ooit tevoren.",
      "naam": "Peter",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Beste keuze ooit",
      "recensie": "Ik heb veel programma's geprobeerd, maar dit is veruit het beste. Het is specifiek gericht op mijn behoeften en ik heb geweldige resultaten behaald.",
      "naam": "Rik",
      "hoe_lang_geleden": "3 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Niet voor de zwakken",
      "recensie": "Dit programma vereist toewijding en hard werken. Het is niet voor de zwakken, maar de resultaten zijn het absoluut waard.",
      "naam": "Christiaan V.",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Boven verwachting",
      "recensie": "Ik was aanvankelijk sceptisch, maar het programma heeft mijn verwachtingen overtroffen. Ik voel me sterker en zelfverzekerder dan ooit.",
      "naam": "René van der Laan",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Uitstekende ondersteuning",
      "recensie": "De ondersteuning tijdens het programma was geweldig. Ik voelde me nooit alleen en kreeg altijd snel antwoord op mijn vragen.",
      "naam": "David",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Dankbaar",
      "recensie": "Stef heeft me enorm geholpen tijdens de challenge. Ik ben heel dankbaar voor zijn begeleiding en de geweldige resultaten die ik heb behaald.",
      "naam": "Ed",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Aanbevolen aan vrienden",
      "recensie": "Ik heb deze challenge aan veel vrienden aanbevolen omdat ik zelf zo'n goede resultaten heb behaald. Een absolute aanrader.",
      "naam": "Thomas",
      "hoe_lang_geleden": "6 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Waardevolle investering",
      "recensie": "Dit programma is elke cent waard. Het heeft me geholpen om mijn spiermassa te vergroten en waardevolle kennis op te doen in korte tijd.",
      "naam": "Ricardo",
      "hoe_lang_geleden": "7 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Zelfstandig verder",
      "recensie": "Ik heb veel geleerd over supplementen en spieropbouw. Nu kan ik zelfstandig verder trainen zonder hulp.",
      "naam": "Freek",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Dagelijkse motivatie",
      "recensie": "Het bijhouden van mijn voortgang in het logboek heeft me geholpen consistent te blijven en geweldige spiermassa op te bouwen.",
      "naam": "Erik K",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Super resultaat",
      "recensie": "De 60 dagen hard gewerkt en daardoor super resultaten behaald! Ik voel me geweldig.",
      "naam": "P. B.",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Beste tot nu toe",
      "recensie": "Van alle programma's die ik heb gevolgd is dit het beste. De resultaten zijn fantastisch en ik heb veel geleerd.",
      "naam": "Martin",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Spiermassa toegenomen",
      "recensie": "Ik ben bijna 2 kilo aan spiermassa aangekomen en heb wat vet verloren. Zeer tevreden met het resultaat.",
      "naam": "Bart",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Effectief schema",
      "recensie": "De schema's zijn goed te volgen, zelfs met mijn drukke schema. Stef heeft het perfect opgelost voor mijn situatie.",
      "naam": "Bart",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Perfecte oplossing",
      "recensie": "Zelfs met mijn drukke schema was het programma goed te volgen dankzij de effectieve schema's van Stef. Perfect voor mijn situatie.",
      "naam": "Bart",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Makkelijk te volgen",
      "recensie": "Dankzij Stef's goed gestructureerde schema's kon ik mijn fitnessdoelen bereiken, zelfs met beperkte tijd. Heel tevreden.",
      "naam": "Bart",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    }
  ]

  
  
  
  
  
  
 
  return (
    <>

    <div className="reviews-section">
      <div className="page-width">
        <div className="container">
          <div className="col-md-12">

            <div className="reviews-heading">
              <h2>36</h2>
              <h4 style={{ fontSize: '32px' }}>Klanten recensies</h4>
              <div className="stars-rating">
                <img style={{ maxWidth: '130px', marginRight: '12px', marginTop: '6px' }} src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-46.png?v=1707467859" alt=""/>
                <span>
                  4.9 gemiddeld met 36 recensies
                </span> 
              </div>
            </div>
          
          </div>
        </div>

            <div className="reviews-slider mt-4">
              <div className="swiper review-swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="review-title">
                      <span style={{ color: "#333" }}>Mijn favoriete reviews</span>
                    </div>







                    <div className="continuous-slider-container">

                    <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {
                        recensies.map((recensie, index) => {
                          if (index < 11) {
                          return (
                            <SwiperSlide key={index}>
                              <div className="review-item" key={index}>
                                <div className="stars-row">
                                  {
                                    recensie.aantal_sterren == 5 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                    : recensie.aantal_sterren == 4 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                    : null
                                  }
                                  <span>{ recensie.hoe_lang_geleden }</span>
                                </div>
                                <h5>{ recensie.titel }</h5>
                                <p>
                                  { recensie.recensie }
                                </p>
                                <div className="review-name">
                                  <span>{ recensie.naam }</span>
                                  <div className="verified-badge">
                                    <img src="/img/icons/verified-icon.svg" alt=""/>
                                    <span>Geverifieerde koper</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                          } else {
                          return false;
                          }
                        })
                      }
                    </Swiper>
                         
                    <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                        reverseDirection: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    > 
                      {
                        recensies.map((recensie, index) => {
                          if (index > 10 && index < 25) {
                          return (
                            <SwiperSlide key={index}>
                              <div className="review-item" key={index}>
                                <div className="stars-row">
                                  {
                                    recensie.aantal_sterren == 5 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                    : recensie.aantal_sterren == 4 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                    : null
                                  }
                                  <span>{ recensie.hoe_lang_geleden }</span>
                                </div>
                                <h5>{ recensie.titel }</h5>
                                <p>
                                  { recensie.recensie }
                                </p>
                                <div className="review-name">
                                  <span>{ recensie.naam }</span>
                                  <div className="verified-badge">
                                    <img src="/img/icons/verified-icon.svg" alt=""/>
                                    <span>Geverifieerde koper</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                          } else {
                          return false;
                          }
                        })
                      }
                    </Swiper>

                    <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {
                        recensies.length > 3 ?
                            recensies.map((recensie, index) => {
                              if (index > 24 && index < 35) {
                              return (
                                <SwiperSlide key={index}>
                                  <div className="review-item" key={index}>
                                    <div className="stars-row">
                                      {
                                        recensie.aantal_sterren == 5 ?
                                          <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                        : recensie.aantal_sterren == 4 ?
                                          <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                        : null
                                      }
                                      <span>{ recensie.hoe_lang_geleden }</span>
                                    </div>
                                    <h5>{ recensie.titel }</h5>
                                    <p>
                                      { recensie.recensie }
                                    </p>
                                    <div className="review-name">
                                      <span>{ recensie.naam }</span>
                                      <div className="verified-badge">
                                        <img src="/img/icons/verified-icon.svg" alt=""/>
                                        <span>Geverifieerde koper</span>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              )
                            } else {
                              return false;
                            }
                            })
                      : null
                      }
                    </Swiper>




                    </div>


                  </div>
                </div>
              </div>
            </div>

      </div>
    </div>
  </>
  )
}

export default ReviewsList;