import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import './OfferPakket.css';
import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import PaymentIcons from './PaymentIcons';
import { Checkbox, Modal, Button, Select, createStyles, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  modal: {
    '.mantine-Paper-root': {
      background: 'transparent !important',
      boxShadow: 'none !important'
    },
    '.mantine-Modal-header': {
      background: 'transparent'
    },
    '.mantine-Modal-inner': {
      padding: '0'
    }
  }
}));

function Offer({ defaultPrice }) {
  
  const { classes } = useStyles();

  const { globalState, updateGlobalState } = useContext(AppContext);

  const [title, setTitle] = useState('Jouw Persoonlijke Trainingsschema');

  const [loading, setLoading] = useState(false);
  const createPayment = () => {
    setLoading(true);

    const values = {
      "message": "Payment",
      "title": title,
      "email": globalState.email, 
      "naam": globalState.naam,
      "amount": price,
      "vraag1": vraag1,
      "vraag2": vraag2,
      "vraag3": vraag3,
      "vraag4": vraag4,
      "vraag5": vraag5,
      "vraag6": vraag6,
      "vraag7": vraag7
    }

    // axios.post(`https://localhost:5000/api/stripe/`, values, {
    axios.post(`https://hlp-app-lmvfr.ondigitalocean.app/api/stripe/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response); 
      console.log(response.data.session.url);
      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
      setLoading(false);
      
      // navigate(`/thank-you`);
    });
  }

  const [price, setPrice] = useState(defaultPrice ? defaultPrice : 14.99);

  const setAmount = (amount, checked) => {

    let newAmount;
    console.log(amount);
    console.log(price);

    if (checked) {
      newAmount = Number(Number(price) + Number(amount)).toFixed(2);
    } else {
      newAmount = Number(Number(price) - Number(amount)).toFixed(2);
    }

    setPrice(newAmount);
  }

  const quiz = [
    {
      title: "Je huidige niveau",
      type: "select",
      options: [
        {
          value: "beginner",
          content: "Beginner"
        },
        {
          value: "gevorderde",
          content: "Gevorderde"
        },
        {
          value: "ervaren",
          content: "Ervaren"
        }
      ]
    },
    {
      title: "Hoeveel dagen kun je trainen per week?",
      type: "number"
    },
    {
      title: "Heb je momenteel beperkingen of blessures?",
      type: "textarea"
    }
  ]

  const [activeTab, setActiveTab] = useState(1);
  const [answers, setAnswers] = useState([]);

  const setOption = (subject, question, option) => {
    console.log(subject)
    console.log(question)
    console.log(option)

    setActiveTab(activeTab + 1);

    setAnswers([...answers, {
      subject, question, option
    }])
  }

  const [dagen, setDagen] = useState(null);
  const handleDagen = (event) => {
    setDagen(event.target.value)
  };


  const [vraag1, setVraag1] = useState(null);
  const [vraag2, setVraag2] = useState(null);
  const [vraag3, setVraag3] = useState(null);
  const [vraag4, setVraag4] = useState(null);
  const [vraag5, setVraag5] = useState(null);
  const [vraag6, setVraag6] = useState(null);
  const [vraag7, setVraag7] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);
 
  return (
    <>
      <div className="section pakket-section" id="offer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading centered dark-heading">
                <h6>Trainen voor jouw lichaam en levensstijl</h6>
                <h1 className="mt-2 mb-4">Vraag nu jouw op maat gemaakte schema aan!</h1>
              
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="step-lines">
                <div className="step-line">
                  <span className="line-number">1</span>
                  <p>
                    Vul hieronder onze quiz in
                  </p>
                </div>
                <div className="step-line">
                  <span className="line-number">2</span>
                  <p>
                    Verstuur de resultaten naar ons
                  </p>
                </div>
                <div className="step-line">
                  <span className="line-number">3</span>
                  <p>
                    Wij ontvangen je resultaten en gaan aan de slag
                  </p>
                </div>
                <div className="step-line">
                  <span className="line-number">4</span>
                  <p>
                    Binnen 24 uur ontvang je een geheel op maat gemaakt schema
                  </p>
                </div>
                {/* <div className="step-line">
                  <span className="line-number">4</span>
                  <p>
                    Toegang tot j
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <Modal centered opened={opened} onClose={close} p="0" size="auto" className={classes.modal}> 
            <div className="offer-quiz">
              {
                activeTab > 7 ?
                <div className="col-md-8 mx-auto">
                  <div className="prijs-card">
                    <h4>Jouw Persoonlijke Trainingsschema</h4>
                    <div className="pricing">
                      <span>€{ defaultPrice ? defaultPrice : 14.99 }</span>
                      <span className="old-price">€{ defaultPrice ? 24.99 : 39.99 }</span>
                    </div>
    
                    <div className="klarna-price">
                      <span>Of 3 renteloze betaling van €{ defaultPrice ? Number(defaultPrice / 3).toFixed(2) : 4.99 } met <img style={{ width: '58px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Klarna_Payment_Badge.svg/640px-Klarna_Payment_Badge.svg.png" alt="" /></span>
                    </div>
                    {/* <p className="mt-3">
                      <i>Nu met vroegboekkorting!</i> 
                    </p> */}
    
                    <ul>
                      <li>
                        <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                        </svg>
                        Een trainingsschema specifiek ontworpen voor jouw wensen en behoeften, perfect voor mensen met een hectisch leven en voor de beste resultaten in de gym.
                      </li>
                      <li>
                        <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                        </svg>
                        Met alternatieve oefeningen zodat je altijd iets kunt doen - ook in een drukke gym!
                      </li>
                      <li>
                        <svg width="718" height="718" viewBox="0 0 718 718" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M242.333 375.667L309 442.334L475.666 275.667" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M192.334 70.261C241.363 41.8993 298.287 25.667 359 25.667C543.094 25.667 692.334 174.905 692.334 359C692.334 543.094 543.094 692.334 359 692.334C174.905 692.334 25.667 543.094 25.667 359C25.667 298.287 41.8993 241.363 70.261 192.334" stroke="#CD0C19" strokeWidth="50" strokeLinecap="round"/>
                        </svg>
                        Ontvang je schema en de gifts binnen 24 uur!
                      </li>
                      {/* <li>
                        <span style={{ marginRight: '7px'}}>🎁</span>
                        <span>
                          <i>Tijdelijk gratis cadeau: </i><br/>Logboek in mobiele app om je workouts bij te houden
                        </span>
                      </li> */}
                      <li>
                        <span style={{ marginRight: '7px'}}>🎁</span>
                        <span>
                          <i>Tijdelijk gratis cadeau: </i><br/>Gids: De 20 Meest Gemaakte Fouten bij Aankomen en Spieren Opbouwen
                        </span> 
                      </li>
                      <li>
                        <span style={{ marginRight: '7px'}}>🎁</span>
                        <span>
                          <i>Tijdelijk gratis cadeau: </i><br/>Receptenboek met eiwitrijke en spieropbouwende recepten 
                        </span> 
                      </li>
                    </ul>
    
                    {/* <h4 className="mb-3 mt-4">Extra's</h4>
                    <div className="extra-option mt-2">
                      <Checkbox
                        label="4 weken online 1-op-1 coaching via WhatsApp - Past in een druk (sociaal) leven (+ €29.99)"
                        onChange={(event) => setAmount(29.99, event.currentTarget.checked)}
                      />
                    </div> */}
                    
                    
                    <div className="button-container text-center mt-4" style={{ flexDirection: 'column' }}>
                      <button className="button button-red" type="button" onClick={() => createPayment()}>
                        Krijg Direct Toegang! - €{ price }
                      </button>
    
                      <div className="pay-info mt-2">
                        <span className="betalen-desc d-flex mb-2 justify-content-center">
                          Veilig betalen met:
                        </span>
                        <PaymentIcons />
                      </div>
    
                      <small style={{ fontSize: '14px', color: "#000" }}>Ben je niet tevreden? Je ontvangt je geld terug zonder vragen!</small>
                    </div>
                  </div>
                </div>
                :
                <>
                  <div className={`quiz-subject ${activeTab == 1 ? 'active' : null}`}>
                    <h4>Wat is je huidige niveau?</h4>
                    <p>
                      Kies een van onderstaande antwoorden
                    </p>
                    <div className="quiz-answers">
                      <div className="quiz-answer" onClick={() => {
                        setVraag1("Beginner");
                        setActiveTab(2);
                      }}>
                        Beginner
                      </div>
                      <div className="quiz-answer" onClick={() => {
                        setVraag1("Gevorderde");
                        setActiveTab(2);
                      }}>
                        Gevorderde
                      </div>
                      <div className="quiz-answer" onClick={() => {
                        setVraag1("Ervaren");
                        setActiveTab(2);
                      }}>
                        Ervaren
                      </div>
                    </div>
                  </div>

                  <div className={`quiz-subject ${activeTab == 2 ? 'active' : null}`}>
                    <h4>Hoeveel dagen per week kun je trainen?</h4>
                    <p>
                      Met zo weinig als 1 dag per week kun je al goede resultaten behalen!
                    </p>
                    <div className="quiz-answers">
                      <input type="number" onChange={(event) => setVraag2(event.target.value)} value={vraag2} placeholder="3" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(1)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(3)}>
                      Volgende
                    </button>
                  </div>

                  <div className={`quiz-subject ${activeTab == 3 ? 'active' : null}`}>
                    <h4>Hoeveel tijd kun je besteden per sessie?</h4>
                    <p>
                      Vul het aantal uren of minuten in. 45 minuten per sessie is al genoeg!
                    </p>
                    <div className="quiz-answers">
                      <input type="text" onChange={(event) => setVraag3(event.target.value)} value={vraag3} placeholder="Vul in" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(2)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(4)}>
                      Volgende
                    </button>
                  </div>

                  <div className={`quiz-subject ${activeTab == 4 ? 'active' : null}`}>
                    <h4>Heb je een voorkeur voor spiergroepen die je het meest wilt groeien?</h4>
                    <p>
                      Schrijf je voorkeuren op (voorbeeld: borst, rug)
                    </p>
                    <div className="quiz-answers">
                      <textarea rows="4" onChange={(event) => setVraag4(event.target.value)} value={vraag4} placeholder="Vul in" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(3)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(5)}>
                      Volgende
                    </button>
                  </div>

                  <div className={`quiz-subject ${activeTab == 5 ? 'active' : null}`}>
                    <h4>Heb je een voorkeur voor oefeningen die je het liefst doet?</h4>
                    <p>
                      Schrijf je voorkeuren op (voorbeeld: squat, bankdrukken)
                    </p>
                    <div className="quiz-answers">
                      <textarea rows="4" onChange={(event) => setVraag5(event.target.value)} value={vraag5} placeholder="Vul in" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(4)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(6)}>
                      Volgende
                    </button>
                  </div>

                  <div className={`quiz-subject ${activeTab == 6 ? 'active' : null}`}>
                    <h4>Welke apparatuur heb je tot je beschikking?</h4>
                    <p>
                      Ga je naar de sportschool? Vul dan in: "sportschool"
                    </p>
                    <div className="quiz-answers">
                      <textarea onChange={(event) => setVraag6(event.target.value)} value={vraag6} placeholder="Vul in" rows="4" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(5)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(7)}>
                      Volgende
                    </button>
                  </div>

                  <div className={`quiz-subject ${activeTab == 7 ? 'active' : null}`}>
                    <h4>Heb je momenteel beperkingen of blessures?</h4>
                    <p>
                      Wij kunnen altijd om beperkingen of blessures heen trainen :)
                    </p>
                    <div className="quiz-answers">
                      <textarea onChange={(event) => setVraag7(event.target.value)} value={vraag7} placeholder="Vul in" rows="4" />
                    </div>
                    <button className="button button-white" type="button" onClick={() => setActiveTab(6)}>
                      Vorige
                    </button>
                    <button className="button button-red" type="button" onClick={() => setActiveTab(8)}>
                      Voltooien
                    </button>
                  </div>
                </>
              }
            </div>
          </Modal>

          <div className="row mt-5">
            <div className="col-md-12">

              <div className="section-heading centered dark-heading mb-4">
                <h6>Laat ons jouw persoonlijke schema bouwen</h6>
              </div>

              <Flex
                justify="center"
              >
                <button className="button button-red" type="button" onClick={open}>
                  Schema aanvragen
                </button>
              </Flex>

            </div>
          </div>

          <div className="row mt-md-5 justify-content-center">
        
            {/* <div className="col-md-5">
              <img className="w-100 offer-img" src="/img/3-daagse-gains.jpg" alt="beginners schema" style={{ boxShadow: '0 0 24px rgba(0,0,0,0.35)' }} />
            </div>  */}

          </div>
        </div>
      </div>
    </>
  )
}

export default Offer;