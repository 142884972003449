import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';

const TeaserProducts = ({ 
  teaserShow = true, 
  teaserTitle = 'Mijn diensten', 
  teaserShowMore = false, 
  teaserShowMoreUrl = '#', 
  teaserShowMoreText = 'Show More', 
  teaserImage, 
  teaserText = 'Special Offer', 
  teaserDescription = 'This is a special description.', 
  teaserLinkText = 'Learn More', 
  teaserLinkUrl = '#', 
  collectionProducts = [
    {
      title: "Trainingsplan",
      description: "Op maat plan voor 8 weken",
      id: "1",
      price: "€99",
      url: "/trainingsplan",
      media: [
        '/img/website/stef-deadlift.jpg'
      ]
    },
    {
      title: "Voedingsplan",
      description: "Op maat plan voor 8 weken",
      id: "2",
      price: "€89",
      url: "/voedingsplan",
      media: [
        '/img/stef-schrijven.jpg'
      ]
    },
    {
      title: "Coachingstrajecten",
      description: "Op maat en met persoonlijke 1-op-1 begeleiding",
      id: "3",
      price: "vanaf €199",
      url: "modal",
      media: [
        '/img/website/hlp-stef-actie.jpg'
      ]
    }
  ], 
}) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        slidesPerView: teaserShow ? 1.3 : 1.3,
        spaceBetween: 24,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        breakpoints: teaserShow
          ? {
              640: { slidesPerView: 2.3 },
              768: { slidesPerView: 3 },
            }
          : {
              640: { slidesPerView: 2.3 },
              768: { slidesPerView: 2.3 },
              1024: { slidesPerView: 3.3 },
              1280: { slidesPerView: 4 },
            },
      });
    }
  }, [teaserShow]);

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
    
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
            {/* Close Button */}
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            {/* Modal Content */}
            <h2 className="text-2xl text-black font-bold mb-4">Stel je vraag</h2>
            <p className="mb-4 text-gray-700">
              Heb je vragen over onze diensten of wil je meer informatie? Vul het formulier in en we
              nemen zo snel mogelijk contact met je op.
            </p>
            {/* Contact Form */}
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                  Naam
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw naam"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw e-mail"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  id="message"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw bericht"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-primary-red text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Verstuur
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="container mx-auto md:px-4 py-[90px]" id={teaserShow ? "related_products" : ""}>
        {teaserShow ? (
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="w-full lg:w-[76%]">
              <div className="flex justify-between items-center mb-4">
                {teaserTitle && (
                  <h2 className="text-[32px] md:text-[40px] font-bold leading-normal text-black">
                    {teaserTitle}
                  </h2>
                )}
              </div>
              <div className="flex flex-row lg:gap-6 swiper pb-4" ref={swiperRef}>
                <div className="swiper-wrapper">
                  {collectionProducts.slice(0, teaserShow ? 3 : 4).map((product, index) => (
                    <div className="swiper-slide" key={index}>
                      <div className="relative product-card bg-primary-darkGray h-[430px] overflow-hidden group">
                        { product.url.includes("modal") ? 
                        <button 
                          type="button"
                          onClick={toggleModal}
                        >
                          <div className="overlay z-5 absolute top-0 left-0 w-full h-full bg-[rgb(51,51,51,.825)] flex" style={{ zIndex: '5' }}></div>
                          <img
                            className="absolute left-0 top-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
                            src={product.media[0]}
                            alt={product.title}
                          />
                          <div className="absolute bottom-6 left-6 z-10 text-white text-left">
                            <h3>{product.title}</h3>
                            <p className="text-4xl font-bold text-white">{product.price}</p>
                          </div>
                          <div className="absolute right-[-1px] bottom-[-1px] p-4 bg-primary-red w-11 h-11 items-center justify-center flex">
                            <img
                              src="//de-rijplaten-specialist.myshopify.com/cdn/shop/t/19/assets/chevron-right.svg?v=29036006152714717661725272118"
                              alt="Aan winkelwagen toevoegen"
                              className="relative min-w-[18px]"
                              width="12"
                              height="17.5"
                            />
                          </div>
                        </button>
                        :
                        <a href={product.url}>
                          <div className="overlay z-5 absolute top-0 left-0 w-full h-full bg-[rgb(51,51,51,.825)] flex" style={{ zIndex: '5' }}></div>
                          <img
                            className="absolute left-0 top-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
                            src={product.media[0]}
                            alt={product.title}
                          />
                          <div className="absolute bottom-6 left-6 z-10 text-white text-left">
                            <h3>{product.title}</h3>
                            <p className="text-4xl font-bold text-white">{product.price}</p>
                          </div>
                          <div className="absolute right-[-1px] bottom-[-1px] p-4 bg-primary-red w-11 h-11 items-center justify-center flex">
                            <img
                              src="//de-rijplaten-specialist.myshopify.com/cdn/shop/t/19/assets/chevron-right.svg?v=29036006152714717661725272118"
                              alt="Aan winkelwagen toevoegen"
                              className="relative min-w-[18px]"
                              width="12"
                              height="17.5"
                            />
                          </div>
                        </a>
                        }
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-scrollbar"></div>
              </div>
            </div>
            {/* <div className="bg-intense w-full lg:w-[24%] relative flex lg:flex-col mt-6 lg:mt-0 lg:ml-6">
              {teaserImage && (
                <img
                  src={teaserImage}
                  alt="Teaser"
                  className="lg:w-full w-24 object-cover h-4/6"
                  style={{ width: '322px', height: '346px' }}
                />
              )}
              {teaserText && (
                <div className="p-4 text-bright lg:h-2/6 flex flex-col w-full items-start">
                  <h3 className="font-bold text-base leading-tight lg:leading-snug lg:text-lg mb-2 lg:mb-0">
                    {teaserText}
                  </h3>
                  {teaserDescription && (
                    <p className="text-xs my-2 hidden lg:block">{teaserDescription}</p>
                  )}
                  {teaserLinkUrl && (
                    <a
                      href={teaserLinkUrl}
                      title={teaserLinkText}
                      className="underline text-xs duration-300 transition-all mt-auto"
                    >
                      {teaserLinkText}
                    </a>
                  )}
                </div>
              )}
            </div> */}
          </div>
        ) : (
          <div className="w-full">
            <div>
              <div className="flex justify-between items-center mb-4">
                {teaserTitle && (
                  <h2 className="text-lg md:text-2xl font-bold leading-normal text-black">
                    {teaserTitle}
                  </h2>
                )}
                {teaserShowMore && teaserShowMoreUrl && (
                  <a
                    className="underline transition-all duration-300 hidden md:block"
                    href={teaserShowMoreUrl}
                    title={teaserShowMoreText}
                  >
                    {teaserShowMoreText}
                  </a>
                )}
              </div>
              <div className="flex flex-row lg:gap-6 swiper" ref={swiperRef}>
                <div className="swiper-wrapper">
                  {collectionProducts.slice(0, 4).map((product, index) => (
                    <div className="swiper-slide" key={index}>
                      <div className="product-card">
                        <img src={product.image} alt={product.title} />
                        <h3>{product.title}</h3>
                        <p>{product.price}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-scrollbar"></div>
              </div>
            </div>
          </div>
        )}
      </div>

    </>

  );
};

export default TeaserProducts;
