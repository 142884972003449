import { useContext } from 'react'
import AppContext from '../../AppContext';

import styled from 'styled-components'
import { Link } from 'react-router-dom';

import Countdown, { zeroPad } from 'react-countdown';

import './PageHeader.css';

function PageHeader() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  // Update the global state
  const openVideo = () => {
    updateGlobalState('video_opened', true);
    console.log(globalState);
  };

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div>
          <div className="timer-box">
            <span>2</span>
          </div>
          <div className="timer-desc">
            <span>klanten</span>
          </div>
          {/* <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>dagen</span>
          </div> */}
        </div>
      );
    }
  };

  const scrollToPrices = () => {
    const section = document.querySelector('#prijzen');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  }
 
  return (
    <div className="header-outer">
      <div className="timer">
        <Countdown 
          date={tomorrow} 
          renderer={renderer}
        />
      </div>
      <div className="page__header" style={{ backgroundImage: `url('/img/page-header/page-header-bg.png')` }}>
        <div className="container">
          <div className="row">
            <div className="header__img">
              <img className="w-100" src="/img/page-header/stef-ridder-img.png" alt="" />
            </div>

            <div className="header-sticker">
              {/* <img className="wapp-logo" src="/img/whatsapp-logo.webp" alt="" /> */}
              <span>Tijdelijk</span>
              <span className="bold-text" style={{ fontWeight: 'bold' }}>GRATIS</span>
              <span>app</span>
            </div>

            <div className="header-content">
              <h1>Meerdere kilo's aankomen</h1>
              {/* <h1>Bereik Jouw Ideale Gewicht</h1> */}
              <h2>Het 8-Weeks Aankom Traject voor Mannen</h2>

              <button onClick={() => scrollToPrices()} className="button button-gray">
                De eerste 5 klanten krijgen
                <span>50% korting</span>
              </button>
              
              <small>Geen Resultaat = Geld Terug</small>
            </div>
          </div>

          {/* <img src="/img/deal-image.png" alt="Logo Healthy Living Project" style={{ position: 'absolute' }} /> */}
          
          {/* <div className="quote">
            <p>
              “Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PageHeader;