import { Modal, Button, Group, TextInput, NumberInput, Checkbox, Box, Flex, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import styled from 'styled-components'

import axios from 'axios';


import PageHeader from '../components/GewichtVerliezen/PageHeader'
import Voordelen from '../components/GewichtVerliezen/Voordelen'
import Recensies from '../components/GewichtVerliezen/Recensies'
import Diensten from '../components/GewichtVerliezen/Diensten'
import Prijzen from '../components/GewichtVerliezen/Prijzen'

function GewichtVerliezen() {

  return (
    <>
      {/* Header */}
      <PageHeader />

      {/* Voordelen */}
      <Voordelen />

      {/* Recensies */}
      <Recensies />

      {/* Diensten */}
      <Diensten />

      {/* Prijzen */}
      <Prijzen />
    </>
  )
}

export default GewichtVerliezen;