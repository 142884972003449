import { useState } from 'react';
import Footer from '../Footer';
import NewsletterForm from '../NewsletterForm';
import ProductBuyBox from '../ProductBuybox';
import TeaserProducts from '../TeaserProducts';
import './Homepage.css';
import Diensten from './components/Diensten';
import SmallHeader from './components/SmallHeader';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';

const Homepage = () => {

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();  // This line is crucial
    setIsSubmitting(true);
    setSubmitStatus(null);
  
    try {
      const response = await axios.post('https://hook.eu2.make.com/vsihku3mvayhv2gcxy69f2s19p48nnkx', formData);
      console.log('Form submitted successfully:', response.data);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  
  return (
    <>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
            {/* Close Button */}
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            {/* Modal Content */}
            <h2 className="text-2xl text-black font-bold mb-4">Stel je vraag</h2>
            <p className="mb-4 text-gray-700">
              Heb je vragen over onze diensten of wil je meer informatie? Vul het formulier in en we
              nemen zo snel mogelijk contact met je op.
            </p>
            {/* Contact Form */}
            <form onSubmit={submitForm}>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                  Naam
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw naam"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw e-mail"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  id="message"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw bericht"
                  rows="4"
                  required
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-primary-red text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-gray-400"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Versturen...' : 'Verstuur'}
              </button>
              {submitStatus === 'success' && (
                <p className="mt-2 text-green-600">Bericht succesvol verzonden!</p>
              )}
              {submitStatus === 'error' && (
                <p className="mt-2 text-red-600">Er is een fout opgetreden. Probeer het later opnieuw.</p>
              )}
            </form>
          </div>
        </div>
      )}

      <SmallHeader />

      <div id="diensten" className="container mx-auto md:px-4 section-padding py-[45px] md:py-[90px] md:mt-[-155px]">
        <Swiper
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={1.25}
          navigation
          autoHeight
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            768: {
              // For tablets and above
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
        >
          <SwiperSlide>
            <div className="bg-gray-100 px-10 h-full py-[25px] md:py-[50px] text-center flex flex-col items-center justify-center transform transition-transform duration-300 hover:-translate-y-2 border-b-4 border-primary-red hover:border-primary-red">
              <a href="/trainingsplan" title="Meer informatie">
                <h3 className="text-[21px] font-bold mb-3">Ik zoek hulp met mijn training</h3>
                {/* <p className="text-sm mb-3 sm:hidden md:block">
                  This is the description for the first block, providing some insightful
                  information.
                </p> */}
                <span
                  className="text-accent-blue underline flex justify-center space-x-2 items-center"
                >
                  <span className="font-bold text-subheader3">Meer informatie</span>
                </span> 
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-gray-100 px-10 h-full py-[25px] md:py-[50px] text-center flex flex-col items-center justify-center transform transition-transform duration-300 hover:-translate-y-2 border-b-4 border-primary-red hover:border-primary-red">
              <a href="/voedingsplan" title="Meer informatie">
                <h3 className="text-[21px] font-bold mb-3">Ik zoek hulp met mijn voeding</h3>
                {/* <p className="text-sm mb-3 sm:hidden md:block">
                  Description for the second block, highlighting key details and
                  features.
                </p> */}
                  <span
                    className="text-accent-blue underline flex justify-center space-x-2 items-center"
                  >
                    <span className="font-bold text-subheader3">Meer informatie</span>
                  </span> 
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-gray-100 px-10 h-full py-[25px] md:py-[50px] text-center flex flex-col items-center justify-center transform transition-transform duration-300 hover:-translate-y-2 border-b-4 border-primary-red hover:border-primary-red">
              <button onClick={toggleModal}>
                <h3 className="text-[21px] font-bold mb-3">Ik wil een totale transformatie</h3>
                {/* <p className="text-sm mb-3 sm:hidden md:block">
                  Final block description, offering valuable insights and more
                  information.
                </p> */}
                  <span
                    className="text-accent-blue underline flex justify-center space-x-2 items-center"
                  >
                    <span className="font-bold text-subheader3">Meer informatie</span>
                  </span> 
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div
        className="container mx-auto px-4 py-16 grid grid-cols-12 gap-[20px] md:gap-[50px] items-center"
        style={{ paddingTop: '27px', paddingBottom: '27px' }}
      >
        {/* Image Column */}
        {/* <div className="col-span-12 md:col-span-5 mt-4 md:mt-0 md:order-2 ml-auto">
          <img
            src="https://placehold.co/1920x1080" 
            alt="Background"
            className="object-cover h-full w-full max-w-[100%] max-h-[430px]"
          />
        </div> */}

        {/* Content Column */}
        <div className="col-span-12 md:order-1">
          <h2 className="text-black text-3xl font-bold mb-3">
            Geen Excuses, Alleen Resultaten
          </h2>
          <div className="text-black text-md">
            Bij Healthy Living Project geloof ik niet in snelle oplossingen of loze beloftes. Ik bied eerlijk advies en effectieve begeleiding in sport, voeding en gezondheid. Met een no-nonsense aanpak werken we samen aan échte resultaten. Of je nu wilt afvallen, spieren wilt opbouwen, of je gewoon gezonder wilt voelen — ik sta voor je klaar. Geen smoesjes, alleen hard werken en duidelijke resultaten.
          </div>
          <div className="flex space-x-8 items-center">
            <button
              type="button"
              onClick={toggleModal}
              className="bg-primary-red text-white px-10 py-6 rounded-[10px] font-bold flex space-x-2 items-center mt-5"
            >
              <span>Ik ben klaar voor verandering</span>
            </button>
          </div>
        </div>
      </div>

      <div
        className="container mx-auto px-4 py-16 grid grid-cols-12 gap-[20px] md:gap-[50px] items-center"
        style={{ paddingTop: '27px', paddingBottom: '27px' }}
      >
        {/* Image Column */}
        <div className="col-span-12 md:col-span-6 mt-4 md:mt-0 md:order-1 mr-auto">
          <img
            src="/img/stef-schrijven.jpg" 
            alt="Background"
            className="object-cover h-full w-full max-w-[100%] max-h-[430px]"
          />
        </div>

        {/* Content Column */}
        <div className="col-span-12 md:col-span-6 md:order-2">
          <h2 className="text-black text-3xl font-bold mb-3">
            Jouw Gezondheid, Mijn Missie
          </h2>
          <div className="text-black text-md">
            Klaar om de beste versie van jezelf te worden? Bij Healthy Living Project is jouw gezondheid mijn missie. Mijn online coachingsprogramma’s zijn ontworpen om jou te helpen je doelen te bereiken met op maat gemaakte plannen die werken. Geen ingewikkelde diëten of onrealistische trainingsschema’s, maar strategieën die passen bij jouw levensstijl. Stuur mij een bericht en start vandaag nog met de verandering die je nodig hebt.
          </div>
          <div className="flex space-x-8 items-center">
            <button
              type="button"
              onClick={toggleModal}
              className="bg-primary-red text-white px-10 py-6 rounded-[10px] font-bold flex space-x-2 items-center mt-5"
            >
              <span>Ik ben klaar voor verandering</span>
            </button>
          </div>
        </div>
      </div>

      <TeaserProducts />

      <div class="container pb-[40px] md:pb-[90px] mx-auto px-4 flex flex-wrap md:flex-row sm:items-center md:items-stretch section-padding">
        <div class="w-full md:w-2/3 bg-primary-red text-white px-[30px] py-[50px] md:py-[60px] md:px-[50px] flex flex-col justify-between sm:order-2 md:order-1">
          <p class="text-3xl font-bold text-white sm:hidden md:block">Gratis informatie in je mailbox</p>
          <h2 class="text-subheader3 md:text-h3 mt-2 text-neutral-white" style={{ lineHeight: '26px' }}>Wil je wekelijks gratis informatie, tips en motivatie in je mailbox ontvangen? Schrijf je nu in op mijn nieuwsbrief en ontvang een kortingscode voor 20% korting op <i>AL MIJN PROGRAMMA'S</i></h2>
          <div class="flex justify-start">
            <a 
              href="https://healthy-living-project.ck.page/kennis" 
              target="_blank"
              className="bg-white text-black px-6 py-3 rounded-[10px] font-bold flex space-x-2 items-center mt-5"
              title="Shop Now"
            >
              <span>Schrijf mij in!</span>
              <div class="relative top-[2px]">
              </div>
            </a>
          </div>
        </div>
        <div class="w-full md:w-1/3 relative sm:mt-4 md:mt-0 sm:order-1 md:order-2 hidden md:block">
          <img src="/img/website/stef-deadlift.jpg" alt="Exclusive Summer Collection" class="w-full sm:h-[175px] md:h-full object-cover sm:relative md:absolute inset-0" style={{ objectPosition: '0 -150px' }} />
          <div class="absolute top-0 right-0 bg-white text-primary-blue p-4 text-center">
            <p class="text-h4 font-bold md:text-[60px] leading-none">20%</p>
            <p class="md:text-[14px] font-bold">Korting op alles</p>
          </div>
        </div>
      </div> 

      {/* <NewsletterForm /> */}
    </>
  );
}
 
export default Homepage;