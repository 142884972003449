import { useContext, useEffect, useState } from 'react'
import AppContext from '../../AppContext';

import styled from 'styled-components'
import { useNavigate } from "react-router-dom";

import { useExitIntent } from 'use-exit-intent'

import Countdown, { zeroPad } from 'react-countdown';

import './Quiz.css';
import ReactWhatsapp from 'react-whatsapp';
import Reviews from './Reviews';

function QuizSection() {
  const { globalState, updateGlobalState } = useContext(AppContext);
  
  const { registerHandler } = useExitIntent()

  useEffect(() => {
    updateGlobalState('quiz_started', false);
  }, [])

  registerHandler({
    id: 'openModal',
    handler: () => console.log('Hello from handler!')
  })

  // Update the global state
  const openVideo = () => {
    updateGlobalState('video_opened', true);
    console.log(globalState);
  };

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer">
          <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div>
          <div className="timer-box">
            <span>5</span>
          </div>
          <div className="timer-desc">
            <span>klanten</span>
          </div>
          {/* <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>dagen</span>
          </div> */}
        </div>
      );
    }
  };

  const scrollToPrices = () => {
    const section = document.querySelector('#prijzen');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  }

  const startQuiz = () => {
    console.log("Start quiz");
    
    updateGlobalState('active_step', 1);
    updateGlobalState('quiz_started', true);
  }

	const navigate = useNavigate();

  const finishQuiz = () => {
    navigate('/quiz-voltooid')
  }

  const [bundleModalShown, setBundleModalShown] = useState(false);
 
  return (
    <div className="header-outer">
      <div className="timer">
        <Countdown 
          date={tomorrow} 
          renderer={renderer} 
        />
      </div>
      <div className="page__header lp__header" style={{ backgroundImage: `url('/img/page-header/hlp-bg-grey.jpg') !important` }}>
        <div className="container">
          {/* <div className="row align-items-center align-items-md-start justify-content-end">
            <div className="logo pt-4" style={{ width: '90%', margin: '0 auto' }}>
              <a href="#">
                <img src="/img/hlp-logo-wit.svg" alt="Logo Healthy Living Project" className="w-100" /> 
              </a>
            </div>
          </div> */}
          <div className="row">

            <div className="header-content" style={ globalState.quiz_started ? { display: 'none' } : { display: 'block' }}>
              
              {/* <h2>
                Speciaal voor:<br/>
                <span>MANNEN (18-45 jaar) die streven naar gewichtstoename en spieropbouw!</span>
              </h2>
              <h2><span>Maak kennis met mijn unieke 6-weken traject!</span></h2> */}

              {/* <h1>Ben je moe van het eeuwige gevecht om aan te komen?</h1>
              <h2>We creëren samen een aanpak voor gezonde gewichtstoename<br />die perfect bij jouw levensstijl past.</h2> */}

              {/* <Label>
                BLACK FRIDAY ACTIE!
              </Label> */}
 
              {/* <h1>Persoonlijke Begeleiding voor<br/>Gezonde Gewichtstoename</h1> */}
              <h1>Krijg persoonlijke inzichten in jouw gewichtsprobleem</h1>
              {/* <h1>Ik heb 109 mannen online geholpen om in totaal 453 kilo aan te komen</h1> */}
              {/* <h1>Bereik Jouw Ideale Gewicht</h1> */}
              {/* <h2>Een Aanpak die Perfect Past bij <b>Jouw Levensstijl</b></h2> */}
              {/* <h2>Mijn naam is Stef, Online Gewichtscoach. Ik heb een quiz gebouwd om jou te helpen naar je ideale gewicht.</h2> */}
              <h2>Doe de quiz en ontdek waarom je moeite hebt met aankomen</h2>
              {/* <h2>Je zult nooit meer te dun zijn met mijn <i>online</i> coaching</h2> */}

              <p className="mt-3">
                Na het invullen van de quiz ontvang je de resultaten en krijg je een unieke aanbieding aangeboden om jou te helpen bij je gewichtsdoelen.
                <br/><br/>
                {/* Ik bied jou waardevolle hulpmiddelen aan om jouw fitnessdoelen te ondersteunen. Mijn speciaal ontworpen tools helpen je bij het overwinnen van veelvoorkomende uitdagingen bij aankomen. Gedurende de quiz kun je tools ontgrendelen die waardevol voor jou kunnen zijn. */}
              </p>
              
              {/* <p className="mt-3" style={{ color: 'orange' }} onClick={() => setBundleModalShown(true)}>
                <b>BLACK FRIDAY ACTIE:</b><br/>
                Doe de quiz (minder dan 3 minuten) <br/>en ontvang mijn <u style={{ cursor: 'pointer' }}>Gewichtstoename Bundel*</u> gratis én maak kans op een gratis coachingstraject (t.w.v. €149,99)
              </p>  */}

            
              {/* <h4><small style={{ textShadow: 'none', fontSize: '16px' }}>(Gegarandeerd resultaat)</small></h4> */}

              <div className="button-container">

                <div className="img-container">
                  <img className="stef-img" src="/img/stef-online-coach.png" />
                </div>

                <ul>
                  <li>Duur max. 2 minuten</li>
                  {/* <li>Ontvang meteen de uitslag</li> */}
                  <li>Je ontvangt meteen de uitslag</li>
                  <li>Gratis</li>
                  <li style={{ color: 'orange' }}>Exclusieve aanbieding na het invullen</li>
                </ul>

                <p style={{ color: 'orange' }}>
                  <b>"FIT VOOR DE ZOMER" ACTIE:</b><br/>

                  Maak na het invullen van de quiz kans op een gratis 8-weeks coachingstraject (t.w.v. €169,99!)
                  <br/><br/>
                  {/* Na het invullen van de quiz nodig ik je, indien nodig, uit voor een vrijblijvende consultatie via WhatsApp om je resultaten te bespreken en te ontdekken hoe ik jou verder kan helpen. */}

                  {/* Ontvang gratis toegang tot tools uit mijn <u className="blink" style={{ cursor: 'pointer' }}>Gewichtstoename Bundel*</u> én  */}
                </p> 
                <button onClick={() => startQuiz()} className="button button-red mt-auto w-100 mb-0">
                  Start Quiz
                </button>
                

              {/* <ReactWhatsapp number="+31627003089" message="Hi Stef, ik kom via je website en ben geinteresseerd in een schema." style={{ background: 'transparent', border: 'none', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <button className="button button-white" style={{ padding: '0', margin: '12.5px 0 0', background: 'transparent', border: 'none', color: '#fff', textDecoration: 'underline', whiteSpace: 'normal' }}>
                  Geïnteresseerd in een voedingsschema of trainingsschema? Klik hier.
                </button>
              </ReactWhatsapp> */}

                 
            {/* <button onClick={() => finishQuiz()} className="button button-red mt-auto">
              Voltooi Quiz
            </button>  */}
              </div>
            </div>

          </div>

{/* <Reviews /> */}

          {/* <img src="/img/deal-image.png" alt="Logo Healthy Living Project" style={{ position: 'absolute' }} /> */}
          
          {/* <div className="quote">
            <p>
              “Als je op zoek bent naar een gezonde en duurzame manier om af te vallen, kan ik Stef ten zeerste aanbevelen!”
            </p>
          </div> */}
        </div>
      </div>

      <div className={`bundle-modal ${bundleModalShown ? 'shown' : ''}`}>
        <span className="bundle-label">GRATIS</span>
        <img className="close-button" src="/img/icons/close-button.svg" alt="" onClick={() => setBundleModalShown(false)} />
        <h4>De Gewichtstoename Bundel bevat:</h4>
        <ul className="bundle-list">
          <li>Een <b>trainingsschema</b> op maat, uniek voor jouw behoeften</li>
          <li>Mijn <b>Gids</b> 'Een gids voor het beginnen met bijhouden van je calorie-inname'</li>
          <li>Mijn <b>E-Book</b> 'De 20 Meest Gemaakte Fouten bij Aankomen en Spieren Opbouwen'</li>
          <li>Mijn <b>Gids</b> 'Hoe te Beginnen in de Sportschool'</li>
        </ul>
      </div>

      


      {/* <div className="deal-label">
      <Label>
        ONTVANG VANDAAG:
      </Label>
        <span><span className="label">Gratis</span> <span>Trainingsschema op maat <i>(t.w.v. €59,99)</i></span></span>
        <span><span className="label">Gratis</span> <span>"Hoe Calorieën Tracken?"-Gids</span></span>
        <span><span className="label">Gratis</span> <span>20 Meest Gemaakte Fouten bij Aankomen E-Book</span></span>
        <span><span className="label">50% korting</span> <span>op een coachingstraject</span></span>
      </div> */}
    </div>
  )
}

const Label = styled.div`
  background: rgba(255, 118, 34, 1);
  color: #fff;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  

  @media (min-width: 992px) { 
    margin-right: auto;
  }
`

export default QuizSection;