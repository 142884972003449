import styled from 'styled-components'
import Lottie from 'react-lottie';
import * as animationData from './confetti.json'
import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import './JeBentBegonnen.css';
import './QuizFinished.css';

import './Prijzen.css';
import './Faq.css';
import ReactWhatsapp from 'react-whatsapp';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import Reviews from './Reviews';
import BeforeAfter from './BeforeAfter';

import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';

function Bundle() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [openedModal, setOpenedModal] = useState(null);

  const openModal = (id) => {
    console.log('Open modal');

    setOpenedModal(id);
  }

  const closeModal = (id) => {
    console.log('Close modal');

    setOpenedModal(null);
  }

  // Update the global state
  const handleClick = () => {
    updateGlobalState('cta_opened', true);
    console.log(globalState);
  };

  const [activeFaq, setActiveFaq] = useState(1);

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0,0,0,0) 

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const schrijfInOpPakket = (id) => {
    
 
		const options = {
			method: 'POST',
			headers: {accept: 'application/json', 'content-type': 'application/json'},
			body: JSON.stringify({
				email: globalState.email, 
				firstName: globalState.naam
				// tel: tel.replace('+3106', '+316').replace('+31', '').replace('06', '6')
			})
		};  

		setLoading(true);
		
		fetch(`https://healthyliving-project.com/api/klaviyo/pakket-${id}`, options)
		// fetch(`http://localhost:5000/klaviyo/pakket-${id}`, options)
			.then(response => response.json()) 
			.then(response => {
				console.log(response)
				setLoading(false);
			}) 
			.catch(err => console.error(err));
  }
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null)

	const navigate = useNavigate();

  useEffect(function() {
    if (searchParams.get("paymentResponse")) {
      setPaymentStatus(searchParams.get("paymentResponse"));
    }
  }, [searchParams]);

  const createPayment = () => {
    setLoading(true);

    const values = {
      "message": "Payment",
      "email": globalState.email, 
      "naam": globalState.naam
    }

    axios.post(`https://healthyliving-project.com/api/stripe/`, values, {
    // axios.post(`https://hlp-app-lmvfr.ondigitalocean.app/api/payment/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response); 
      console.log(response.data.session.url);
      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
      setLoading(false);
      
      // navigate(`/thank-you`);
    });
  }
 
  return (
    <>

    <div className="section header-section" style={{ backgroundImage: `url('/img/page-header/hlp-bg-grey.jpg')` }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className="mb-3">Je kunt gebruik maken van een gratis consultatie!</h1>
            <p className="mb-5">
              Neem contact met mij op door een appje te sturen of mij te mailen. Ik zal dan zo snel mogelijk contact met je opnemen.
            </p>
            <div className="d-flex flex-column align-items-start">
              {/* <a href="#prijzen" className="button button-white mt-4">Stuur mij een appje!</a> */}
              <ReactWhatsapp number="+31627003089" message="Hi Stef, ik kom via je landingspagina en ik wil graag gebruik maken van een gratis consultatie." style={{ background: 'transparent', border: 'none', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <button className="button button-white" style={{ background: '#84B62D', borderColor: '#84B62D' }}>Stuur mij een appje!
                    <img className="wapp-logo" src="/img/whatsapp-logo.webp" alt="" /></button>
                    <span style={{ color: "#fff" }}>(+31627003089)</span>
              </ReactWhatsapp>
              

              <div className="mt-3">
                <p className="mb-4" style={{ fontSize: '20px' }}>
                  <a href="#deal">Bekijk mijn aanbieding 👇</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">

            <div className="img-container">
              <img className="stef-img w-100" src="/img/digitale-gewichtbegeleider.png" />
            </div>

            {/* <video
              src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'} 
              width="800" 
              height="600" 
              className="mt-5 mt-md-0"
              autoPlay={false}
              muted={false}
              controls={true}
              allowFullScreen={false}
              style={{ opacity: '1', width: '100%', height: 'auto' }}
            >
            </video> */}
          </div>
        </div>
      </div>
    </div>

    <div className="section timer-section" style={{ padding: '30px 0' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="prijs-timer">
              <div className="timer-container">
                <span style={{ color: '#fff' }}>Ik heb een aanbieding die nog maar een paar dagen geldt! 👇</span>
                <Countdown 
                  date={tomorrow} 
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section usp-bar-red">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4 mb-md-0">
            <h3>4,9 sterren gemiddeld</h3>
            <p>
              op Google Reviews
            </p>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <h3>10+ jaar ervaring</h3>
            <p>
              In fitness & leefstijl coaching
            </p>
          </div>
          <div className="col-md-4">
            <h3>Schema's op maat</h3>
            <p>
            Uniek voor jouw behoeften
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="section usp-bar" style={{ background: '#353535' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-5 mb-md-0">
            <h5>Persoonlijk plan</h5>
            <p>
              Geen kant en klare schema's die je ontvangt. Alles is 100% maatwerk, gebaseerd op jouw doelen, leefstijl, budget en voorkeuren.
            </p>
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <h5>Geen restricties</h5>
            <p>
            Je bent nog steeds vrij om lekkere dingen te eten! Ik zorg voor de juiste balans waardoor je in shape komt, zonder grote opoffering.
            </p>
          </div>
          <div className="col-md-4">
            <h5>Professionele coaching</h5>
            <p>
            Ik als digitale gewichtsbegeleider zit 10+ jaar in het vak en heb al tal van mensen geholpen.
            </p>
          </div>
        </div>
      </div>
    </div> */}

    {/* <div className="section text-image-block" style={{ background: 'var(--dark-gray)' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 mr-auto">
            <h5>Persoonlijke trainingsschema's</h5>
            <p>
            Schema's op maat gemaakt door jouw digitale gewichtsbegeleider, aangepast op jouw doelen,
            situatie, levensstijl en ervaring.
            <br/><br/>
            - 100% maatwerk<br/>
            - Trainingsschema altijd bij de hand<br/>
            - Uitleg over de oefeningen door middel van afbeeldingen<br/>
            - Voor elke oefening een alternatieve oefening
            </p>
            <div className="d-flex mt-3">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
          <div className="col-md-6 mt-5 md-md-0">
            <img className="w-100" src="/img/stef-schrijven.jpg" alt="" />
          </div>
        </div>
      </div>
    </div> */}

    {/* <div className="section text-image-block" style={{ background: '#353535' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-2 order-md-1 mt-5 mt-md-0">
            <img className="w-100" src="/img/stef-schrijven.jpg" alt="" />
          </div>
          <div className="col-md-5 ml-auto order-1 order-md-2">
            <h5>Persoonlijke voedingsschema's</h5>
            <p>
              Je digitale gewichtsbegeleider maakt een persoonlijk voedingsplan en hierbij zal rekening gehouden worden met jouw levensstijl, intoleranties of een allergie. 
              <br/><br/>
              - 100% maatwerk<br/>
              - Maaltijd plannen voor meerdere dagen<br/>
              - Begeleiding bij voeding zodat je het zelf leert
            </p>
            <div className="d-flex mt-3">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/* <div className="section hoe-werkt-het" style={{ background: 'var(--red)' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-5 mb-md-0">
            <span className="number">1</span>
            <h5>Koop het pakket</h5>
            <p>
              Als jij op aanmelden klikt krijg je verdere uitleg en een intake-formulier in je mail. 
            </p>
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <span className="number">2</span>
            <h5>Intake formulier</h5>
            <p>
              Nadat jij een pakket hebt gekozen krijg jij via de mail een email met verdere uitleg. Hier kun jij vervolgens het intake formulier invullen. Aan de hand hiervan kan jouw digitale gewichtsbegeleider de schema's opstellen. Deze zul jij binnen 5 werkdagen ontvangen zodat je zo snel mogelijk aan de slag kunt.
            </p>
          </div>
          <div className="col-md-4">
            <span className="number">3</span>
            <h5>Resultaten behalen!</h5>
            <p>
              Nu kun je aan de slag en is het tijd om je doelen te behalen! Je kunt 24/7 vragen stellen aan je coach. Via WhatsApp heb jij altijd toegang tot je training en voedings plan. Je zult om de week een check-in moment hebben met je coach om te zorgen dat je constant resultaten behaald!
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/* <div className="section timer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="prijs-timer">
              <div className="timer-container">
                <span style={{ color: '#fff' }}>De aanbieding geldt nog maar een paar dagen!</span>
                <Countdown 
                  date={tomorrow} 
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div id="prijzen" className="section pakket-section" style={{ backgroundImage: `url('/img/page-header/hlp-bg-grey.jpg')` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Unieke aanbieding 👇</h3>
            <h4>Betaal nu achteraf!</h4>
          </div>
        </div>

        <div className="row mt-md-5 justify-content-center">
       
          <div className="col-md-6 mx-auto">
            <div className="prijs-card">
              {/* <div className="sticker" style={{ background: 'var(--red)' }}>
                <span>50%</span>
                <span>korting</span>
              </div> */}
              <h4>Ultieme Fysiek Bundel</h4>
              {/* <h4>6-weken<br/>traject</h4> */}
              <div className="pricing">
                <span>€29.99</span>
                <span className="old-price">€244.95</span>
              </div>
              <small style={{ color: 'orange', fontSize: '20px' }}>! Niet tevreden = niet betalen !</small>
              <p className="mt-3">
                <i>Speciale Nieuwjaars korting</i>
              </p>

              <ul>
                <li>
                  Een trainingsschema op maat (t.w.v. €49,99)
                </li>
                <li>
                  Een voedingsschema op maat (t.w.v. €49,99)
                </li>
                <li>
                  De ultieme gids voor krachttraining (t.w.v. €29,99)
                </li>
                <li>
                  Gids voor het beginnen met bijhouden van calorieën (t.w.v. €14,99)
                </li>
                <li>
                  4 weken toegang tot je persoonlijke virtuele coach (t.w.v. €99,99)
                </li>
                <li>
                  Om de week een check-in moment
                </li>
                <li>
                  Aanpassingen training & macro's
                </li>
                <li>
                  24/7 contact via WhatsApp
                </li>


              </ul>

              {/* <small>
                <i>Als je geen resultaat ziet, krijg je je geld terug. Beloofd.</i>
              </small>  */}

              {/* <button onClick={() => createPayment()} className="button button-white mt-4">
                { loading ? 'Versturen...' : 'Vraag nu gratis aan!' }
              </button> */}
              
              <ReactWhatsapp number="+31627003089" message="Hi Stef, ik wil graag vrijblijvend je Ultieme Fysiek Bundel aanvragen." style={{ background: 'transparent', border: 'none', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <button className="button button-white">Vraag nu gratis aan!</button>
              </ReactWhatsapp>

            </div>
          </div>

        </div>
      </div>
    </div>

    {/* <div className="header-outer">
      <div className={`modal-overlay ${openedModal !== null ? 'shown' : ''}`} onClick={() => setOpenedModal(null)}></div>
      <div className="finished-container" style={{ backgroundImage: `url('/img/page-header/hlp-bg-grey.jpg')` }}>
        <div className="container">
          <div className="row">

            <div className="header-container" style={{ padding: '70px 0' }}>
              
              <div className="col-md-12">
                <p style={{ textAlign: 'center', paddingBottom: '0px', marginTop: '0', marginBottom: '30px' }}>
                  Whatsapp naar <i><a style={{ color: 'white' }} href="tel:0031627003089">+31 06 27 00 30 89</a></i><br/> 
                  E-mail naar <i><a style={{ color: 'white' }} href="mailto:info@healthyliving-project.com">info@healthyliving-project.com</a></i><br/> 
                  of klik hieronder om direct WhatsApp te openen.
                </p> 
                <ReactWhatsapp number="+31627003089" message={`Hi Stef, ik heb zojuist je quiz ingevuld en ik wil graag gebruik maken van je gratis consultatie om te ontdekken hoe ik mijn gewichtsdoelen kan bereiken.`} style={{ background: 'transparent', border: 'none' }}>
                  <button className="button button-red">Stuur mij een appje</button>
                </ReactWhatsapp>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div> */}
    
    {/* <section className="faq-section" style={{ background: 'var(--red)' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto text-center mb-5">
            <h2>Veelgestelde vragen</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto">
            <div className={`faq-item ${activeFaq == 1 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(1)}>
                <h3>Wat houdt een trainingsschema op maat in?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Een trainingsschema op maat is een persoonlijk afgestemd trainingsplan, gebaseerd op jouw fitnessniveau, doelen, en beschikbare tijd. Het is ontworpen om je te helpen je fitnessdoelen effectiever en efficiënter te bereiken.
                </p>
              </div>
            </div>
            <div className={`faq-item ${activeFaq == 2 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(2)}>
                <h3>Hoe wordt mijn voedingsschema aangepast aan mijn behoeften?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p> 
                Je voedingsschema wordt aangepast op basis van je persoonlijke doelen, voedingsvoorkeuren, allergieën, en levensstijl. Het zorgt ervoor dat je de juiste voedingsstoffen binnenkrijgt om je training te ondersteunen en je gezondheidsdoelen te bereiken.
                </p>
              </div>
            </div>
            <div className={`faq-item ${activeFaq == 3 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(3)}>
                <h3>Wat kan ik verwachten van de ultieme gids voor krachttraining?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Deze gids biedt uitgebreide informatie over krachttrainingstechnieken, uitrusting, en strategieën om je kracht en spiermassa op te bouwen. Het is zowel geschikt voor beginners als voor gevorderden.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 4 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(4)}>
                <h3>Hoe werkt de toegang tot een persoonlijke virtuele coach?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Met deze toegang krijg je gepersonaliseerd advies en begeleiding van een gekwalificeerde coach. Dit omvat het beantwoorden van vragen, het geven van feedback op je voortgang, en het aanpassen van je schema's waar nodig.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 5 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(5)}>
                <h3>Wat houdt het check-in moment om de week in?
                    </h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Tijdens deze check-ins evalueren we je voortgang, bespreken we uitdagingen en passen we indien nodig je trainingsschema of voedingsschema aan.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 6 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(6)}>
                <h3>Hoe worden aanpassingen gemaakt aan mijn training en macro's?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Aanpassingen worden gemaakt op basis van je voortgang, feedback, en eventuele veranderingen in je routine of doelen. Dit zorgt ervoor dat je programma altijd afgestemd blijft op je behoeften.
                </p>
              </div>
            </div>

            <div className={`faq-item ${activeFaq == 7 ? 'active' : ''}`}>
              <div className="faq-heading" onClick={() => setActiveFaq(7)}>
                <h3>Hoe werkt het 24/7 contact via WhatsApp?</h3>
                <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                </svg>
              </div>
              <div className="faq-body">
                <p>
                Je kunt op elk moment vragen stellen of ondersteuning vragen via WhatsApp. Dit betekent dat je snel antwoord krijgt op je vragen en continue ondersteuning hebt in je fitnessreis.
                </p>
              </div>
            </div>

          

          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <a href="#prijzen" className="button button-white mt-4">Bekijk aanbieding</a>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </>
  )
}

export default Bundle;