import { useEffect, useContext } from "react";
import AppContext from '../../AppContext';
import { useSearchParams } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

function BetalingGelukt() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  
  ReactPixel.init('336340599280167', options);

  ReactPixel.track('Purchase', {currency: "EUR", value: 31.99});

  // useEffect(() => {

	// 	const options = {
	// 		method: 'POST',
	// 		headers: {accept: 'application/json', 'content-type': 'application/json'},
	// 		body: JSON.stringify({
	// 			email: searchParams.get("email"), 
	// 			naam: searchParams.get("naam")
	// 			// tel: tel.replace('+3106', '+316').replace('+31', '').replace('06', '6')
	// 		})
	// 	};  
		
	// 	fetch('https://healthyliving-project.com/api/klaviyo/bundel_gekocht', options)
	// 	// fetch('http://localhost:5000/klaviyo/add_member_to_list', options)
	// 		.then(response => response.json()) 
	// 		.then(response => {
	// 			console.log(response)
	// 		}) 
	// 		.catch(err => console.error(err));
  // }, []);

  return (
    <>
      {/* Navigatie */}
      <nav className="d-none">
        <div className="container">
          <div className="row align-items-center justify-content-end">
            <div className="logo">
              <a href="#">
                <img src="/img/hlp-logo-wit.svg" alt="Logo Healthy Living Project" />
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Header */}
      <div className="result-section pb-5 mb-5 pt-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto mt-5 mt-md-0">
              <div className="header__content">
                <h5 className="mt-5" style={{ fontSize: '18px' }}>Bedankt voor je vertrouwen</h5>
                <h1>Je fitnessreis is begonnen!</h1>
                <p className="mb-2">
                  Je ontvangt binnen 24 uur een mail van ons persoonlijk.<br/><br/>
                  Niks ontvangen? Stuur een mail naar <b><a href="mailto:info@healthyliving-project.com">info@healthyliving-project.com</a></b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BetalingGelukt;