// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const SmallHeader = () => {
  return (
    <>
      <div
        className="relative bg-cover bg-center h-auto md:h-auto pt-[185px] pb-[90px] md:py-[0px]"
        style={{
          backgroundPosition: 'center',
          backgroundImage: "url('/img/website/hlp-stef-actie.jpg')",
          backgroundSize: 'cover',
        }}
      >
        {/* Gradient Overlay */}
        <div
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.725) 100%)',
          }}
        ></div>

        <div className="overlay mx-auto px-4 h-full flex sm:items-end md:items-center relative">
          <div className="container relative z-10">
            <div className="sm:max-w-2xl md:max-w-4xl sm:px-6 sm:pb-10 md:p-0 md:py-[200px]">
              <div className="relative">
                <h1 className="text-primary-red sm:text-body1 md:text-h2 font-bold mb-2">
                  Geen Bullshit
                </h1>
                <h2 className="text-white text-4xl font-bold mb-6 max-w-3xl">
                  Eerlijk advies in <br />
                  Sport, Voeding & Gezondheid
                </h2>
                <div className="flex space-x-8 items-center">
                  <a
                    href="#diensten"
                    className="bg-primary-red text-white px-10 py-6 rounded-[10px] font-bold flex space-x-2 items-center mt-4"
                  >
                    <span>Ik ben klaar voor verandering</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
 
export default SmallHeader;