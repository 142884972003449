import { BackgroundImage, Center, Text, Box, Title, Flex, Stack, ActionIcon, Avatar, Paper, createStyles, Image } from '@mantine/core';

import { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Mousewheel } from "swiper";

const useStyles = createStyles((theme) => ({
  vertical_container: {
    maxHeight: '100vh',
    display: 'flex',
    gap: '20px',
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    opacity: '0',
    transition: 'opacity .5s ease',
    '> div': {
      width: '100%',
    },
    '.swiper': {
      height: '100%',
      overflow: 'visible',
      '.swiper-slide': {
        height: 'auto'
      }
    },
    '@media (max-width: 991px)': {
      flexDirection: 'column',
      maxHeight: 'auto',
      '.swiper': {
        height: '250px !important'
      },
    },
    '&.loaded': {
      width: '99%',
      opacity: '1',
      transition: 'opacity .5s ease',
      '@media (max-width: 991px)': {
        height: '100%'
      },
    }
  },
  image: {
    maxHeight: '370px',
    'img': {
      maxHeight: '370px',
    }
  },
  swiper_left: {
    '.swiper-wrapper': {
      transitionTimingFunction: 'linear !important'
    },
    '@media (max-width: 991px)': {
      display: 'none !important'
    },
  },
  swiper_right: {
    '.swiper-wrapper': {
      transitionTimingFunction: 'linear !important'
    }
  }
}));

export const AnimatedSlider = ({ testimonials }) => {
  
  const { classes } = useStyles();

  const [loaded, setLoaded] = useState(false);
  useEffect(() => { 
    setTimeout(() => {
      console.log('loaded');
      setLoaded(true);
    }, 1000)
  }, []);

  function shuffle(array) {
    array.sort(() => Math.random() - 0.5);

    console.log('Shuffles array: ', array);

    return array  
  }

  const [allTestimonials, setAllTestimonials] = useState([]);

  useEffect(() => {
    let defaultTestimonials = testimonials;
    // defaultTestimonials = shuffle(defaultTestimonials);

    console.log('testos: ', defaultTestimonials)
    setAllTestimonials(defaultTestimonials);
  }, [testimonials])
  
  const [firstSliderTestimonials, setFirstSliderTestimonials] = useState([]);
  const [secondSliderTestimonials, setSecondSliderTestimonials] = useState([]);

  useEffect(() => {
    setFirstSliderTestimonials(testimonials.filter((item, index) => index < (testimonials.length / 2)));
    setSecondSliderTestimonials(testimonials.filter((item, index) => index >= (testimonials.length / 2)));
  }, [testimonials])


  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages([
      "/img/resultaten/april2024/resultaat1.jpg",
      "/img/resultaten/april2024/resultaat3.jpg",
      "/img/resultaten/april2024/resultaat5.jpg",
      "/img/resultaten/resultaat-21.jpg",
      "/img/resultaten/april2024/resultaat6.jpg",
      "/img/resultaten/april2024/resultaat7.jpg",
      "/img/resultaten/april2024/resultaat8.jpg",
      "/img/resultaten/april2024/resultaat9.jpg",
      "/img/resultaten/resultaat-30.jpg",
      "/img/resultaten/april2024/resultaat10.jpg",
      "/img/resultaten/april2024/resultaat4.jpg",
      "/img/resultaten/april2024/resultaat12.jpg",
      "/img/resultaten/resultaat-8.jpg",
      "/img/resultaten/april2024/resultaat13.jpg",
      "/img/resultaten/april2024/resultaat14.jpg",
      "/img/resultaten/resultaat-6.jpg",
      "/img/resultaten/april2024/resultaat15.jpg",
      "/img/resultaten/april2024/resultaat16.jpg"
    ]);
  }, []) 
  
  return (
    <Box className={`${classes.vertical_container} ${loaded ? 'loaded' : ''}`}>



      <Swiper
        slidesPerView={'auto'}
        spaceBetween={40}
        direction={'vertical'}
        loop={true}
        loopedSlides={6}
        centeredSlides={true}
        modules={[Pagination, Mousewheel, Navigation, Autoplay]}
        className={`${classes.swiper} ${classes.swiper_right}`}
        style={{ height: '100vh' }}
        speed={5000}
        allowTouchMove={false}
        // autoplay={{
        //   delay: 0,
        //   disableOnInteraction: true,
        //   reverseDirection: true
        // }}
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
          reverseDirection: true
        }}
        freeMode={true}
        // freeModeMomentum={false}
      > 

        <SwiperSlide>
          <Image
            radius={32}
            src={ images[9] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[10] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[11] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[12] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[13] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[14] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[15] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[16] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            radius={32}
            src={ images[17] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide>

        {/* <SwiperSlide>
          <Image
            radius={32}
            src={ images[0] }
            alt=""
            className={classes.image}
          />
        </SwiperSlide> */}
        {/* {
          images ?
          images.map((item, index) => {

            if (index < 9) return false;

            if (item.name) {
              return (
                <SwiperSlide key={index}>
                  <Image
                    radius={32}
                    src={ item }
                    alt=""
                    className={classes.image}
                  />
                </SwiperSlide>
              )
            } else {
              return (
                <SwiperSlide key={index}>
                  <Image
                    radius={32}
                    src={ item }
                    alt=""
                    className={classes.image}
                  />
                </SwiperSlide>
              )
            }

          })
          : null
        } */}
      </Swiper>

    </Box>
  );
}