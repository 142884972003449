import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import './Page.css';

import { Crisp } from "crisp-sdk-web";

import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import USPs from './USPs';
import Schema from './Schema';
import Wetenschap from './Wetenschap';
import ReviewsList from './ReviewsList';
import OfferPakket from './OfferPakket';
import OfferCard from './OfferPakketCard';
import Gifts from './Gifts';
import Insta from './Instagram';
import Header from './Header';
import Intro from './Intro';
import Resultaten from './Resultaten';
import StickyAtc from './StickyAtc';
import FeaturedReview from './FeaturedReview';
import JouwCoach from './JouwCoach';
import Traject from './Traject';
import Verwachten from './Verwachten';
import VoorWie from './VoorWie';
import NietVoorJou from './NietVoorJou';
import WatMoetIkLaten from './WatMoetIkLaten';
import Garantie from './Garantie';
import Twijfels from './Twijfels';
import WatNodig from './WatNodig';
import DeChallenge from './DeChallenge';
import Waarom from './Waarom';
import TextImage from './TextImage';
import HeaderReview from './HeaderReview';
import OfferItems from './OfferItems';
import Facebook from './Facebook';
import WatJeKrijgt from './WatJeKrijgt';

function BeginnersPage({ defaultPrice }) {

  Crisp.configure('3a766b7e-34c5-4cea-8d7c-8b42d4bb1ed6', {
    autoload: false
  });

  useEffect(() => {
    Crisp.load();
  }, [])
  
  const { globalState, updateGlobalState } = useContext(AppContext);

  var today = new Date();
  today.setHours(today.getHours() + 4);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };
  
  const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

  const [activeFaq, setActiveFaq] = useState(0);
  const faqs = [
    {
      "vraag": "Wanneer gaat de challenge van start?",
      "antwoord": "De challenge is niet aan een bepaalde tijd of datum gebonden. Je begint de challenge wanneer het jou het beste uitkomt."
    },
    {
      "vraag": "Wat kan ik van het persoonlijke trainingsschema verwachten?",
      "antwoord": "Je ontvangt een intakeformulier met vragen over jouw leefstijl, ervaring met training, gewenste trainingsdagen, toegang tot apparatuur en meer. Aan de hand van jouw antwoorden maken wij een op maat gemaakt trainingsschema specifiek voor jouw niveau, wensen en behoeften. Je bepaald zelf hoeveel dagen per week en hoe lang je wilt trainen."
    },
    {
      "vraag": "Wat kan ik verwachten van de Challenge App?",
      "antwoord": "De app is speciaal ontworpen voor deze challenge. Je kunt in de app eenvoudig je trainingen bijhouden en persoonlijk contact hebben met je coach via het berichtensysteem. Al je data in één overzicht."
    },
    {
      "vraag": "Moet ik Challenge App gebruiken met deze challenge?",
      "antwoord": "Nee de app is niet verplicht. Wij leveren je trainingsschema en de gids ook als PDF, zodat je hier zonder de app ook mee aan de slag kunt. "
    },
    {
      "vraag": "Moet ik de Challenge App op mijn mobiel downloaden?",
      "antwoord": "De app is ontworpen om via computer, tablet én mobiel bereikbaar te zijn. Je kunt via internet inloggen in de app wanneer je maar wilt."
    },
    {
      "vraag": "Wat leer ik allemaal in De Ultieme Spiermassa Gids?",
      "antwoord": "In deze gids hebben wij al onze kennis over training, voeding, spiergroei, rust en herstel op een eenvoudige en begrijpelijke manier uitgelegd. Al deze kennis kun je toepassen in de challenge en zelfs de rest van je fitnessreis."
    },
    {
      "vraag": "Hoeveel tijd moet ik per dag besteden aan deze challenge?",
      "antwoord": "Je kunt zelf bepalen hoe vaak en hoe lang je traint. De trainingen zijn zo ontworpen dat ze binnen 45-60 minuten voltooid kunnen worden. De tijd die je aan de challenge besteedt, hangt af van je eigen planning en beschikbaarheid. Over het algemeen raad ik aan om minimaal een paar keer per week te trainen."
    },
    {
      "vraag": "Wat als ik geen ervaring heb met fitness?",
      "antwoord": "Geen zorgen! Deze challenge is speciaal ontworpen voor beginners. De challenge begeleidt je stap voor stap en voorziet je van alle nodige informatie en ondersteuning om succesvol te zijn. Het trainingsschema wordt aangepast aan jouw niveau."
    },
    // {
    //   "vraag": "Moet ik al mijn eten gaan bijhouden in de challenge?",
    //   "antwoord": "Nee, dit is zeker niet nodig. Ik weet dat de meeste mensen hier tegenop zien, dus daarom heb ik een eenvoudige methode ontwikkeld waarbij je, met kleine toevoegingen aan je dieet en zonder alles wat je eet bij te houden, geweldige resultaten kunt behalen."
    // },
    {
      "vraag": "Wat kan ik verwachten van de persoonlijke online begeleiding?",
      "antwoord": "Je krijgt je persoonlijke coach toegewezen aan wie je gedurende de challenge al je vragen of twijfels via de app kunt stellen, waarna je antwoorden en advies zult ontvangen. Dit houdt je gemotiveerd en op het juiste pad. Ook maken we aanpassingen waar nodig voor je."
    },
    {
      "vraag": "Zal ik supplementen moeten aanschaffen of nemen in de challenge?",
      "antwoord": "Nee. Hoewel supplementen voordelen kunnen geven, zijn de meeste supplementen niet nodig, dus zonder supplementen kun je hele goede resultaten behalen."
    },
    {
      "vraag": "Zal ik speciale apparatuur nodig hebben om deel te nemen aan de challenge?",
      "antwoord": "Niet per se. Hoewel het handig kan zijn om toegang te hebben tot een sportschool of enige trainingsapparatuur, kunnen we de workouts ook thuis uitvoerbaar maken met minimale benodigdheden."
    },
    {
      "vraag": "Ik heb een druk schema. Kan ik de challenge nog steeds volgen?",
      "antwoord": "Absoluut! Ik begrijp dat het leven hectisch kan zijn. Daarom bieden we flexibiliteit in mijn programma's, zodat je de challenge kunt aanpassen aan jouw schema."
    },
    {
      "vraag": "Kan ik echt resultaten zien in 60 dagen?",
      "antwoord": "Ja, de 60 Dagen Spiermassa Challenge is ontworpen om je binnen twee maanden merkbare resultaten te geven. Met toewijding en consistentie zul je aanzienlijke verbeteringen in je spiermassa en algehele fitheid zien."
    },
    {
      "vraag": "Wat als ik een dag mis?",
      "antwoord": "Geen probleem! Het schema is flexibel genoeg om aan jouw levensstijl aan te passen. Als je een dag mist, kun je eenvoudig de gemiste training inhalen op een andere dag. Consistentie is belangrijk, maar het schema is ontworpen om realistisch en haalbaar te zijn."
    },
    // {
    //   "vraag": "Moet ik mijn hele dieet omgooien?",
    //   "antwoord": "Nee, zeker niet. Wij bieden een lijst van eiwitrijke voedingsmiddelen die je aan je dagen kunt toevoegen zodat je in ieder geval je eiwitdoel behaalt. Het doel is om je voeding te optimaliseren, niet te compliceren."
    // },
    {
      "vraag": "Wat voor resultaten kan ik verwachten?",
      "antwoord": "De resultaten verschillen per persoon en zijn afhankelijk van jouw niveau en je toewijding en inzet tijdens de challenge. We hebben nog niemand in de challenge gehad die echt geen resultaten heeft behaald, dus resultaten ga je zeker zien!"
    },
    {
      "vraag": "Wat gebeurt er na de 60 dagen challengeperiode?",
      "antwoord": "Na de challengeperiode van 60 dagen heb je de kennis en vaardigheden om zelfstandig door te gaan met je fitness- en voedingsdoelen. We bieden ook opties voor verdere ondersteuning en begeleiding als je dat wenst."
    }
  ]  
  

  const [activeObjection, setActiveObjection] = useState(0); 
  const objectionHandlers = [
    {
      "vraag": "\"Ik heb al eerder geprobeerd om spiermassa op te bouwen, maar het lukte me niet. Waarom zou het deze keer anders zijn?\"",
      "antwoord": "Dat begrijp ik. Deze challenge biedt echter persoonlijke begeleiding en op maat gemaakte trainingsschema's die specifiek zijn ontworpen om jou te helpen jouw doelen te bereiken. Met de juiste begeleiding en ondersteuning is succes binnen handbereik."
    },
    {
      "vraag": "\"Ik ben niet zeker of ik genoeg tijd heb om me volledig aan de challenge te wijden. Hoe kan ik er zeker van zijn dat ik mijn schema kan volgen?\"",
      "antwoord": "We begrijpen dat het leven druk kan zijn, maar de trainingsschema's zijn flexibel en kunnen worden aangepast aan jouw schema. Onze coaches zullen je helpen om realistische doelen te stellen en je ondersteunen bij het vinden van manieren om de challenge in je drukke leven te passen."
    },
    {
      "vraag": "\"Ik heb geen ervaring met krachttraining. Is deze challenge geschikt voor beginners?\"",
      "antwoord": "Absoluut! Deze challenge is ontworpen voor mensen van alle fitnessniveaus, inclusief beginners. Onze coaches zullen je begeleiden bij elke stap van je fitnessreis en ervoor zorgen dat je de juiste technieken leert en veilig vooruitgang boekt."
    },
    {
      "vraag": "\"Ik ben bang dat ik mijn voortgang niet zal kunnen bijhouden of dat ik niet gemotiveerd genoeg zal zijn om door te zetten. Hoe kan ik hiermee omgaan?\"",
      "antwoord": "Het is normaal om af en toe twijfels te hebben, maar we zijn hier om je te ondersteunen. Onze challenge omvat regelmatige voortgangsbeoordelingen en persoonlijke begeleiding om je gemotiveerd te houden en eventuele obstakels te overwinnen. Samen zullen we jouw succes verzekeren."
    }
  ]
  
  
  
  
  
  
  return (
    <>
      {/* <StickyAtc defaultPrice={defaultPrice} /> */}

      <HeaderReview />

<Intro /> 

<VoorWie />

<USPs /> 

<Verwachten />

      {/* <Header / > */}

      {/* <Schema /> */}

<Facebook />

{/* <Waarom /> */}

{/* <Resultaten /> */}

{/* <DeChallenge /> */}

{/* <OfferItems /> */}

<WatJeKrijgt />

{/* <TextImage
  image={'/img/fullbody-schema.png'}
  imageBlur={false}
  subtitle="Training"
  title="Trainingsschema"
  content={`
    Mijn allerbeste trainingsprogramma met de beste resultaten.
    <br/><br/>
    Terug naar de basis. Oefeningen die veel spieren tegelijk aanpakken.
    <br/><br/>
    Geen nutteloze oefeningen die er alleen leuk uitzien voor op Instagram.
    <br/><br/>
    Je kunt kiezen voor verschillende varianten: voor beginners, gevorderden en experts.
    <br/><br/>
    Ook het aantal trainingsdagen per week is aan te passen. Kun je een keer 2 in plaats van 3 keer trainen? Pak die week het 2-daags schema.
    <br/><br/>
    <b>Gegarandeerde resultaten</b>.
  `}
/>

<TextImage
  bg="#fff"
  image={'/img/offer/offer-gids.jpg'}
  subtitle="Educatie"
  title="Ultieme Spiermassa Gids"
  content={`
    Geen uitgebreide e-book van 150 pagina's die je toch niet gaat lezen...
    <br/><br/>
    Maar een korte gids van een aantal pagina's met de <b>allerbelangrijkste informatie</b> die je nodig hebt over voeding, training, rust en herstel voor optimale spiergroei.
    <br/><br/>
    Ik heb alle informatie hierin geschreven die ik heb geleerd in de afgelopen jaren bij het trainen van honderden mannen.
  `}
/>

<TextImage
  image={'/img/page-header/stef-ridder-img.png'}
  subtitle="Coaching"
  title="Contact met een expert"
  content={`
    Kom je er gedurende de challenge niet uit? Loop je ergens vast of wil je meer informatie?
    <br/><br/>
    Je krijgt een rechtstreekse verbinding met je online personal coach, waarbij één tot twee keer per dag dagelijks al je vragen beantwoordt worden via de mail.
    <br/><br/>
    Geen reden meer om niet te groeien!
  `}
/> */}

{/* <TextImage
  subtitle="Voeding"
  title="Simpele methode om te eten voor spiergroei"
  content={`
    Ik snap dat je helemaal geen zin hebt om alles bij te houden wat je in je mond stopt...
    <br/><br/>
    Geen gedoe met calorieën tellen in deze challenge.
    <br/><br/>
    Je krijgt geleerd hoe je met <b>hele simpele</b> aanpassingen en <b>zonder hoge kosten</b> ervoor zorgt dat je je spieren kunt voorzien van voedsel voor optimale spiergroei.
    <br/><br/>
    Je kunt zelfs met het avondeten mee blijven eten.
  `}
/> */}

{/* <WatNodig /> */}

{/* <JouwCoach /> */}

{/* <NietVoorJou /> */}

<ReviewsList />

{/* <WatMoetIkLaten /> */}

{/* <Twijfels /> */}

      {/* <Traject /> */}

      {/* <Gifts /> */}

      {/* <OfferPakket defaultPrice={defaultPrice} /> */}

      {/* <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Herken jij jezelf in het volgende?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                objectionHandlers.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeObjection == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveObjection(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Bekijk aanbieding
              </a>
            </div>
          </div>
        </div>
      </section>  */}

      {/* <FeaturedReview /> */}

      {/* <JouwCoach /> */}

<OfferCard defaultPrice={defaultPrice} />

<Garantie />

      <section className="faq-section voorwie-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Wat ons eerder is gevraagd 👇</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                faqs.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeFaq == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveFaq(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="#000"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Bekijk aanbieding
              </a>
            </div>
          </div>
        </div>
      </section> 

<Insta />
  </>
  )
}

export default BeginnersPage;