import './Voordelen.css';

function Voordelen() {

  return (
    <section className="services" id="services">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h6>Voordelen</h6>
              <h2>Waarom ik een goede keus ben</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="voordeel-item">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="65" height="65" rx="32.5" fill="#353535"/>
                <path d="M47 29.9943V31.3101C46.9981 34.3942 45.9507 37.3951 44.014 39.8652C42.0772 42.3354 39.3549 44.1424 36.253 45.0169C33.1511 45.8913 29.8359 45.7863 26.8017 44.7175C23.7675 43.6487 21.177 41.6734 19.4164 39.0861C17.6559 36.4989 16.8197 33.4383 17.0325 30.3609C17.2453 27.2835 18.4958 24.3541 20.5974 22.0097C22.699 19.6652 25.5392 18.0313 28.6943 17.3516C31.8494 16.672 35.1504 16.9829 38.105 18.2382" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M47 19.8605L31.6154 34.1624L27 29.8761" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <div className="voordeel-content">
                <h3 className="mt-0 mb-2">Persoonlijke voedingsadviezen</h3>
                <p>
                  Samen met jou werk ik om een op maat gemaakt plan te creëren dat past bij jouw specifieke behoeften en doelen, zodat je effectief en op een gezonde manier kunt afvallen.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Voordelen;
