import './Recensies.css';

// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Recensies() {

  return (
    <section className="recensies-section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-heading centered dark-heading">
            <h6>Recensies</h6>
            <h2 className="mt-2 mb-4">Ontdek wat anderen die je voor gingen zeggen over onze gids</h2>
            <p>
            Lees de succesverhalen van onze klanten en laat je inspireren door hun reis naar een beter leven
            </p>
          </div>
        </div>
      </div>

      <Swiper
        className="mt-5 pt-5"
        modules={[Navigation]}
        style={{ overflow: 'visible' }}
        spaceBetween={50}
        slidesPerView={3.15}
        navigation
        autoHeight
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          // when window width is >= 320px
          320: {
            width: 320,
            slidesPerView: 1
          },
          // when window width is >= 640px
          640: {
            width: 320,
            slidesPerView: 1
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2
          },
          // when window width is >= 768px
          991: {
            width: 991,
            slidesPerView: 3
          },
          // when window width is >= 768px
          1025: {
            width: 1025,
            slidesPerView: 3
          }
        }}
      >
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h5>Eindelijk een gezonde manier om aan te komen zonder fastfood!</h5>
            <p className="quote">
            "Als een dunne man die altijd moeite had om aan te komen, heeft deze gids mijn leven veranderd. De lijst met voedingsmiddelen en de handige boodschappenlijsten hebben me geholpen om gezonde keuzes te maken en mijn calorie-inname te verhogen. Geen fastfood of shakes nodig! Ik ben eindelijk trots op mijn lichaam en vol zelfvertrouwen. Een absolute aanrader!"
            </p>
            <div className="pricing">
              <span>Mark B.</span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h5>Praktische tips</h5>
            <p className="quote">
            "Ik was op zoek naar een gezonde manier om aan te komen zonder mijn voedingsgewoonten volledig overhoop te gooien. Deze gids bood precies dat! De strategieën en tips waren praktisch en gemakkelijk te volgen. Ik ben aangekomen zonder mijn lichaam te schaden en voel me energieker dan ooit tevoren. Bedankt!"
            </p>
            <div className="pricing">
              <span>Lucas G.</span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h5>Top Gids!</h5>
            <p className="quote">
            “Een gids die me heeft geholpen mijn doelen te bereiken op een duurzame manier”
            </p>
            <div className="pricing">
              <span>Freek</span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="recensie-card">
            {/* <img className="avatar" src="https://via.placeholder.com/300x300" alt="" /> */}
            <h5>De ultieme oplossing</h5>
            <p className="quote">
            "Ik had nooit gedacht dat ik in staat zou zijn om aan te komen zonder fastfood te eten. Deze gids heeft me laten zien dat het mogelijk is! De boodschappenlijsten waren een game-changer - ik kon direct aan de slag zonder me zorgen te maken over het plannen van mijn maaltijden. Ik ben zo blij dat ik deze gids heb gevonden en raad het ten zeerste aan aan alle dunne mannen die willen aankomen op een gezonde manier."
            </p>
            <div className="pricing">
              <span>Kevin L.</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

    </div>
    </section>
  )
}

export default Recensies;
